import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../css/BetSlipTable.css';
import { height } from '@mui/system';
import { maxWidth } from '@mui/system';

// Styled TableContainer with full width and borders
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '100%', // Ensure full width
  //maxHeight: 'calc(100vh - 200px)', // Adjust height for responsiveness
  overflowY: 'auto', // Enable vertical scrolling
  overflowX: 'auto', // Enable horizontal scrolling
  position: 'relative', // Position relative for pagination alignment
  marginBottom: "50px",
  bornder: 'none',
  // Hide scrollbars
  '&::-webkit-scrollbar': {
    width: '0px', // Hide scrollbar
    height: '0px', // Hide horizontal scrollbar
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent', // Hide scrollbar thumb
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent', // Hide scrollbar track
  },
  '-ms-overflow-style': 'none', // Hide scrollbar in IE and Edge
  'scrollbar-width': 'none', // Hide scrollbar in Firefox
}));

// Styled Table with borders
const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%', // Ensure full width
  height: 'auto',
  borderCollapse: 'collapse', // Ensure borders collapse
  '& thead th': {
    backgroundColor: '#f8f8f8', //theme.palette.grey[100], // Header background color (darker grey)
    color: "#555", //theme.palette.text.primary, // Header text color
    position: 'sticky',
    top: 0,
    borderTop: `1px solid ${theme.palette.divider}`, // Left border for headers
    borderBottom: `1px solid ${theme.palette.divider}`, // Bottom border for headers
    borderLeft: `1px solid ${theme.palette.divider}`, // Left border for headers
    borderRight: `1px solid ${theme.palette.divider}`, // Right border for headers
    textAlign: 'center', // Center align text in headers
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'left',
    whiteSpace: "nowrap",
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: '#f9f9f9', // Background color for even rows
  },
  '& tbody tr:nth-of-type(even)': {
    backgroundColor: "white", // Background color for odd rows
  },
  '& tbody td': {
    borderBottom: `0.5px solid ${theme.palette.divider}`, // Bottom border for cells
    borderLeft: `0.5px solid ${theme.palette.divider}`, // Left border for cells
    borderRight: `0.5px solid ${theme.palette.divider}`, // Right border for cells
    textAlign: 'center', // Center align text in cells
    fontSize: '13px',
    color: '#555',
    textAlign: 'left',
    fontWeight: "500",
    verticalAlign: 'top',
  },
  fontSmooth: 'always',
}));

// Styled TablePagination to match the theme and align left
const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-toolbar': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '8px 16px', // Adjust padding
    backgroundColor: theme.palette.background.paper, // Ensure background color
    textAlign: 'left',
    paddingRight: 'auto',
  },
  // Ensure pagination is visible on horizontal scroll
  position: 'relative',
  bottom: 0,
}));

// Sample data generation
const generateData = (rows = 100, cols = 14) => {
  const data = [];
  for (let i = 0; i < rows; i++) {
    const row = [];
    for (let j = 0; j < cols; j++) {
      if (j == 0) {
        row.push("Sun Sep 01 15:29:22 IST 2024");
      } else if (j == 1) {
        row.push("Match Winner");
      } else if (j == 2) {
        row.push("0.58");
      } else if (j == 3) {
        row.push("1000");
      } else if (j == 4) {
        row.push("KHAI");
      } else if (j == 5) {
        row.push("Meerut Mavericks");
      } else if (j == 6) {
        row.push("SP191857");
      } else if (j == 7) {
        row.push("1000.0");
      } else if (j == 8) {
        row.push("-580.0");
      } else if (j == 9) {
        row.push("12.0%");
      } else if (j == 10) {
        row.push("120.0");
      } else if (j == 11) {
        row.push("-69.6");
      } else if (j == 12) {
        row.push("Settle");
      } else if (j == 13) {
        row.push("120.0");
      }
      // row.push(`Cell ${i + 1}-${j + 1}`);
    }
    data.push(row);
  }
  return data;
};

// BigTable Component
const BetSlipTable = ({ open, isSmallScreen }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const data = generateData();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const customTable = {
    thTableCell: {
      padding: '5px',
    },
    tdTableCell: {
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    tdTablePhone: {
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '8px',
      paddingBottom: '8px',
      whiteSpace: 'nowrap',
    },
    listItemIcon: {
      color: 'lightgrey',
      minWidth: 0,
      justifyContent: 'center',
      paddingLeft: 0.5,
      fontSize: 20,
    },
    listItemText: {
      color: 'lightgrey',
      marginLeft: 3,
    },
    sublListItem: {
      color: 'lightgrey'
    },
    sublListItem: {
      color: 'lightgrey'
    },
    drawer: {
      flexShrink: 0,
    },
  };

  return (
    <div>
      <StyledTableContainer>
        <div className={open ? "bet-slip-table-parent-open" : "bet-slip-table-parent-close"} >
          <StyledTable>
            <TableHead>
              {/* Colspan Headers */}
              <TableRow>
                <TableCell colSpan={9} sx={customTable.thTableCell}>
                  Bet
                </TableCell>
                <TableCell colSpan={4} sx={customTable.thTableCell}>
                  My Share / Position
                </TableCell>
                <TableCell colSpan={1} sx={customTable.thTableCell}>
                  Settlement
                </TableCell>
              </TableRow>
              {/* Main Headers */}
              <TableRow>
                <TableCell sx={customTable.thTableCell}>DATE</TableCell>
                <TableCell sx={customTable.thTableCell}>Market Title</TableCell>
                <TableCell sx={customTable.thTableCell}>RATE</TableCell>
                <TableCell sx={customTable.thTableCell}>AMOUNT</TableCell>
                <TableCell sx={customTable.thTableCell}>MODE</TableCell>
                <TableCell sx={customTable.thTableCell}>Runner Name</TableCell>
                <TableCell sx={customTable.thTableCell}>User</TableCell>
                <TableCell sx={customTable.thTableCell}>{"Antigua And Barbuda Falcons"}</TableCell>
                <TableCell sx={customTable.thTableCell}>{"Guyana Amazon Warriors"}</TableCell>
                <TableCell sx={customTable.thTableCell}>My Share</TableCell>
                <TableCell sx={customTable.thTableCell}>{"Antigua And Barbuda Falcons"}</TableCell>
                <TableCell sx={customTable.thTableCell}>{"Guyana Amazon Warriors"}</TableCell>
                <TableCell sx={customTable.thTableCell}>Status</TableCell>
                <TableCell sx={customTable.thTableCell}>Plus / Minus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </div>
      </StyledTableContainer>
      <StyledTablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default BetSlipTable;
