import React, { useCallback, useEffect, useState } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export const AuthSidebarData = [
    {
        title:"Admin login",
        icon: <DashboardIcon />,
        link: "/logadmin"
    },
    {
        title:"Agent login",
        icon: <AccountBalanceWalletIcon />,
        link: "/logagent"
    },
    {
        title:"Master login",
        icon: <PersonAddIcon />,
        link: "#"
    }
]