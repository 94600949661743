import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { myDownline } from "./tables/Columns";
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import TokenService from "../..//services/token-service";
import './css/MyDownline.css';
import './css/OverlayPopups.css';
import AgentActionsPopup from "./AgentActionsPopup";
import Modal from './Modal';
import { tab } from "@testing-library/user-event/dist/tab";
import TableTitle from "./customized/TableTitle";
import ClientTable from "./tables/ClientTable";

export default function MyClients({ role, logout, open, isSmallScreen }) {
    console.log("show", role);
    let agentType = "user";
    let addType = "User";
    console.log("agentType", agentType);
    const href = window.location.href;
    let blockedStatus = false;
    if (href.includes("blockedclients"))
        blockedStatus = true;
    console.log("agentType", blockedStatus);
    const user = TokenService.getUser();
    const { state } = useLocation();
    // if (state === null) {
    //     logout();
    // }
    const { msg } = state ? state : "";

    const getChildrenByType = "/beta/getChildrenByType?type=user&offset=0&limit=20&blocked=";
    const api = httpHelpers();
    const [childrenByType, setChildrenByType] = useState([]);
    const [table, setTable] = useState(childrenByType);
    let navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - childrenByType.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchChildrenByType = () => {
        api
            .get(`${getChildrenByType}` + blockedStatus)
            .then(res => {
                console.log("imm children", res);
                if (res && res.data && res.data.length > 0) {
                    setChildrenByType(res.data);
                    setTable(res.data);
                } else {
                    setChildrenByType(null);
                    setTable(null);
                }
            })
            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    const openRegister = (e) => {
        e.preventDefault();
        if (agentType.includes("subcompany")) {
            navigate('/registersc');
        } else if (agentType.includes("superstockist")) {
            navigate('/registersst');
        } else if (agentType.includes("stockist")) {
            navigate('/registerst');
        } else if (agentType.includes("agent")) {
            navigate('/registeragent');
        } else if (agentType.includes("user")) {
            navigate('/registeruser');
        }
    };

    const deposit = (e, child) => {
        e.preventDefault();
        // navigate('/deposit', { state: { child } });
        // navigate(`/paycash/${child.userId}`, { state: { child } });
        setActionChild(child);
        setActionType("D");
        // setShowActionPopup(true);
        setModalOpen(true);
    };

    const withdraw = (e, child) => {
        e.preventDefault();
        // navigate('/withdraw', { state: { child } });
        // navigate(`/receivecash/${child.userId}`, { state: { child } });
        setActionChild(child);
        setActionType("W");
        // setShowActionPopup(true);
        setModalOpen(true);
    };

    const handleImmediateChildren = (e, child) => {
        e.preventDefault();
        navigate(`/icdashboard/${child.userId}/${child.entityType}`, { state: { child } });
        // if (child.entityType.includes("subcompany")) {
        //     navigate('/childrenssc', { state: { child } });
        // } else if (child.entityType.includes("superstockist")) {
        //     navigate('/childrenssst', { state: { child } });
        // } else if (child.entityType.includes("stockist")) {
        //     navigate('/childrensst', { state: { child } });
        // } else if (child.entityType.includes("agent")) {
        //     navigate('/childrensagent', { state: { child } });
        // }
    };

    const edit = (e, child) => {
        e.preventDefault();
        // navigate('/edit', { state: { child } });
        navigate('/update', { state: { child } });
    };

    const statement = (e, child) => {
        e.preventDefault();
        navigate('/statement', { state: { child } });
    };

    // const changeUserPassword = (e, child) => {
    //     e.preventDefault();
    //     // navigate('/changeuserpassword', { state: { child } });
    //     setActionChild(child);
    //     setActionType("C");
    //     // setShowActionPopup(true)
    //     setModalOpen(true);
    // };

    const changeUserPassword = (e, child) => {
        e.preventDefault();
        navigate('/userpassword', { state: { child } });
        // setActionChild(child);
        // setActionType("C");
        // setShowActionPopup(true)
        // setModalOpen(true);
    };

    useEffect(() => {
        fetchChildrenByType();
    }, [blockedStatus]);

    const onSearchInputChange = (e) => {
        const searchVal = e.target.value;
        if (searchVal === "") { setTable(childrenByType); return; }
        const filterBySearch = childrenByType.filter((item) => {
            if ((item.userId.toLowerCase().includes(searchVal.toLowerCase())
                || item.firstName.toLowerCase().includes(searchVal.toLowerCase())
                || item.lastName.toLowerCase().includes(searchVal.toLowerCase())
                || item.balance.toString().toLowerCase().includes(searchVal.toLowerCase())
                || item.share.toString().toLowerCase().includes(searchVal.toLowerCase())
                || user.share.toString().toLowerCase().includes(searchVal.toLowerCase())
            )) {
                return item;
            }
        })
        setTable(filterBySearch);
    }

    const [showActionPopup, setShowActionPopup] = useState(false);
    const [actionType, setActionType] = useState();
    const [actionChild, setActionChild] = useState();
    const [isModalOpen, setModalOpen] = useState(false);

    const closeAgentActionsPopup = (e) => {
        e.preventDefault();
        setModalOpen(false);
    };

    return (
        <div>
            {/* {showActionPopup && <div className="agent-actions-popup-overlay">
                <div className="agent-actions-popup-overlay-content">
                    <AgentActionsPopup action={actionType} child={actionChild} /></div>
            </div>} */}
            {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)}>
                    <AgentActionsPopup role={role} logout={logout} action={actionType} child={actionChild} closeAgentActionsPopup={closeAgentActionsPopup} />
                </Modal>
            )}
            <div className="my-downline-root">
                {msg && (<div className="form-group" style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }}>
                    <div className="alert alert-success" role="alert">
                        {msg}
                    </div>
                </div>)}
                <div style={{ display: "flex", flexDirection: "row", background: "#204b99", color: "#f8f8f8", marginLeft: "5px", marginRight: "5px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "0px", textAlign: "left" }}>
                    {/* <div style={{ width: "100%", fontSize: "22px", paddingTop: "2px" }}>{blockedStatus === true ? "Blocked Clients" : "All Clients"}</div> */}
                    <TableTitle
                        text={blockedStatus === true ? "Blocked Clients" : "All Clients"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width="100%"
                        height="36px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {blockedStatus === false && <div>
                        {open && <div className="add-master-container" style={{ paddingRight: isSmallScreen ? "65px" : "5px" }}>
                            <div>
                                <button
                                    className="board-buttons board-buttons-nav-bar-dark-smaller-thin-add-agent"
                                    onClick={(e) => openRegister(e)} >Create New User</button>
                            </div>
                        </div>}
                        {!open && <div className="add-master-container" style={{ paddingRight: "5px" }}>
                            <div>
                                <button
                                    className="board-buttons board-buttons-nav-bar-dark-smaller-thin-add-agent"
                                    onClick={(e) => openRegister(e)} >Create New User</button>
                            </div>
                        </div>}
                    </div>}
                </div>
                {childrenByType && <ClientTable
                    clients={childrenByType}
                    user={user}
                    // block={block}
                    edit={edit}
                    handleImmediateChildren={handleImmediateChildren}
                    changeUserPassword={changeUserPassword} />}
            </div>
        </div>
    );
};

const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Root = styled('div')(
    ({ theme }) => `
  border-radius: 2px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  overflow: clip;
  border-right: none;

  table {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.775rem;
    border-collapse: collapse;
    border: none;
    width: 100%;
    min-width: 600px;
    margin: -1px;
    background: white;
  }

  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 12px;
    background: white;
    font-weight: bold;
  }

  td {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 8px;
  }

  tr:nth-of-type(odd) {
    background: #f8f8f8;
  }
  `,
);

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    background: white;
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (orientation: portrait) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin-left: auto;
    margin-top: 3px;
    align-items: center;

    @media (orientation: portrait) {
      margin-left: 30px;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background-color: transparent;
      }
    }
  }
  `,
);
