import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import GameScreen from './GameScreen';
import GameScreen2 from './GameScreen2';
// import data from "./data";
// import ReactPaginate from "react-paginate";
// import { httpHelpers } from "../../services/httpHelpers";
// import { 
//   ZERO,
//   ONE,
//   TWO,
//   THREE,
//   FOUR,
//   FIVE,
//   SIX,
//   SEVEN,
//   EIGHT,
//   NINE,
//   GREEN,
//   PURPLE,
//   RED
// } from '../LuckyNumber/BetCandidatesTypes';

export default function MatchesLiveTable({ role, logout }) {
    console.log("show", role);
    const { state } = useLocation();

    if (state === null) {
        logout();
    }
    const { embedLink, games, sportId } = state ? state : "";
    const getLiveGames = "/alpha/getLiveGame?sportType=" + sportId;
    const api = httpHelpers();
    const [liveGames, setLiveGames] = useState(null);
    const [noLiveGames, setNoLiveGames] = useState(false);
    let navigate = useNavigate();

    const fetchLiveGames = () => {
        api
            .get(`${getLiveGames}`)
            .then(res => {
                console.log("live games", res);
                if (res && res.data) {
                    if (res.data === "") {
                        setNoLiveGames(true);
                    } else {
                        setLiveGames(res.data);
                    }
                } else {
                    setLiveGames(null);
                }
            })

            .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    const openRegisterGames = (e) => {
        e.preventDefault();
        navigate('/registergames');
    };

    const stop = (e, games) => {
        e.preventDefault();
    };

    const cancel = (e, games) => {
        e.preventDefault();
    };

    const suspend = (e, games) => {
        e.preventDefault();
    };

    useEffect(() => {
        fetchLiveGames();
    }, []);

    return (
        <div>
            {noLiveGames && <div style={{ marginTop: "200px" }}>
                <label style={{ color: "#48aaad", fontSize: "30px" }}>No Live Games!</label></div>}
            {(role === "admin" && (liveGames || embedLink)) && (
                <div>
                    <div>
                        <GameScreen game={liveGames} gameLink={embedLink} preLive={games} logout={logout} />
                    </div>
                </div>
            )}
            {(role !== "admin" && liveGames) && (
                <div>
                    <div>
                        <GameScreen2 game={liveGames} gameLink={embedLink} preLive={games} logout={logout} />
                    </div>
                </div>
            )}
        </div>
    );
};
