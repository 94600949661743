import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../styles/AgentUserDetails.css";
import "./UserBetHistory";
import UserBetHistory from './UserBetHistory';
import UserTransactionHistory from './UserTransactionHistory';
import UserTreeTable from './UserTreeTable';
import { useParams, useLocation } from 'react-router-dom';
import AgentUserAction from './AgentUserActions';


function UserDetailsPage() {
    const { username } = useParams();
    console.log(username);
    const location = useLocation();
    console.log(location);
    const { user } = location.state || {};

    return (
        <div>
            <div className="user-details-div">
                <Container>
                    <Typography variant="h4" gutterBottom>
                        User Details
                    </Typography>

                    <Accordion className="accrodian-style">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6">Bet History</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserBetHistory userName={username} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accrodian-style">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h6">Transaction History</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserTransactionHistory userName={username} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accrodian-style">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="h6">User Tree</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserTreeTable userName={username} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accrodian-style">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="h6">User Actions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AgentUserAction user={user}/>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </div>
        </div>
    );
};

export default UserDetailsPage;
