import { useEffect, useState } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import "./style/MasterDownlineTableLite.css";
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

export default function MUIPaginationBetHistory({ role, logout }) {
    const { state } = useLocation();
    const { userId, gameId } = state ? state : "";

    const getBetHistory = "beta/getBetHistory?userId=" + userId + "&gameId=" + (gameId ? gameId : "");
    const api = httpHelpers();
    const [betHistory, setBetHistory] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - betHistory.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchBetHistory = () => {
        api
            .get(`${getBetHistory}`)
            .then(response => {
                console.log("bet history res", response);
                if (response && response.data) {
                    setBetHistory(response.data);
                }
            })
            .catch(err => {
                console.log("err", err);
                if (err && err.response && err.response.status === 401) {
                    logout();
                }
            });
    };

    useEffect(() => {
        fetchBetHistory();
    }, []);

    const Row = ({ row }) => {
        return (
            <div>
                <tr>
                    <td className="master-list-title-td-stmt-long">{row.timestamp}</td>
                    <td className="master-list-title-td">{row.gameId}</td>
                    <td className="master-list-title-td">{row.betCandidate}</td>
                    <td className="master-list-title-td-blue">{row.amount}</td>
                    <td className="master-list-title-td">{row.betType}</td>
                    <td className="master-list-title-td-blue">{row.rate}</td>
                    {row.profitLoss < 0 && <td className="master-list-title-td-red">(-{row.profitLoss})</td>}
                    {row.profitLoss == 0 && <td className="master-list-title-td-red">({row.profitLoss})</td>}
                    {row.profitLoss > 0 && <td className="master-list-title-td-green">{row.profitLoss}</td>}
                    <td className="master-list-title-td">{row.betState}</td>
                </tr>
            </div>
        )
    };

    return (
        <div>
            <div className="table-top-container">
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th-stmt-long">Date/Time</th>
                                        <th className="master-list-title-th">Game ID</th>
                                        <th className="master-list-title-th">Candidate</th>
                                        <th className="master-list-title-th">Amount</th>
                                        <th className="master-list-title-th">Type</th>
                                        <th className="master-list-title-th">Rate</th>
                                        <th className="master-list-title-th">P/L</th>
                                        <th className="master-list-title-th">State</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tbl-content">
                            <div className="tbl-content-scroller">
                                <table>
                                    <tbody>
                                        {betHistory && (rowsPerPage > 0
                                            ? betHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : betHistory
                                        ).map((row, index) =>
                                            <Row
                                                row={row}
                                                key={index}
                                                index={index} />)}
                                        {emptyRows > 0 && (
                                            <tr style={{ height: 34 * emptyRows }}>
                                                <td colSpan={3} aria-hidden />
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {betHistory && betHistory.length > rowsPerPage && <div>
                <div className="table-pagination-container">
                    <div className="table-pagination-container-child">
                        <CustomTablePagination
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            rowsPerPageOptions={10}
                            colSpan={3}
                            count={betHistory && betHistory.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                                select: {
                                    'aria-label': 'rows per page',
                                },
                                actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

// const blue = {
//     50: '#F0F7FF',
//     200: '#A5D8FF',
//     400: '#3399FF',
//     900: '#003A75',
// };

// const grey = {
//     50: '#F3F6F9',
//     100: '#E5EAF2',
//     200: '#DAE2ED',
//     300: '#C7D0DD',
//     400: '#B0B8C4',
//     500: '#9DA8B7',
//     600: '#6B7A90',
//     700: '#434D5B',
//     800: '#303740',
//     900: '#1C2025',
// };

const blue = {
    50: '#48aaad',
    200: '#48aaad',
    400: '#48aaad',
    900: '#48aaad',
};

const grey = {
    50: '#E5E4E2',
    100: '#C0C0C0',
    200: '#E5E4E2',
    300: '#C0C0C0',
    400: '#C0C0C0',
    500: '#C0C0C0',
    600: '#C0C0C0',
    700: '#C0C0C0',
    800: '#C0C0C0',
    900: '#C0C0C0',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #48aaad;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);