import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { myDownline } from "./tables/Columns";
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import TokenService from "../..//services/token-service";
import './css/MyDownline.css';
import './css/OverlayPopups.css';
import AgentActionsPopup from "./AgentActionsPopup";
import Modal from './Modal';
import DirectAgentsTable from "./tables/DirectAgentsTable";

export default function DirectAgents({ role, logout, open, isSmallScreen }) {
    console.log("show", role);
    const href = window.location.href;
    let fromDirectAgents = true;
    if (href.includes("directclients")) {
        fromDirectAgents = false;
    }

    const { state } = useLocation();
    const { child } = state ? state : null;
    const api = httpHelpers();
    let navigate = useNavigate();
    const user = TokenService.getUser();

    const getDirectAgents = "/beta/getImmediateChildren?agentId=" + child.userId + "&offset=0&limit=20";
    const [immediateSubMaster, setImmediateSubMaster] = useState(null);
    const [immediateSubCompany, setImmediateSubCompany] = useState(null);
    const [immediateSuperStockist, setImmediateSuperStockist] = useState(null);
    const [immediateStockist, setImmediateStockist] = useState(null);
    const [immediateAgent, setImmediateAgent] = useState(null);
    const [immediateUser, setImmediateUser] = useState(null);

    const fetchDirectAgents = () => {
        api
            .get(`${getDirectAgents}`)
            .then(res => {
                console.log("fetch agents", res);
                if (res && res.data) {
                    if (fromDirectAgents) {
                        if (res.data.submaster)
                            setImmediateSubMaster(res.data.submaster);
                        if (res.data.subcompany)
                            setImmediateSubCompany(res.data.subcompany);
                        if (res.data.superstockist)
                            setImmediateSuperStockist(res.data.superstockist);
                        if (res.data.stockist)
                            setImmediateStockist(res.data.stockist);
                        if (res.data.agent)
                            setImmediateAgent(res.data.agent);
                    } else {
                        if (res.data.user)
                            setImmediateUser(res.data.user);
                    }
                } else {
                    console.log("fetch agents null");
                    setImmediateSubMaster(null);
                    setImmediateSubCompany(null);
                    setImmediateSuperStockist(null);
                    setImmediateStockist(null);
                    setImmediateAgent(null);
                    setImmediateUser(null);
                }
            })
            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        fetchDirectAgents();
    }, []);

    const [showActionPopup, setShowActionPopup] = useState(false);
    const [actionType, setActionType] = useState();
    const [actionChild, setActionChild] = useState();
    const [isModalOpen, setModalOpen] = useState(false);

    const closeAgentActionsPopup = (e) => {
        e.preventDefault();
        setModalOpen(false);
    };

    return (
        <div>
            {/* {showActionPopup && <div className="agent-actions-popup-overlay">
                <div className="agent-actions-popup-overlay-content">
                    <AgentActionsPopup action={actionType} child={actionChild} /></div>
            </div>} */}
            {/* {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)}>
                    <AgentActionsPopup role={role} logout={logout} action={actionType} child={actionChild} closeAgentActionsPopup={closeAgentActionsPopup} />
                </Modal>
            )} */}
            {/* <div className="my-downline-commission-limit-root"> */}
                <div className="table-top-container-commission-limit">
                    <div className="my-downline-table-container-commission-limit">
                        <div style={{ paddingTop: "0px" }}>
                            {immediateSubMaster && <div>
                                <div style={{ paddingBottom: "60px" }}>
                                    <DirectAgentsTable rows={immediateSubMaster} tableHeader={"All Sub Master"} />
                                </div>
                            </div>}
                            {immediateSubCompany && <div>
                                <div style={{ paddingBottom: "60px" }}>
                                    <DirectAgentsTable rows={immediateSubCompany} tableHeader={"All Sub Company"} />
                                </div>
                            </div>}
                            {immediateSuperStockist && <div>
                                <div style={{ paddingBottom: "60px" }}>
                                    <DirectAgentsTable rows={immediateSuperStockist} tableHeader={"All Super Stockist"} />
                                </div>
                            </div>}
                            {immediateStockist && <div>
                                <div style={{ paddingBottom: "60px" }}>
                                    <DirectAgentsTable rows={immediateStockist} tableHeader={"All Stockist"} />
                                </div>
                            </div>}
                            {immediateAgent && <div>
                                <div style={{ paddingBottom: "60px" }}>
                                    <DirectAgentsTable rows={immediateAgent} tableHeader={"All Agent"} />
                                </div>
                            </div>}
                            {immediateUser && <div>
                                <div style={{ paddingBottom: "0px" }}>
                                    <DirectAgentsTable rows={immediateUser} tableHeader={"All User"} isUser={true} />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
    );
};