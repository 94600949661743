import React, { useState, useEffect } from 'react';
import '../css/ClientTable.css'; // Custom styling
import { FaSortAmountDownAlt, FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import { Button } from 'rsuite';
import Pagination from './AgentTablePagination';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { CSVLink } from "react-csv";
import { csvPdfHeaders } from './Columns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const UpArrowIcon = () => (
    <svg className="client-table-directional-arrow-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#888" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 40V4" />
        <path d="M5 12l7-7 7 7" />
        <path d="M5 12h14" />
    </svg>
);

const DownArrowIcon = () => (
    <svg className="client-table-directional-arrow-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#888" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 4v36" />
        <path d="M5 32l7 7 7-7" />
        <path d="M5 32h14" />
    </svg>
);

const ClientTable = ({ clients, user, block, edit, handleImmediateChildren, changeUserPassword }) => {
    console.log("clients", clients);
    const userType = "CLIENT";
    const [table, setTable] = useState(clients);
    const [csvPdfRows, setCsvPdfRows] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clients.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearchInputChange = (e) => {
        const searchVal = e.target.value;
        if (searchVal === "") { setTable(clients); return; }
        const filterBySearch = clients.filter((item) => {
            if ((item.userId.toLowerCase().includes(searchVal.toLowerCase())
                || item.firstName.toLowerCase().includes(searchVal.toLowerCase())
                || item.lastName.toLowerCase().includes(searchVal.toLowerCase())
                || item.balance.toString().toLowerCase().includes(searchVal.toLowerCase())
                || item.share.toString().toLowerCase().includes(searchVal.toLowerCase())
                || user.share.toString().toLowerCase().includes(searchVal.toLowerCase())
            )) {
                return item;
            }
        })
        setTable(filterBySearch);
    }

    useEffect(() => {
        setTable(clients);
    }, [clients]);

    useEffect(() => {
        updateCsvPdfRows();
    }, [table]);

    const updateCsvPdfRows = () => {
        let csvPdfData = [];
        table.map((row) => (
            csvPdfData.push(
                {
                    col1: row.userId,
                    col2: row.displayName,
                    col3: row.firstName,
                    col4: row.balance.toFixed(2),
                    col5: row.share,
                    col6: user.share
                }
            )
        ));
        console.log("csv data", csvPdfData);
        setCsvPdfRows(csvPdfData);
    };

    const generatePDF = (e) => {
        e.preventDefault();
        const doc = new jsPDF();
        const tableColumn = csvPdfHeaders.map(header => header.label);
        const tableRows = csvPdfRows.map(row => [row.col1, row.col2, row.col3, row.col4, row.col5, row.col6]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
        });

        doc.save(userType + "-table-data.pdf");
    };

    const [currentPage, setCurrentPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const rowsPerPageOptions = [10, 20, 50];
    const totalEntries = 100; // Example value

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (rows) => {
        setRowsPerPage(rows);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    return (
        <div className="client-table-root">
            <div>
                <div className="my-downlines-csv-pdf-search-container">
                    <div className="my-downlines-csv-pdf-container">
                        {csvPdfRows && <CSVLink style={{ textDecoration: "none", paddingTop: "8px", paddingBottom: "8px" }} className="agent-table-csv-button" data={csvPdfRows} headers={csvPdfHeaders} filename={userType + "-table-data.csv"}>
                            CSV
                        </CSVLink>}
                        <button className="agent-table-pdf-button" onClick={(e) => generatePDF(e)}>
                            PDF
                        </button>
                    </div>
                    <div className="my-downlines-search-container">
                        <div style={{ justifyContent: "right", textAlign: "left", marginLeft: "10px", maxWidth: "300px" }}>
                            <input onChange={(e) => onSearchInputChange(e)} className="form-control" placeholder='Search'></input>
                        </div>
                    </div>
                </div>
            </div>
            <div className="client-table-container">
                <table className="client-table-custom">
                    <thead>
                        <tr className="client-table-header">
                            <th className="client-table-header-cell-grey">
                                <div className="client-table-header-title">ID</div>
                                {/* <div className="client-table-header-icons"><FaSortAmountDownAlt /></div> */}
                            </th>
                            <th className="client-table-header-cell">
                                <div className="client-table-header-title">User Name</div>
                                {/* <div className="client-table-header-icons"><FaLongArrowAltDown className="client-table-up-down-arrow-icons" /><FaLongArrowAltUp className="client-table-up-down-arrow-icons" /></div> */}
                            </th>
                            <th className="client-table-header-cell">
                                <div className="client-table-header-title">Name</div>
                                {/* <div className="client-table-header-icons"><FaLongArrowAltDown className="client-table-up-down-arrow-icons" /><FaLongArrowAltUp className="client-table-up-down-arrow-icons" /></div> */}
                            </th>
                            <th className="client-table-header-cell-small">
                                <div className="client-table-header-title">Match Comm.</div>
                                {/* <div className="client-table-header-icons"><FaLongArrowAltDown className="client-table-up-down-arrow-icons" /><FaLongArrowAltUp className="client-table-up-down-arrow-icons" /></div> */}
                            </th>
                            <th className="client-table-header-cell-small">
                                <div className="client-table-header-title">Ssn Comm.</div>
                                {/* <div className="client-table-header-icons"><FaLongArrowAltDown className="client-table-up-down-arrow-icons" /><FaLongArrowAltUp className="client-table-up-down-arrow-icons" /></div> */}
                            </th>
                            <th className="client-table-header-cell-small">
                                <div className="client-table-header-title">Share</div>
                                {/* <div className="client-table-header-icons"><FaLongArrowAltDown className="client-table-up-down-arrow-icons" /><FaLongArrowAltUp className="client-table-up-down-arrow-icons" /></div> */}
                            </th>
                            <th className="client-table-header-cell-actions">
                                <div className="client-table-header-title">Actions</div>
                                {/* <div className="client-table-header-icons"><FaLongArrowAltDown className="client-table-up-down-arrow-icons" /><FaLongArrowAltUp className="client-table-up-down-arrow-icons" /></div> */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {table && (rowsPerPage > 0
                            ? table.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : table
                        ).map((client, index) =>
                            <tr key={index} className="client-table-row">
                                <td className="client-table-custom-td-grey">{client.userId}</td>
                                <td className="client-table-custom-td">
                                    <Button appearance="link" className="client-display-name-link" style={{ textDecoration: "none", whiteSpace: "pre-wrap" }}
                                        onClick={(e) => handleImmediateChildren(e, client)}>{client.userId}<br></br>({client.firstName})
                                    </Button>
                                </td>
                                <td className="client-table-custom-td">{client.firstName}</td>
                                <td className="client-table-custom-td-small">{client.balance.toFixed(2)}</td>
                                <td className="client-table-custom-td-small">{client.share}</td>
                                <td className="client-table-custom-td-small">{user.share}</td>
                                <td className="client-table-custom-td-actions">
                                    <button className="client-table-edit-button" style={{ fontSize: "14px" }} onClick={(e) => edit(e, client)}>
                                        ✎ Edit
                                    </button>
                                    <button className="client-table-pwd-button" style={{ fontSize: "14px" }} onClick={(e) => changeUserPassword(e, client)}>
                                        PWD
                                    </button>
                                    {/* <button className="client-table-blk-button" style={{ fontSize: "14px"}} onClick={(e) => block(e, client)}>
                                        🔒 BLK
                                    </button> */}
                                </td>
                            </tr>)}
                        {/* {emptyRows > 0 && (
                            <tr style={{ height: 34 * emptyRows }}>
                                <td colSpan={3} aria-hidden />
                            </tr>
                        )} */}
                    </tbody>
                    {/* {table && <tfoot>
                        <tr>
                            <CustomTablePagination
                                rowsPerPageOptions={[1, 5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={6}
                                count={table.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        'aria-label': 'rows page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                        slots: {
                                            firstPageIcon: FirstPageRoundedIcon,
                                            lastPageIcon: LastPageRoundedIcon,
                                            nextPageIcon: ChevronRightRoundedIcon,
                                            backPageIcon: ChevronLeftRoundedIcon,
                                        },
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                    </tfoot>} */}
                </table>
            </div>
            <div className="agent-table-pagination-container">
                {table && <Pagination
                    totalEntries={table.length}
                    rowsPerPageOptions={rowsPerPageOptions}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage} />}
            </div>
            {/* <div>
                {table && <Pagination totalEntries={table.length} rowsPerPageOptions={[1, 2, 3]} />}
            </div> */}
            {/* <div>
                {table && <PaginationComponent
                    totalEntries={totalEntries}
                    rowsPerPageOptions={rowsPerPageOptions}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />}
            </div> */}
            {/* <div className="agent-table-pagination">
                <button
                    className="agent-table-pagination-button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &laquo; Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={`agent-table-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className="agent-table-pagination-button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next &raquo;
                </button>
            </div> */}
        </div>
    );
};

export default ClientTable;

const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    background: white;
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (orientation: portrait) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin-left: auto;
    margin-top: 3px;
    align-items: center;

    @media (orientation: portrait) {
      margin-left: 30px;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background-color: transparent;
      }
    }
  }
  `,
);