// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/CashLedger.css';
import { DEPOSIT_CASH, WITHDRAW_CASH } from '../common/Constants';
import TableHeader from '../TableHeader';

const EntityLedgerTable = ({ columns, data, tableHeader }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(5);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const totalPages = Math.ceil(data && data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data && data.slice(startIndex, startIndex + itemsPerPage);

  // Generate pagination buttons
  const pageNumbers = [];
  const visiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  if (endPage - startPage + 1 < visiblePages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="cash-ledger-table-root">
      <div className="cash-ledger-table-container">
        <table className="cash-ledger-table">
          <thead>
            {/* <tr> */}
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
            {/* </tr> */}
          </thead>
          <tbody>
            {currentData && currentData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  {row.transactionTime}
                </td>
                <td>
                  {row.transactionType === DEPOSIT_CASH && "Cash Received"}
                  {row.transactionType === WITHDRAW_CASH && "Cash Paid"}
                </td>
                <td>
                  {row.transactionType === DEPOSIT_CASH && "-"}
                  {row.transactionType === WITHDRAW_CASH && row.amount.toFixed(1)}
                </td>
                <td>
                  {row.transactionType === DEPOSIT_CASH && row.amount.toFixed(1)}
                  {row.transactionType === WITHDRAW_CASH && "-"}
                </td>
                <td>
                  {row.toUserBalance.toFixed(1)}
                </td>
                <td>
                  {row.remark}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="pagination-button"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="pagination-button"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}
      </div>
    </div>
  );
};

export default EntityLedgerTable;
