import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { httpHelpers } from "../services/httpHelpers";
import TokenService from "../services/token-service";
import { LOGOUT } from "../actions/types";
import "../styles/Table.css";
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import {Container} from '@mui/material';

function BoardAgentPagination() {
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [startRecord, setStartRecord] = useState(1);
    const [endRecord, setEndRecord] = useState(1);
    const api = httpHelpers();
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);

    const isMobile = useMediaQuery('(max-width:600px)');
    const role = "agent";
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const fetchCurrentRecords = () => {
        console.log("fetching data..")
        var url = `beta/getUsers?offset=${offset}&limit=${limit}`
        api
            .get(`${url}`)
            .then(res => {
                console.log("report res", res.data)
                console.log("report res users", res.data.users);
                setUsers(res.data.users);
                setTotalItems(res.data.count);
                setTotalPages(Math.ceil(res.data.count / limit));
                setStartRecord(offset + 1);
                setEndRecord(offset + limit);
            })
            .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    TokenService.removeUser(role);
                    logOut();
                }
            });
    }

    const logOut = useCallback(() => {
        if (role === "admin") {
            navigate("/logadmin");
        } else if (role === "agent") {
            navigate("/logagent");
        }
    }, [dispatch]);

    useEffect(() => {
        console.log("inside fetch records ..")
        fetchCurrentRecords();
    }, [offset, limit]);

    const handlePageChange = (event, value) => {
        setOffset((value - 1) * limit);
    };

    return (
        <div>
            <Container>
            <TableContainer component={Paper} className="pageable-table">
                <Table>
                    <TableHead className="table-head">
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>First Level Members</TableCell>
                            <TableCell>Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <Link to={{
                                        pathname: `/agent/user/${row.userName}`
                                    }} state={{user: row}}>
                                        {row.id}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.userName}</TableCell>
                                <TableCell>{row.phoneNumber}</TableCell>
                                <TableCell>{row.firstLevelCount}</TableCell>
                                <TableCell>{row.balance}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination className="table-pagination"
                count={Math.ceil(totalItems / limit)}
                page={(offset / limit) + 1}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                style={{ marginTop: isMobile ? '10px' : '20px' }}
            />
            </Container>
        </div>
    );

}

export default BoardAgentPagination;