const TableHeader = ({ title, minWidth, fontSize }) => {
    return (
        <div style={{ display: "table", width: "100%", minHeight: "50px", minWidth: `${minWidth}`, overflow: "hidden" }}>
            <div style={{ paddingLeft: "12px", fontSize: `${fontSize}`, display: "table-cell", height: "36px", textAlign: "left", verticalAlign: "middle", background: "#3963af", color: "white" }}>
                {title}
            </div>
        </div>
    );
};

export default TableHeader;