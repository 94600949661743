export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const BLOCK_SUCCESS = "BLOCK_SUCCESS";
export const BLOCK_FAIL = "BLOCK_FAIL";
export const OVERRIDE_RESULT_SUCCESS = "OVERRIDE_RESULT_SUCCESS";
export const OVERRIDE_RESULT_FAIL = "OVERRIDE_RESULT_FAIL";
export const SETTLE_SUCCESS = "SETTLE_SUCCESS";
export const SETTLE_FAIL = "SETTLE_FAIL";
export const APPROVE_RECHARGE_SUCCESS = "APPROVE_RECHARGE_SUCCESS";
export const APPROVE_RECHARGE_FAIL = "APPROVE_RECHARGE_FAIL";
export const DEPOSIT_SUCCESS = "DEPOSIT_SUCCESS";
export const DEPOSIT_FAIL = "DEPOSIT_FAIL";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_FAIL = "WITHDRAW_FAIL";
export const APPROVE_WITHDRAW_SUCCESS = "APPROVE_WITHDRAW_SUCCESS";
export const APPROVE_WITHDRAW_FAIL = "APPROVE_WITHDRAW_FAIL";
export const ADD_UPI_SUCCESS = "ADD_UPI_SUCCESS";
export const ADD_UPI_FAIL = "ADD_UPI_FAIL";
export const DELETE_UPI_SUCCESS = "DELETE_UPI_SUCCESS";
export const DELETE_UPI_FAIL = "DELETE_UPI_FAIL";
export const UPDATE_BALANCE_SUCCESS = "UPDATE_BALANCE_SUCCESS";
export const UPDATE_BALANCE_FAIL = "UPDATE_BALANCE_FAIL";
export const NOTIFY_ALL_USERS_SUCCESS = "NOTIFY_ALL_USERS_SUCCESS";
export const NOTIFY_ALL_USERS_FAIL = "NOTIFY_ALL_USERS_FAIL";
export const NOTIFY_USER_SUCCESS = "NOTIFY_USER_SUCCESS";
export const NOTIFY_USER_FAIL = "NOTIFY_USER_FAIL";
export const START_GAME_SUCCESS = "START_GAME_SUCCESS";
export const START_GAME_FAIL = "START_GAME_FAIL";
export const UPDATE_GAME_STATUS_SUCCESS = "UPDATE_GAME_STATUS_SUCCESS";
export const UPDATE_GAME_STATUS_FAIL = "UPDATE_GAME_STATUS_FAIL";
export const MAKE_TRANSACTION_SUCCESS = "MAKE_TRANSACTION_SUCCESS";
export const MAKE_TRANSACTION_FAIL = "MAKE_TRANSACTION_FAIL";
export const UPDATE_GLOBAL_PROPERTY_SUCCESS = "UPDATE_GLOBAL_PROPERTY_SUCCESS";
export const UPDATE_GLOBAL_PROPERTY_FAIL = "UPDATE_GLOBAL_PROPERTY_FAIL";
export const BLOCK_MARKET_SUCCESS = "BLOCK_MARKET_SUCCESS";
export const BLOCK_MARKET_FAIL = "BLOCK_MARKET_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
