import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import TokenService from "./services/token-service";
import SideDrawer from "./SideDrawer";
import MiniNavBar from "./MiniNavBar";
import CricHome from "./components/cric247/Home";
import Login from "./components/cric247/Login";
import HC from "./MiniHorizontalCollapse";

const Cric247App = () => {

    const [userId, setUserId] = useState();
    const [role, setRole] = useState();
    const [supportedSports, setSupportedSports] = useState();

    const { user: currentUser } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [visitedPaths, setVisitedPaths] = useState([]);

    const componentStyle = {
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '10px'
    };

    const logOut = useCallback(() => {
        // dispatch(logout("", role));
        TokenService.removeUser();
        navigate("/login");
    }, [dispatch]);

    useEffect(() => {
        // if (role === "agent")
        //   url = "beta/getBalance";
    }, [role]);

    useEffect(() => {
        console.log("location", location);
        console.log("visitedPaths", visitedPaths);
        setVisitedPaths(prevPaths => {
            const currentIndex = prevPaths.indexOf(location.pathname);
            if (currentIndex === -1) {
                return [...prevPaths, location.pathname]
            } else {
                return prevPaths.slice(0, currentIndex + 1);
            }
        });

        if (location.pathname === "/login" && visitedPaths && visitedPaths.length > 1) {
            logout();
        }
    }, [location]);

    useEffect(() => {
        console.log('cus', currentUser)
        if (currentUser) {
            setRole(TokenService.getRole());
            if (currentUser && currentUser.accountStatus) {
                if (currentUser.accountStatus.includes("ACTIVE")) {
                    setSupportedSports(currentUser.supportedSports);
                } else if (currentUser.accountStatus.includes("NEW")) {
                    setSupportedSports(currentUser.supportedSports);
                }
            }
        } else {
            console.log("JWT is null, not authorize!");
        }
    }, [currentUser]);

    const logout = () => {
        logOut();
        window.location.reload();
    }

    return (
        <div className="App">
            {/* {currentUser && role && <FlexNavBar role={role} logout={logout} supportedSports={supportedSports} visitedPaths={visitedPaths} user={currentUser} />} */}
            {currentUser && role && <MiniNavBar role={role} logout={logout} supportedSports={supportedSports} visitedPaths={visitedPaths} user={currentUser} />}
            {/* <SideDrawer /> */}
            <Routes>
                <Route path="/login" exact element={role ? <CricHome role={role} logout={logout} /> : <Login role={role} logout={logout} />} />
                <Route path="/" exact element={role ? <CricHome role={role} logout={logout} /> : <Login role={role} logout={logout} />} />
            </Routes>
            {/* {currentUser && role && <HC role={role} logout={logout} supportedSports={supportedSports} visitedPaths={visitedPaths} user={currentUser} />} */}
            {/* <HC /> */}
            {/* <DashboardNavbar /> */}
            {/* <NavbarTemp /> */}
        </div>
    );
}

export default Cric247App;