import React from 'react';
import './SecondTable.css';

const SecondTable = ({ data }) => {
  return (
    <table className="second-table">
      <thead>
        <tr>
          <th>Col 1</th>
          <th>Col 2</th>
          <th>Col 3</th>
          <th>Col 4</th>
          <th>Col 5</th>
          <th>Col 6</th>
          <th>Col 7</th>
          <th>Col 8</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className="col1">{row.col1}</td>
            <td className="col2">{row.col2}</td>
            <td className="col3">{row.col3}</td>
            <td className="col4">{row.col4}</td>
            <td className="col5">{row.col5}</td>
            <td className="col6">{row.col6}</td>
            <td className="col7">{row.col7}</td>
            <td className="col8">{row.col8}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SecondTable;
