import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ParvatiApp from './ParvatiApp';
import Cric247App from './Cric247App';
import SideDrawerApp from './SideDrawerApp';
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import "./styles/Adag.css"

// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <App/> */}
      {/* <ParvatiApp /> */}
      <Cric247App />
      {/* <SideDrawerApp /> */}
    </BrowserRouter>
  </Provider>, document.getElementById('root')
);