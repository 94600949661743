import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { FaArrowRight, FaRupeeSign } from "react-icons/fa";

export default function NationsPostion({ teams }) {
    console.log("Nations", teams);
    const SUSPENDED = "SUSPENDED";
    const CLOSED = "CLOSED";
    const ACTIVE = "ACTIVE";

    const [showBetButtons, setShowBetButtons] = useState(false);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState();
    const [backSelected, setBackSelected] = useState();
    const [inputValue, setInputValue] = useState("");

    const player1Exposure = 0;
    const player2Exposure = 0;

    const backColor = "#90EE90";
    const layColor = "#F9A9BA";

    const bets = [
        "10",
        "50",
        "100",
        "250",
        "500",
        "750",
        "1000",
        "1250",
        "1500",
        "2000",
    ];

    var betBackgroundColor;
    const getBetBackgroundColorWithOpacity = () => {
        if (backSelected) {
            betBackgroundColor = backColor + "40";
        } else {
            betBackgroundColor = layColor + "40";
        }
        return betBackgroundColor;
    };

    const handleBackBoxClick = (index) => {
        // Toggle the visibility of the bet buttons box
        if (!backSelected) {
            setBackSelected(true);
        } else {
            setShowBetButtons(!showBetButtons);
            setBackSelected(true);
        }
        setSelectedTeamIndex(index);
        // setInputValue("");
        // setReturnsTeam1(0.0);
        // setReturnsTeam2(0.0);
        // setBetType("BACK");
        // setRate(teams.at(index).backRate);
    };

    const handleLayBoxClick = (index) => {
        // Toggle the visibility of the bet buttons box
        if (backSelected) {
            setBackSelected(false);
        } else {
            setShowBetButtons(!showBetButtons);
            setBackSelected(false);
        }
        setSelectedTeamIndex(index);
        // setInputValue("");
        // setReturnsTeam1(0.0);
        // setReturnsTeam2(0.0);
        // setBetType("LAY");
        // setRate(teams.at(index).layRate);
    };

    const handleBetButtonClick = (bet, index) => {
        // Set the selected bet and hide the bet buttons box
        // setInputValue(bet);
        // showAndSetReturns(index, bet);
    };

    const handleInputChange = (event, index) => {
        // only allow numeric value
        // const numericValue = event.target.value.replace(/[^0-9]/g, "");
        // setInputValue(numericValue);
        // showAndSetReturns(index, numericValue);
    };

    const handlePlaceBetClick = (e) => {
        // e.preventDefault();
        // setSuccessful(false);

        // console.log("place bet", rate);
        // if (inputValue.length <= 0) {
        //     alert("Please enter bet amount!");
        //     return;
        // }
        // dispatch(placeBet(userId, gameId, inputValue, rate, betType, betCandidate))
        //     .then((data) => {
        //         console.log("place bet data: ", data);
        //         if (data.status === 401) {
        //             logout();
        //         } else if (data.status === 200) {
        //             setSuccessful(true);
        //             fetchBetsHistory();
        //         } else {
        //             setSuccessful(false);
        //             alert(data.data);
        //         }
        //     })
        //     .catch(() => {
        //     });
    };

    return (
        <div>
            {teams.map((team, index) => (
                <div key={index}>
                    <Card className="team-card" square={true}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "stretch",
                                flexDirection: "row",
                                backgroundColor: "#f8f8f8",
                                textAlign: "left"
                            }}
                        >
                            {/* Team Name (70% width) */}
                            <Box flex={0.6} marginRight="16px" sx={{ padding: "2px", fontSize: "14px", paddingLeft: "4px" }}
                                key={index === 0 ? player1Exposure + team.name : player2Exposure + team.name}
                                className="flash">
                                <Typography variant="h7" component="div" style={{ fontWeight: "bold"}}>
                                    {team.name}
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        color: "#D0011C",
                                        marginTop: "0px",
                                        verticalAlign: "middle" 
                                    }}
                                >
                                    <FaArrowRight
                                        color="#D0011C"
                                        style={{ fontSize: "12px", marginTop: "4px" }}
                                    />
                                    <Typography
                                        variant="h7"
                                        component="div"
                                        display={{ marginLeft: "3px", fontWeight: "bold" }}
                                    >
                                        {index === 0 && (
                                            <span
                                                style={{
                                                    color: player1Exposure >= 0 ? "green" : "#D0011C",
                                                }}
                                            >
                                                {player1Exposure}
                                            </span>
                                        )}
                                        {index === 1 && (
                                            <span
                                                style={{
                                                    color: player2Exposure >= 0 ? "green" : "#D0011C",
                                                }}
                                            >
                                                {player2Exposure}
                                            </span>
                                        )}
                                    </Typography>
                                </div>
                            </Box>
                            {/* Back Section (15% width) */}
                            {team.status === ACTIVE && <Box
                                flex={0.2}
                                backgroundColor={backColor}
                                style={{
                                    background: "rgb(40 108 79 / 60%)",
                                    padding: "10px",
                                }}
                                position="relative"
                                // onClick={() => handleBackBoxClick(index)}
                                key={team.backRate + team.backStake + index + "back"}
                                className="flash-blue"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "30%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        fontWeight: "200",
                                        color: "white",
                                    }}
                                >
                                    <b>{team.backRate}</b>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "70%", // Adjusted position for the third Typography element
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "white",
                                    }}
                                >
                                    {team.backStake}K
                                </Typography>
                            </Box>}
                            {(team.status === SUSPENDED || team.status === CLOSED) && <Box
                                flex={0.2}
                                backgroundColor={backColor}
                                style={{
                                    background: "rgb(40 108 79 / 60%)",
                                    padding: "9px",
                                    backgroundColor: "white",
                                    opacity: "0.5",
                                    border: "0.1px solid red"
                                }}
                                position="relative"
                            // onClick={() => handleBackBoxClick(index)}
                            // key={team.backRate}
                            // className="flash"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "50%", // Adjusted position for the third Typography element
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "red",
                                    }}
                                >
                                    {team.status}
                                </Typography>
                            </Box>}
                            {/* Lay Section (15% width) */}
                            {team.status === ACTIVE && <Box
                                flex={0.2}
                                backgroundColor={layColor}
                                position="relative"
                                // onClick={() => handleLayBoxClick(index)}
                                style={{
                                    padding: "10px",
                                }}
                                key={team.layRate + team.layStake + index + "lay"}
                                className="flash-yellow"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "30%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                    }}
                                >
                                    <b>{team.layRate}</b>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "70%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                    }}
                                >
                                    {team.layStake}K
                                </Typography>
                            </Box>}
                            {(team.status === SUSPENDED || team.status === CLOSED) && <Box
                                flex={0.2}
                                backgroundColor={backColor}
                                style={{
                                    background: "rgb(40 108 79 / 60%)",
                                    padding: "9px",
                                    backgroundColor: "white",
                                    opacity: "0.5",
                                    border: "0.1px solid red"
                                }}
                                position="relative"
                            // onClick={() => handleBackBoxClick(index)}
                            // key={team.backRate}
                            // className="flash"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "50%", // Adjusted position for the third Typography element
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "red",
                                    }}
                                >
                                    {team.status}
                                </Typography>
                            </Box>}
                        </div>
                        {/* Bet Buttons Box */}
                        {showBetButtons && index == selectedTeamIndex && (
                            <div>
                                <Card variant="outlined" square={true}>
                                    <Box
                                        sx={{
                                            padding: "10px",
                                            backgroundColor: getBetBackgroundColorWithOpacity(),
                                        }}
                                    >
                                        <div className="game-bets">
                                            {bets.map((bet, indexBet) => (
                                                <Button
                                                    variant="contained"
                                                    color="inherit"
                                                    key={indexBet}
                                                // onClick={() => handleBetButtonClick(bet, index)}
                                                >
                                                    {bet}
                                                </Button>
                                            ))}
                                        </div>
                                    </Box>
                                </Card>
                                <Card variant="outlined" square={true}>
                                    <Box
                                        sx={{
                                            padding: "10px",
                                            backgroundColor: getBetBackgroundColorWithOpacity(),
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex"
                                                }}
                                            >
                                                <FaRupeeSign style={{
                                                    marginTop: "8px",
                                                    marginRight: "5px"
                                                }} />
                                                <TextField
                                                    style={{ width: "100px" }}
                                                    variant="standard"
                                                    type="text"
                                                    value={inputValue}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex"
                                                }}
                                            >
                                                <label style={{
                                                    marginTop: "8px",
                                                    marginRight: "5px"
                                                }}>Rate: </label>
                                                <TextField
                                                    style={{ width: "100px" }}
                                                    variant="standard"
                                                    type="text"
                                                    value={inputValue}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            </div>
                                            {/* <div
                                                            style={{
                                                                display: "flex",
                                                                color: "#D0011C",
                                                                marginTop: "10px",
                                                            }}
                                                        >
                                                            <FaArrowRight
                                                                color="#D0011C"
                                                                style={{ fontSize: "18px" }}
                                                            />
                                                            <Typography
                                                                variant="h7"
                                                                component="div"
                                                                display={{ marginLeft: "3px", fontWeight: "bold" }}
                                                            >
                                                                {index === 0 && (
                                                                    <span
                                                                    style={{
                                                                        color: returnsTeam1 >= 0 ? "green" : "#D0011C",
                                                                    }}
                                                                    >
                                                                        {returnsTeam1.toFixed(2)}
                                                                    </span>
                                                                )}
                                                                {index === 1 && (
                                                                    <span
                                                                    style={{
                                                                        color: returnsTeam2 >= 0 ? "green" : "#D0011C",
                                                                    }}
                                                                    >
                                                                        {returnsTeam2.toFixed(2)}
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                        </div> */}
                                            <div style={{ marginLeft: "10px" }}>
                                                <Button variant="contained" size="small"
                                                    // onClick={(e) => handlePlaceBetClick(e)}
                                                >
                                                    Place Bet
                                                </Button>
                                            </div>
                                        </div>
                                    </Box>
                                </Card>
                            </div>
                        )}
                    </Card>
                </div>
            ))}
        </div>
    );
}