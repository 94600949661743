import React, { useState, useRef, useEffect } from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, TextField } from '@mui/material';
// import { Button } from '@mui/material';
import { FaRupeeSign } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';
import Notify from './Notify';
import './style/Video.css';
// import "../App.css";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { logout, startGame, updateGameStatus } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../actions/message";
import { httpHelpers } from "../../services/httpHelpers";
import { useNavigate } from 'react-router-dom';
import TokenService from '../../services/token-service';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const GameScreen2 = ({ role, logout, game, gameLink, preLive }) => {
    console.log("game", gameLink, game);
    const [newGame, setNewGame] = useState(game);
    const [gameId, setGameId] = useState(game ? game.id : preLive.id);
    const [title, setTitle] = useState(game ? game.title : preLive.title);
    const [date, setDate] = useState(game ? game.date : preLive.date);
    const [startTime, setStartTime] = useState(game ? game.startTime : preLive.startTime);
    const [player1, setPlayer1] = useState(game ? game.player1 : preLive.player1);
    const [player2, setPlayer2] = useState(game ? game.player2 : preLive.player2);
    const [gameStatus, setGameStatus] = useState(game ? game.gameStatus : preLive.gameStatus);
    console.log("gameStatus", gameStatus);
    const [streamLink, setStreamLink] = useState(game ? game.streamLink : gameLink);
    const [player1BackRate, setPlayer1BackRate] = useState(game ? game.player1BackRate : "");
    const [player2BackRate, setPlayer2BackRate] = useState(game ? game.player2BackRate : "");
    const [player1LayRate, setPlayer1LayRate] = useState(game ? game.player1LayRate : "");
    const [player2LayRate, setPlayer2LayRate] = useState(game ? game.player2LayRate : "");
    const [showBetButtons, setShowBetButtons] = useState(false);
    const [selectedBet, setSelectedBet] = useState('');
    const [backSelected, setBackSelected] = useState();
    const [inputValue, setInputValue] = useState('');
    const [selectedTeamIndex, setSelectedTeamIndex] = useState();
    const [returnsTeam1, setReturnsTeam1] = useState(game ? game.player1ProfitLoss : "");
    const [returnsTeam2, setReturnsTeam2] = useState(game ? game.player2ProfitLoss : "");

    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const [gameStatusSuccessful, setGameStatusSuccessful] = useState(false);
    const [gameStatusMessage, setGameStatusMessage] = useState(null);
    const [startGameUpdateOddsButton, setStartGameUpdateOddsButton] = useState(gameStatus === "UPCOMING" ? "Start Game" : "Update Odds");
    const [startGameUpdateOddsMessage, setStartGameUpdateOddsMessage] = useState(null);
    const [disableUpdateOddsButton, setDisableUpdateOddsButton] = useState("");
    const getLiveGames = "/alpha/getLiveGame";
    const api = httpHelpers();

    useEffect(() => {
        dispatch(clearMessage());
        // Set up a timer to fetch the balance every 5 seconds
        const intervalId = setInterval(() => {
            fetchLiveGames();
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (gameStatus === "LIVE") {
            setDisableUpdateOddsButton("disabled");
        } else {
            setDisableUpdateOddsButton("");
        }
    }, [gameStatus])

    const fetchLiveGames = () => {
        api
            .get(`${getLiveGames}`)
            .then(res => {
                console.log("live games", res);
                if (res && res.data) {
                    setNewGame(res.data);
                    setReturnsTeam1(res.data.player1ProfitLoss);
                    setReturnsTeam2(res.data.player2ProfitLoss);
                }
            })
            .catch(err => {
                console.log(err)
                if (err && err.response && err.response.status === 401) {
                    logout();
                }
            });
    };

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const teams = [
        { name: player1, backRate: player1BackRate, layRate: player1LayRate },
        { name: player2, backRate: player2BackRate, layRate: player2LayRate }
        // Add more teams as needed
    ];

    const backColor = "#90EE90";
    const layColor = "#F9A9BA";

    const bets = ["10", "50", "100", "250", "500", "750", "1000", "1250", "1500", "2000"];

    var betBackgroundColor;
    const getBetBackgroundColorWithOpacity = () => {
        if (backSelected) {
            betBackgroundColor = backColor + "80";
        } else {
            betBackgroundColor = layColor + "80";
        }
        return betBackgroundColor
    }

    const handleBackBoxClick = (index) => {
        // Toggle the visibility of the bet buttons box
        if (!backSelected) {
            setBackSelected(true);
        } else {
            setShowBetButtons(!showBetButtons);
            setSelectedBet('');
            setBackSelected(true);
        }
        setSelectedTeamIndex(index);
        setInputValue('');
        // setReturnsTeam1(0.0);
        // setReturnsTeam2(0.0);
    };

    const handleLayBoxClick = (index) => {
        // Toggle the visibility of the bet buttons box
        if (backSelected) {
            setBackSelected(false);
        } else {
            setShowBetButtons(!showBetButtons);
            setSelectedBet('');
            setBackSelected(false);
        }
        setSelectedTeamIndex(index);
        setInputValue('');
        // setReturnsTeam1(0.0);
        // setReturnsTeam2(0.0);
    };

    const handleBetButtonClick = (bet, index) => {
        // Set the selected bet and hide the bet buttons box
        setSelectedBet(bet);
        setInputValue(bet);
        showAndSetReturns(index, bet);
    };

    const handleInputChange = (event, index) => {
        // only allow numeric value
        const numericValue = event.target.value.replace(/[^0-9]/g, '');
        setInputValue(numericValue); // Update the input value
        showAndSetReturns(index, numericValue);
    };

    function getReturnValue(returnRate, betAmount) {
        const integerPart = Math.floor(returnRate);
        const decimalPart = returnRate - integerPart;

        // Subtract 1 from the integer part
        const modifiedIntegerPart = integerPart - 1;

        const result = (modifiedIntegerPart * betAmount) + (decimalPart * betAmount);

        return result;
    }

    function showAndSetReturns(index, bet) {
        if (index == 0 && backSelected) {
            setReturnsTeam1(getReturnValue(teams.at(0).backRate, bet));
            setReturnsTeam2(-1 * bet);
        }
        if (index == 0 && !backSelected) {
            setReturnsTeam1(-1 * getReturnValue(teams.at(0).layRate, bet));
            setReturnsTeam2(bet);
        }
        if (index == 1 && backSelected) {
            setReturnsTeam2(getReturnValue(teams.at(1).backRate, bet));
            setReturnsTeam1(-1 * bet);
        }
        if (index == 1 && !backSelected) {
            setReturnsTeam2(-1 * getReturnValue(teams.at(1).layRate, bet));
            setReturnsTeam1(bet);
        }
    }

    function handleDecimalsOnValue(value) {
        const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,3})/s;
        return value.match(regex)[0];
    }

    const onChangePlayer1BackRate = (e) => {
        const p1br = e.target.value;
        setPlayer1BackRate(handleDecimalsOnValue(p1br));
        setSuccessful(false);
    };

    const onChangePlayer2BackRate = (e) => {
        const p2br = e.target.value;
        setPlayer2BackRate(handleDecimalsOnValue(p2br));
        setSuccessful(false);
    };

    const onChangePlayer1LayRate = (e) => {
        const p1lr = e.target.value;
        setPlayer1LayRate(handleDecimalsOnValue(p1lr));
        setSuccessful(false);
    };

    const onChangePlayer2LayRate = (e) => {
        const p2lr = e.target.value;
        setPlayer2LayRate(handleDecimalsOnValue(p2lr));
        setSuccessful(false);
    };

    const complete = (e) => {
        e.preventDefault();
        navigate('/matchcomplete', { state: { newGame } });
    };

    const cancel = (e) => {
        e.preventDefault();
        setGameStatusMessage("CANCELLED");
        handleGameStatus(newGame.id, "CANCELLED");
    };

    const suspend = (e) => {
        e.preventDefault();
        if (gameStatus === "SUSPENDED") {
            setGameStatusMessage("LIVE");
            handleGameStatus(newGame.id, "LIVE");
        } else {
            setGameStatusMessage("SUSPENDED");
            handleGameStatus(newGame.id, "SUSPENDED");
        }
    };

    const masterBook = (e) => {
        e.preventDefault();
        navigate('/masterbook', { state: { gameId, player1, player2 } });
    };

    const userBook = (e) => {
        e.preventDefault();
        const childId = TokenService.getUserName();
        const fromUserBook = true;
        navigate('/mbagent', { state: { childId, gameId, player1, player2, fromUserBook } });
    };

    const handleGameStatus = (id, gameStatus) => {
        setSuccessful(false);
        console.log("game status", role);
        dispatch(updateGameStatus(id, gameStatus))
            .then((data) => {
                console.log("game status data: ", data);
                if (data.status === 401) {
                    if (data.data === "Wrong password") {
                        setGameStatusMessage(null);
                        setGameStatusSuccessful(false);
                    } else {
                        logout();
                    }
                } else if (data.status === 200) {
                    setGameStatus(data.data.gameStatus);
                    setGameStatusSuccessful(true);
                } else {
                    setGameStatusMessage(null);
                    setGameStatusSuccessful(false);
                }
            })
            .catch(() => {
            });
    };

    const handleUpdateOdds = (e) => {
        e.preventDefault();
        form.current.validateAll();

        if (player1BackRate.length <= 0 || player2BackRate.length <= 0
            || player1LayRate.length <= 0 || player2LayRate.length <= 0) {
            setSuccessful(true);
            setStartGameUpdateOddsMessage("Odds can't be empty!");
            return;
        }
        if (checkBtn.current.context._errors.length === 0) {
            console.log("update odds");
            dispatch(startGame(gameId, streamLink, player1BackRate, player2BackRate, player1LayRate, player2LayRate))
                .then((res) => {
                    console.log("up odds data: ", res);
                    if (res.status === 401) {
                        if (res.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (res.status === 200) {
                        if (startGameUpdateOddsButton === "Start Game") {
                            setStartGameUpdateOddsMessage("Game Started!");
                        } else {
                            setStartGameUpdateOddsMessage("Odds Updated!");
                        }
                        setSuccessful(true);
                        setNewGame(res.data);
                        setGameId(res.data.id);
                        setTitle(res.data.title);
                        setDate(res.data.date);
                        setStartTime(res.data.startTime);
                        setPlayer1(res.data.player1);
                        setPlayer2(res.data.player2);
                        setGameStatus(res.data.gameStatus);
                        console.log("gameStatus", gameStatus);
                        setPlayer1BackRate(res.data.player1BackRate);
                        setPlayer2BackRate(res.data.player2BackRate);
                        setPlayer1LayRate(res.data.player1LayRate);
                        setPlayer2LayRate(res.data.player2LayRate);
                        setReturnsTeam1(res.data.player1ProfitLoss);
                        setReturnsTeam2(res.data.player2ProfitLoss);
                    } else {
                        if (res.status === 400) {
                            setSuccessful(true);
                            setStartGameUpdateOddsMessage(res.data);
                        } else {
                            setSuccessful(false);
                        }
                    }
                })
                .catch((err) => {
                    console.log("up odds", err);
                });
        }
    };

    return (
        <div className="wrapper">
            {/* YouTube embedded iframe */}
            <div style={{ display: "flex", marginTop: "50px", justifyContent: "center", backgroundColor: "#48aaad", padding: "5px", marginBottom: "0px", alignContent: "center", color: "white" }}>
                <Typography variant="h5" component="div" sx={{ fontFamily: "Brush Script", paddingTop: "0px" }}>
                    {title}
                </Typography>
            </div>
            <div className="live-matches-container">
                <div className="live-matches-left">
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        paddingBottom: '56.25%', // 16:9 aspect ratio
                    }}>
                        <iframe
                            width="560"
                            height="315"
                            src={streamLink}
                            title="YouTube Video"
                            frameBorder="0"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                </div>
                <div className="live-matches-right" style={{ background: "white", paddingTop: "5px" }}>
                    {/* <div>
                        <Card variant="outlined" square={true}>
                            <Notify game={game} />
                        </Card>
                    </div> */}
                    {/* Card with two rows for two teams */}
                    <div>
                        <Form onSubmit={handleUpdateOdds} ref={form}>
                            {teams.map((team, index) => (
                                <div key={index}>
                                    <Card square={true} className="team-card" style={{ marginBottom: "5px" }}>
                                        <div style={{ display: 'flex', alignItems: "stretch", flexDirection: 'row' }}>
                                            {/* Team Name (70% width) */}
                                            <Box flex={0.6} marginRight="16px" sx={{ padding: "9px", textAlign: "left" }}
                                                key={index === 0 ? returnsTeam1 : returnsTeam2}
                                                className="flash">
                                                <Typography variant="h7" component="div">
                                                    {team.name}
                                                </Typography>
                                                <div style={{ display: "flex", color: "#D0011C", marginTop: "10px" }}>
                                                    <FaArrowRight color='#D0011C' style={{ fontSize: "18px" }} />
                                                    <Typography variant="h7" component="div" display={{ marginLeft: "3px", fontWeight: "bold" }}>
                                                        {index === 0 && (
                                                            <span style={{ color: returnsTeam1 >= 0 ? 'green' : '#D0011C' }}>
                                                                {returnsTeam1.toFixed(3)}
                                                            </span>
                                                        )}
                                                        {index === 1 && (
                                                            <span style={{ color: returnsTeam2 >= 0 ? 'green' : '#D0011C' }}>
                                                                {returnsTeam2.toFixed(3)}
                                                            </span>
                                                        )}
                                                    </Typography>
                                                </div>
                                            </Box>
                                            {/* Back Section (15% width) */}
                                            <Box flex={0.5} backgroundColor={backColor} position="relative"
                                            // onClick={() => handleBackBoxClick(index)}
                                            >
                                                {/* Content for the Back section */}
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    style={{
                                                        textAlign: 'center',
                                                        position: 'absolute',
                                                        top: '20%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        margin: '0',
                                                        marginTop: "3px"
                                                    }}
                                                >
                                                    Back
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    style={{
                                                        textAlign: 'center',
                                                        position: 'absolute',
                                                        top: '70%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        margin: '0',
                                                    }}
                                                >
                                                    {team.backRate}
                                                    {/* {index === 0 && <Input
                                                        id="reg_player1backrate"
                                                        type="text"
                                                        className="form-control"
                                                        name="player1backrate"
                                                        value={player1BackRate}
                                                        onChange={onChangePlayer1BackRate}
                                                        style={{ width: "80px" }}
                                                    // validations={[required]}
                                                    />}
                                                    {index === 1 && <Input
                                                        id="reg_player2backrate"
                                                        type="text"
                                                        className="form-control"
                                                        name="player2backrate"
                                                        value={player2BackRate}
                                                        onChange={onChangePlayer2BackRate}
                                                        style={{ width: "80px" }}
                                                    // validations={[required]}
                                                    />} */}
                                                </Typography>
                                            </Box>
                                            {/* Lay Section (15% width) */}
                                            <Box flex={0.5} backgroundColor={layColor} position="relative"
                                            // onClick={() => handleLayBoxClick(index)}
                                            >
                                                {/* Content for the Lay section */}
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    style={{
                                                        textAlign: 'center',
                                                        position: 'absolute',
                                                        top: '20%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        margin: '0',
                                                        marginTop: "3px"
                                                    }}
                                                >
                                                    Lay
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    style={{
                                                        textAlign: 'center',
                                                        position: 'absolute',
                                                        top: '70%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        margin: '0',
                                                    }}
                                                >
                                                    {team.layRate}
                                                    {/* {index === 0 && <Input
                                                        id="reg_player1layrate"
                                                        type="text"
                                                        className="form-control"
                                                        name="player1layrate"
                                                        value={player1LayRate}
                                                        onChange={onChangePlayer1LayRate}
                                                        style={{ width: "80px" }}
                                                    // validations={[required]}
                                                    />}
                                                    {index === 1 && <Input
                                                        id="reg_player2layrate"
                                                        type="text"
                                                        className="form-control"
                                                        name="player2layrate"
                                                        value={player2LayRate}
                                                        onChange={onChangePlayer2LayRate}
                                                        style={{ width: "80px" }}
                                                    // validations={[required]}
                                                    />} */}
                                                </Typography>
                                            </Box>

                                        </div>
                                        {/* Bet Buttons Box */}
                                        {/* {(showBetButtons && index == selectedTeamIndex) && (
                                            <div>
                                                <Card variant="outlined" square={true}>
                                                    <Box sx={{ padding: "10px", backgroundColor: getBetBackgroundColorWithOpacity() }}>

                                                        <div className="game-bets">
                                                            {bets.map((bet, indexBet) => (
                                                                <Button variant='contained' color="inherit"
                                                                    key={indexBet}
                                                                    onClick={() => handleBetButtonClick(bet, index)}
                                                                >
                                                                    {bet}
                                                                </Button>
                                                            ))}
                                                        </div>
                                                    </Box>
                                                </Card>
                                                <Card variant="outlined">
                                                    <Box sx={{ padding: "10px", backgroundColor: getBetBackgroundColorWithOpacity() }}>

                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <FaRupeeSign />
                                                            <TextField variant="standard" type="text" value={inputValue} onChange={(e) => handleInputChange(e, index)} />
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <Button variant="contained" size="small">Place Bet</Button>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </Card>
                                            </div>
                                        )} */}
                                    </Card>
                                    {/* {successful && index === 1 && <div className="div-label-form">
                                        <label style={{ marginLeft: "15px" }}>{startGameUpdateOddsMessage}</label>
                                    </div>}
                                    {index === 1 && <div className="form-group" style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "5px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark"
                                            style={{ width: "94%" }}
                                        // disabled={disableUpdateOddsButton}
                                        >
                                            {startGameUpdateOddsButton}
                                        </button>
                                    </div>} */}
                                </div>
                            )
                            )}

                            {/* <CheckButton style={{ display: "none" }} ref={checkBtn} /> */}
                        </Form>
                    </div>
                    {/* {gameStatusSuccessful && <div>
                        <label style={{ marginLeft: "15px" }}>{"Game " + gameStatusMessage + "!"}</label>
                    </div>} */}
                    {(gameStatus === "LIVE" || gameStatus === "SUSPENDED") && <div>
                        {/* <div className="live-matches-operation-button-container">
                            <div className="live-matches-buttons">
                                <ButtonToolbar style={{ padding: 1, justifyContent: "center" }}>
                                    <Button color="green" appearance="ghost" size="lg"
                                        onClick={(e) => complete(e)} style={{ width: "29%" }} >
                                        Complete
                                    </Button>
                                    <Button color="red" appearance="ghost" size="lg"
                                        onClick={(e) => cancel(e)} style={{ width: "29%" }} >
                                        Cancel
                                    </Button>
                                    <Button color="cyan" appearance="ghost" size="lg"
                                        onClick={(e) => suspend(e)} style={{ width: "29%" }} >
                                        {gameStatus === "LIVE" ? "Suspend" : "Resume"}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </div> */}
                        <div style={{ marginTop: "5px", marginLeft: "4px", paddingBottom: "6px" }}>
                            <button className="board-buttons board-buttons-nav-bar-dark"
                                style={{ width: "46%" }}
                                onClick={(e) => masterBook(e)} >
                                Master Book
                            </button>
                            <button className="board-buttons board-buttons-nav-bar-dark"
                                style={{ width: "46%" }}
                                onClick={(e) => userBook(e)} >
                                User Book
                            </button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default GameScreen2;