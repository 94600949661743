import "../../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { myDownline } from "./Columns";
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

export default function MyDownlineTable({ role, logout, open, isSmallScreen }) {
    console.log("show", role);
    const href = window.location.href;
    let agentType = "user";
    let addType = "User";
    if (href.includes("showsc")) {
        agentType = "subcompany";
        addType = "SC";
    } else if (href.includes("showsst")) {
        agentType = "superstockist";
        addType = "SST";
    } else if (href.includes("showst")) {
        agentType = "stockist";
        addType = "ST";
    } else if (href.includes("showagent")) {
        agentType = "agent";
        addType = "Agent";
    }
    console.log("agentType", agentType);

    const getChildrenByType = "/beta/getChildrenByType?type=" + agentType + "&offset=0&limit=20";
    const api = httpHelpers();
    const [childrenByType, setChildrenByType] = useState(null);
    let navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - childrenByType.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchChildrenByType = () => {
        api
            .get(`${getChildrenByType}`)
            .then(res => {
                console.log("children", res);
                if (res && res.data && res.data.length > 0) {
                    setChildrenByType(res.data);
                } else {
                    setChildrenByType(null);
                }
            })
            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    const openRegister = (e) => {
        e.preventDefault();
        if (agentType.includes("subcompany")) {
            navigate('/registersc');
        } else if (agentType.includes("superstockist")) {
            navigate('/registersst');
        } else if (agentType.includes("stockist")) {
            navigate('/registerst');
        } else if (agentType.includes("agent")) {
            navigate('/registeragent');
        } else if (agentType.includes("user")) {
            navigate('/registeruser');
        }
    };

    const deposit = (e, child) => {
        e.preventDefault();
        navigate('/deposit', { state: { child } });
    };

    const withdraw = (e, child) => {
        e.preventDefault();
        navigate('/withdraw', { state: { child } });
    };

    const handleImmediateChildren = (e, child) => {
        e.preventDefault();
        if (child.userType.includes("subcompany")) {
            navigate('/childrenssc', { state: { child } });
        } else if (child.userType.includes("superstockist")) {
            navigate('/childrenssst', { state: { child } });
        } else if (child.userType.includes("stockist")) {
            navigate('/childrensst', { state: { child } });
        } else if (child.userType.includes("agent")) {
            navigate('/childrensagent', { state: { child } });
        }
    };

    const edit = (e, child) => {
        e.preventDefault();
        navigate('/edit', { state: { child } });
    };

    const statement = (e, child) => {
        e.preventDefault();
        navigate('/statement', { state: { child } });
    };

    const changeUserPassword = (e, child) => {
        e.preventDefault();
        navigate('/changeuserpassword', { state: { child } });
    };

    useEffect(() => {
        fetchChildrenByType();
    }, [agentType]);

    const ButtonStyle = { margin: "0px 10px" };

    const Row = ({ child }) => {
        return (
            <tr>
                {child.entityType !== "user" && <td className="master-list-title-td">
                    <Button appearance="link"
                        onClick={(e) => handleImmediateChildren(e, child)}>{child.userId}</Button>
                </td>}
                {child.entityType === "user" && <td className="master-list-title-td">
                    {child.userId}
                </td>}
                <td className="master-list-title-td">{child.balance}</td>
                {child.entityType !== "user" && <td className="master-list-title-td-blue">{child.share}</td>}
                {child.entityType !== "user" && <td className="master-list-title-td-blue">{child.share}</td>}
                {/* <td className="master-list-title-td">
                    {child.status === "ACTIVE" &&
                        <label className="label-status-active">{child.status}</label>}
                    {child.status === "BLOCKED" &&
                        <label className="label-status-blocked">{child.status}</label>}
                    {child.status === "NEW" &&
                        <label className="label-status-new">{child.status}</label>}
                </td> */}
                <td className="master-list-title-actions-td">
                    <div>
                        <ButtonToolbar style={{ padding: 1 }}>
                            <Button color="green" appearance="ghost" size="xs"
                                onClick={(e) => deposit(e, child)} >
                                D
                            </Button>
                            <Button color="red" appearance="ghost" size="xs"
                                onClick={(e) => withdraw(e, child)} >
                                W
                            </Button>
                            <Button color="cyan" appearance="ghost" size="xs"
                                onClick={(e) => edit(e, child)} >
                                Edit
                            </Button>
                            <Button color="violet" appearance="ghost" size="xs"
                                onClick={(e) => statement(e, child)} >
                                Stmt.
                            </Button>
                            <Button color="orange" appearance="ghost" size="xs"
                                onClick={(e) => changeUserPassword(e, child)} >
                                Ch. Pwd.
                            </Button>
                        </ButtonToolbar>
                    </div>
                </td>
            </tr>
        )
    };

    return (
        <div className="manage-master-downline-root">
            <div className="table-top-container">
                <div>
                    {open && <div className="add-master-container" style={{ paddingRight: isSmallScreen ? "65px" : "135px" }}>
                        <div>
                            <button
                                className="board-buttons board-buttons-nav-bar-dark-smaller-thin"
                                onClick={(e) => openRegister(e)} >Add {addType}</button>
                        </div>
                    </div>}
                    {!open && <div className="add-master-container" style={{ paddingRight: isSmallScreen ? "0px" : "15px" }}>
                        <div>
                            <button
                                className="board-buttons board-buttons-nav-bar-dark-smaller-thin"
                                onClick={(e) => openRegister(e)} >Add {addType}</button>
                        </div>
                    </div>}
                </div>
                <Root sx={{ width: "100%", maxWidth: '100%', minWidth: "800px" }}>
                    <table aria-label="custom pagination table" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                {myDownline.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {childrenByType && (rowsPerPage > 0
                                ? childrenByType.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : childrenByType
                            ).map((child, index) =>
                                <Row
                                    child={child}
                                    key={index}
                                    index={index} />)}
                            {emptyRows > 0 && (
                                <tr style={{ height: 34 * emptyRows }}>
                                    <td colSpan={3} aria-hidden />
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <CustomTablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={childrenByType.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    slotProps={{
                                        select: {
                                            'aria-label': 'rows page',
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                            slots: {
                                                firstPageIcon: FirstPageRoundedIcon,
                                                lastPageIcon: LastPageRoundedIcon,
                                                nextPageIcon: ChevronRightRoundedIcon,
                                                backPageIcon: ChevronLeftRoundedIcon,
                                            },
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </tr>
                        </tfoot>
                    </table>
                </Root>
            </div>
        </div>
    );
};

const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Root = styled('div')(
    ({ theme }) => `
  border-radius: 12px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  overflow: clip;

  table {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.675rem;
    border-collapse: collapse;
    border: none;
    width: 500px;
    margin: -1px;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 8px;
  }

  `,
);

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background-color: transparent;
      }
    }
  }
  `,
);
