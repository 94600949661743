import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import TableHeader from "./TableHeader";
import './css/AdminSettings.css';
import {
    AGENT_BANNER_MESSAGE,
    AGENT_NOTIFICATION_MESSAGE,
    USER_BANNER_MESSAGE,
    USER_NOTIFICATION_MESSAGE
} from "./common/Constants";
import GlobalPropertyPopup from "./GlobalPropertyPopup";
import { useState } from "react";
import Modal from './Modal';
import TableTitle from "./customized/TableTitle";

export default function AdminSettings({ role, logout, isSmallScreen }) {
    console.log("show", role);
    const [isModalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [propertyKey, setPropertyKey] = useState(null);
    const [actionHeader, setActionHeader] = useState(null);
    const [actionTitle, setActionTitle] = useState(null);

    const closePropertyUpdatePopup = (e) => {
        e.preventDefault();
        setModalOpen(false);
    };

    const onPropertyUpdateSuccessful = (msg) => {
        setMessage(msg);
        setModalOpen(false);
    };

    const showUpdatePropertyPoppup = (e, key, header, title) => {
        e.preventDefault();
        setPropertyKey(key);
        setActionHeader(header);
        setActionTitle(title);
        setMessage(null);
        setModalOpen(true);
    };

    return (
        <div style={{ background: "white", paddingBottom: "50px" }}>
            {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)} isSmallScreen={isSmallScreen}>
                    <GlobalPropertyPopup role={role} logout={logout} propertyKey={propertyKey} closePropertyUpdatePopup={closePropertyUpdatePopup} onPropertyUpdateSuccessful={onPropertyUpdateSuccessful} actionHeader={actionHeader} actionTitle={actionTitle} />
                </Modal>
            )}
            <div>
                {/* <TableHeader title={"Admin Settings"} /> */}
                <TableTitle
                    text="Admin Settings"
                    color="#ffffff"
                    fontSize="24px"
                    textAlign="left"
                    width="99.2%"
                    height="60px"
                    paddingLeft="12px"
                />
            </div>
            <div style={{ paddingTop: "50px" }}>
                {message && (<div className="form-group" style={{ marginTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
                    <div className="alert alert-success" role="alert">
                        {message}
                    </div>
                </div>)}
                <div className="settings-button-grid-container">
                    <div className="settings-button-row-container">
                        <button className="cric-board-buttons cric-board-admin-settings-buttons-large"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => showUpdatePropertyPoppup(e, AGENT_BANNER_MESSAGE, "Agent Banner Message", "Banner Message")}
                        >
                            Agent Banner Message
                        </button>
                        <button className="cric-board-buttons cric-board-admin-settings-buttons-large"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => showUpdatePropertyPoppup(e, AGENT_NOTIFICATION_MESSAGE, "Agent Notification Message", "Notification Message")}
                        >
                            Agent Notification Message
                        </button>
                    </div>
                </div>
                <div className="settings-button-grid-container">
                    <div className="settings-button-row-container">
                        <button className="cric-board-buttons cric-board-admin-settings-buttons-large"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => showUpdatePropertyPoppup(e, USER_BANNER_MESSAGE, "User Banner Message", "Banner Message")}
                        >
                            User Banner Message
                        </button>
                        <button className="cric-board-buttons cric-board-admin-settings-buttons-large"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => showUpdatePropertyPoppup(e, USER_NOTIFICATION_MESSAGE, "User Notification Message", "Notification Message")}
                        >
                            User Notification Message
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
