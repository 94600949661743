import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { updateGlobalProperty } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import { SET_MESSAGE } from "../../actions/types";
import {
    AGENT_BANNER_MESSAGE,
    AGENT_NOTIFICATION_MESSAGE,
    USER_BANNER_MESSAGE,
    USER_NOTIFICATION_MESSAGE
} from "./common/Constants.js";
import { httpHelpers } from "../../services/httpHelpers";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

const GlobalPropertyPopup = ({ role, logout, propertyKey, closePropertyUpdatePopup, onPropertyUpdateSuccessful, actionHeader, actionTitle }) => {
    let registerBy = role;
    const user = TokenService.getUser();

    if (user === null) {
        logout();
    }
    const [propertyValue, setPropertyValue] = useState();
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(null);
    const form = useRef();
    const checkBtn = useRef();

    let getGlobalProperty = "alpha/getGlobalProperty/" + propertyKey;
    const api = httpHelpers();

    const fetchGlobalProperty = () => {
        api
            .get(`${getGlobalProperty}`)
            .then(res => {
                console.log("get global property res", res);
                if (res && res.data) {
                    setPropertyValue(res.data);
                    if (res.data === 'undefined')
                        setPropertyValue("");
                } else {
                    setPropertyValue("");
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(clearMessage());
        fetchGlobalProperty();
    }, [])

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const onPropertyValueChange = (e) => {
        const gp = e.target.value;
        setPropertyValue(gp);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (propertyValue.length === 0) {
            setShowError("Please Enter Message.");
            return;
        }
        setShowError(null);
        setSuccessful(false);
        form.current.validateAll();
        dispatch(clearMessage());

        if (checkBtn.current.context._errors.length === 0) {
            console.log("global property", role, propertyKey);
            dispatch(updateGlobalProperty(propertyKey, propertyValue))
                .then((data) => {
                    console.log("global property data: ", data);
                    if (data.status === 401) {
                        if (data.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (data.status === 200) {
                        let msg = "Agent Banner Updated Successfully!";
                        if (propertyKey === AGENT_NOTIFICATION_MESSAGE)
                            msg = "Agent Notification Updated Successfully!";
                        else if (propertyKey === USER_BANNER_MESSAGE)
                            msg = "User Banner Updated Successfully!";
                        else if (propertyKey === USER_NOTIFICATION_MESSAGE)
                            msg = "User Notification Updated Successfully!";
                        onPropertyUpdateSuccessful(msg);
                        setSuccessful(true);
                    } else {
                        setSuccessful(false);
                        dispatch({
                            type: SET_MESSAGE,
                            payload: data.data,
                            role: role
                        });
                    }
                })
                .catch(() => {
                });
        }
    };

    return (
        <div className="admin-global-porperty-popup-container">
            <div className="admin-global-porperty-popup-header">
                <label>{actionHeader}</label>
            </div>
            <div className="admin-global-porperty-popup-body">
                <div>
                    {showError && !successful && (<div className="form-group" style={{ marginTop: "10px" }}>
                        <div className="alert alert-danger" role="alert">
                            {showError}
                        </div>
                    </div>)}
                    {(message || successful) && (<div className="form-group" style={{ marginTop: "10px" }}>
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {message ? message : (propertyKey + " Successfully!")}
                        </div>
                    </div>)}
                    <Form onSubmit={handleSubmit} ref={form} style={{ padding: "10px" }}>
                        {!successful && (
                            <div>
                                <div className="form-group">
                                    <div className="cric-form-label-textarea-container">
                                        <div className="cric-div-gp-label-form">
                                            <label className="label-form" htmlFor="pvalue">{actionTitle}</label>
                                        </div>
                                        <div className="cric-div-textarea-form">
                                            <textarea
                                                className="global-property-text-area"
                                                placeholder={"Enter Message"}
                                                id="gp_value"
                                                name="pvalue"
                                                rows="6"
                                                cols="50"
                                                value={propertyValue || ''}
                                                onChange={onPropertyValueChange}
                                                validations={!successful}
                                                style={{ fontSize: "12px" }}
                                                maxLength={(propertyKey === AGENT_BANNER_MESSAGE || propertyKey === USER_BANNER_MESSAGE) ? 200 : 500}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginTop: "30px", textAlign: "center" }}>
                                    <button className="board-buttons board-buttons-nav-bar-dark-small">Submit</button>
                                    <button className="board-buttons board-buttons-nav-bar-dark-small" style={{ background: "#E34234" }} onClick={(e) => closePropertyUpdatePopup(e)}>Cancel</button>
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
            {/* <div className="agent-notification-popup-separator"></div> */}
            {/* <div className="agent-notification-popup-close-button">
                <button
                    className="board-buttons board-buttons-nav-bar-dark-small-agent-notification-close"
                    onClick={(e) => closeAgentNotificationPopup(e)} >Close</button>
            </div> */}
        </div>
        // <div className="cric-agent-actions-top-container">
        //     <div>
        //         <div className="cric-card" style={{ paddingBottom: "10px" }}>
        //             {!successful && <div className="cric-deposit-withdraw-header">
        //                 <label>{actionHeader}</label>
        //             </div>}
        //             <div>
        //                 {(message || successful) && (<div className="form-group" style={{ marginTop: "10px" }}>
        //                     <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
        //                         {message ? message : (propertyKey + " Successfully!")}
        //                     </div>
        //                 </div>)}
        //                 <Form onSubmit={handleSubmit} ref={form} style={{ padding: "10px" }}>
        //                     {!successful && (
        //                         <div>
        //                             <div className="form-group">
        //                                 <div className="cric-form-label-textarea-container">
        //                                     <div className="cric-div-gp-label-form">
        //                                         <label className="label-form" htmlFor="pvalue">{actionTitle}</label>
        //                                     </div>
        //                                     <div className="cric-div-textarea-form">
        //                                         <textarea
        //                                             className="global-property-text-area"
        //                                             placeholder={"Enter Message"}
        //                                             id="gp_value"
        //                                             name="pvalue"
        //                                             rows="6"
        //                                             cols="50"
        //                                             value={propertyValue}
        //                                             onChange={onPropertyValueChange}
        //                                             validations={!successful}
        //                                             style={{ fontSize: "12px" }}
        //                                             maxLength={(propertyKey === AGENT_BANNER_MESSAGE || propertyKey === USER_BANNER_MESSAGE) ? 50 : 100}
        //                                         >
        //                                         </textarea>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div className="form-group" style={{ marginTop: "30px" }}>
        //                                 <button className="board-buttons board-buttons-nav-bar-dark-small">Submit</button>
        //                                 <button className="board-buttons board-buttons-nav-bar-dark-small" style={{ background: "#E34234" }} onClick={(e) => closePropertyUpdatePopup(e)}>Cancel</button>
        //                             </div>
        //                         </div>
        //                     )}
        //                     <CheckButton style={{ display: "none" }} ref={checkBtn} />
        //                 </Form>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default GlobalPropertyPopup;