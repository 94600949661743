// import React, { useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import Alert from "@material-ui/lab/Alert";
import logo from '../assets/icons8-done.gif';

import { httpHelpers } from "../services/httpHelpers";


// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import instance from "../services/api";
// import axios from "axios";
import { useEffect, useState } from "react";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         LuckYo In
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({


  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RegistrationSuccess() {
  const classes = useStyles();
  const [downloadURL, setDownloadUrl] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [pokemon, setPokemon] = useState();

  const [url, setURL] = useState("beta/requestCount");
  const api = httpHelpers();
  const myStyle = {
   
    fontSize:"50px"
  };

  useEffect(() => {
    api
      .get(`gamma/lucky9/getLatestVersionUrlPlay247Ui`)
      .then(res => {
        console.log("regist success: ", res);
        setDownloadUrl(res.data);
        setLoading(false);
      })
      .catch(err => {
        // console.log(err.response)
        if (err && err.response) {
          
        }
      })
  }, []);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    
    
    <Container component="main" maxWidth="lg">
      <img src={logo} alt="loading..." />
      
      <br></br>

     User has been successfully registered
     <br></br>
     <Link style={myStyle} class ="blink" href= {downloadURL} >
      
       CLick here to Download Latest Apk
      </Link>
     
    </Container>
  );
}
