import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

export default function CricketScoreBoard({ logout, matchId, matchDetails }) {

    console.log("matchDetails", matchDetails);
    const matchScore = JSON.parse(matchDetails).data;
    console.log("matchDetails", matchScore);
    const [responseStatus, setResponseStatus] = useState(JSON.parse(matchDetails).status);
    console.log("matchDetails", responseStatus);
    const [responseMessage, setResponseMessage] = useState(JSON.parse(matchDetails).msg);
    const [teamA, setTeamA] = useState(responseMessage ? matchScore.team_a : "");
    const [teamB, setTeamB] = useState(responseMessage ? matchScore.team_b : "");
    const [teamAFlag, setTeamAFlag] = useState(responseMessage ? matchScore.team_a_img : "");
    const [teamBFlag, setTeamBFlag] = useState(responseMessage ? matchScore.team_b_img : "");
    const [teamAShort, setTeamAShort] = useState(responseMessage ? matchScore.team_a_short : "");
    const [teamBShort, setTeamBShort] = useState(responseMessage ? matchScore.team_b_short : "");
    const [teamAScore, setTeamAScore] = useState(responseMessage ? matchScore.team_a_scores : "");
    const [teamBScore, setTeamBScore] = useState(responseMessage ? matchScore.team_b_scores : "");
    const [teamAOver, setTeamAOver] = useState(responseMessage ? matchScore.team_a_over : "");
    const [teamBOver, setTeamBOver] = useState(responseMessage ? matchScore.team_b_over : "");
    // const [teamARun, setTeamARun] = useState(matchScore.team_a_score.score);
    // const [teamBRun, setTeamBRun] = useState(matchScore.team_b_score.score);
    // const [teamAWicket, setTeamAWicket] = useState(matchScore.team_a_score.wicket);
    // const [teamBWicket, setTeamBWicket] = useState(matchScore.team_b_score.wicket);
    // const [teamAOver, setTeamAOver] = useState(matchScore.team_a_score.over);
    // const [teamBOver, setTeamBOver] = useState(matchScore.team_b_score.over);
    const [currentInnings, setCurrentInnings] = useState(responseMessage ? matchScore.current_inning : "");
    const [toss, setToss] = useState(responseMessage ? matchScore.toss : "");
    const [result, setResult] = useState(responseMessage ? matchScore.result : "");
    const [matchType, setMatchType] = useState(responseMessage ? matchScore.match_type : "");
    const [batters, setBatters] = useState(responseMessage ? matchScore.batsman : "");
    const [bowlers, setBowlers] = useState(responseMessage ? matchScore.bolwer : "");
    const [lastFourOvers, setLastFourOvers] = useState(responseMessage ? matchScore.last4overs : "");
    const [needRunBall, setNeedRunBall] = useState(responseMessage ? matchScore.need_run_ball : "");
    const [target, setTarget] = useState(responseMessage ? matchScore.target : "");
    const [reqRunRate, setReqRunRate] = useState(responseMessage ? matchScore.rr_rate : "");
    const [runNeed, setRunNeed] = useState(responseMessage ? matchScore.need_run : "");
    const [ballRemaining, setBallRemaining] = useState(responseMessage ? matchScore.ball_rem : "");

    const Batter = ({ row }) => {
        return (
            <tr>
                <td className="cricket-batters-bowlers-td">{row.name}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.run}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.ball}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.fours}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.sixes}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.strike_rate}</td>
            </tr>
        )
    };

    const Bowler = ({ row }) => {
        return (
            <tr>
                <td className="cricket-batters-bowlers-td">{row.name}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.over}</td>
                <td className="cricket-batters-bowlers-stats-td">-</td>
                <td className="cricket-batters-bowlers-stats-td">{row.run}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.wicket}</td>
                <td className="cricket-batters-bowlers-stats-td">{row.economy}</td>
            </tr>
        )
    };

    return (
        <div>
            {matchScore && responseMessage && responseMessage === "Data found." && <div className="cric-cricket-scoreboard-root">
                <div>
                    <div className="live-match-title-container">
                        <Typography
                            variant="h7"
                            component="div"
                            sx={{ fontFamily: "Brush Script", paddingTop: "0px" }}
                        >
                            {teamA} <b>Vs</b> {teamB}
                        </Typography>
                    </div>
                    <div className="cricket-scoreboard-top-container">
                        <div className="cricket-scoreboard-team-score-container">
                            <div className="team-score-container-left">
                                <div className="team-left-flag-name">
                                    <div className="team-left-flag">
                                        <img className="cricket-team-flag" src={teamAFlag}></img>
                                    </div>
                                    <div className="team-left-flag">
                                        {teamAShort}
                                    </div>
                                </div>
                                <div className="team-left-scores">
                                    <div className="team-left-first-inning-score">
                                        {teamAScore}
                                    </div>
                                    <div className="team-left-sec-inning-score">
                                    </div>
                                    <div className="team-left-overs">
                                        <label className="cricket-scoreboard-over">{teamAOver ? "(" + teamAOver + ")" : ""}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="current-inning">
                                {currentInnings} innings
                            </div>
                            <div className="team-score-container-right">
                                <div className="team-right-scores">
                                    <div className="team-right-first-inning-score">
                                        <label key={teamBScore}>{teamBScore}</label>
                                    </div>
                                    <div className="team-right-sec-inning-score">
                                    </div>
                                    <div className="team-right-overs">
                                        <label className="cricket-scoreboard-over">{teamBOver ? "(" + teamBOver + ")" : ""}</label>
                                    </div>
                                </div>
                                <div className="team-right-flag-name">
                                    <div className="team-right-flag">
                                        <img className="cricket-team-flag" src={teamBFlag}></img>
                                    </div>
                                    <div className="team-right-flag">
                                        {teamBShort}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cricket-scoreboard-team-score-msg-container">
                            {reqRunRate && (matchType === "ODI" || matchType === "T20") && <label className="cricket-last-four-overs">RR: {reqRunRate}</label>}
                        </div>
                        <div className="cricket-scoreboard-team-score-msg-container">
                            {(matchType === "ODI" || matchType === "T20") && currentInnings === 1 ? toss : (result ? result : needRunBall)}
                            {/* {matchType === "Test" && currentInnings === 1 ? toss : needRunBall} */}
                        </div>
                        <div className="cricket-scoreboard-last-balls-container">
                            <label className="cricket-last-four-overs-container">Last Four Overs:</label>
                            <label className="cricket-last-four-overs">{lastFourOvers &&
                                lastFourOvers.map((row, index) =>
                                    " | " +
                                    row.balls.map((ball) =>
                                        " " + ball))}
                            </label>
                        </div>
                        <div className="cricket-scoreboard-team-batters-container">
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <thead>
                                    <tr>
                                        <th className="cricket-batters-bowlers-th">Batters</th>
                                        <th className="cricket-batters-bowlers-stats-th">R</th>
                                        <th className="cricket-batters-bowlers-stats-th">B</th>
                                        <th className="cricket-batters-bowlers-stats-th">4s</th>
                                        <th className="cricket-batters-bowlers-stats-th">6s</th>
                                        <th className="cricket-batters-bowlers-stats-th">SR</th>
                                    </tr>
                                </thead>
                            </table>
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <tbody>
                                    {batters &&
                                        batters.map((row, index) =>
                                            <Batter
                                                row={row}
                                                key={index}
                                                index={index} />)}
                                </tbody>
                            </table>
                        </div>
                        <div className="cricket-scoreboard-team-bowlers-container">
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <thead>
                                    <tr>
                                        <th className="cricket-batters-bowlers-th">Bowlers</th>
                                        <th className="cricket-batters-bowlers-stats-th">O</th>
                                        <th className="cricket-batters-bowlers-stats-th">M</th>
                                        <th className="cricket-batters-bowlers-stats-th">R</th>
                                        <th className="cricket-batters-bowlers-stats-th">W</th>
                                        <th className="cricket-batters-bowlers-stats-th">ECO</th>
                                    </tr>
                                </thead>
                            </table>
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <tbody>
                                    {bowlers && <Bowler row={bowlers} />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}