import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout, editAgent, editUser } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const Edit = ({ role, logout }) => {

    const { state } = useLocation();
    const { child } = state ? state : "";
    console.log("edit", child);

    const form = useRef();
    const checkBtn = useRef();

    const [name, setName] = useState(child ? child.name : "");
    const [userName, setUserName] = useState(child ? child.userName : "");
    // const [password, setPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");
    const [yourPassword, setYourPassword] = useState("");
    const [share, setShare] = useState(child ? child.share : "");
    const [openingBalance, setOpeningBalance] = useState(child ? child.openingBalance : "");
    const [creditReference, setCreditReference] = useState(child ? child.creditReference : "");
    const [exposureLimit, setExposureLimit] = useState(child ? child.exposureLimit : "");
    const [status, setStatus] = useState(child ? child.status : "");;
    const [successful, setSuccessful] = useState(false);
    const [newCreatedUserName, setNewCreatedUserName] = useState(null);
    const [showError, setShowError] = useState(true);

    const [agentBlocked, setAgentBlocked] = useState(status === "BLOCKED" ? true : false);
    const [betsBlocked, setBetsBlocked] = useState(status === "BETSBLOCKED" ? true : false);

    const borderRadiusStyle = { borderRadius: 2 }

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);
    };

    // const onChangePassword = (e) => {
    //     const password = e.target.value;
    //     setPassword(password);
    // };

    // const onChangeConfirmPassword = (e) => {
    //     const confirmPassword = e.target.value;
    //     setPassword(confirmPassword);
    // };

    const onChangeYourPassword = (e) => {
        const yp = e.target.value;
        setYourPassword(yp);
    };

    const onChangeShare = (e) => {
        const share = e.target.value;
        setShare(share);
    };

    const onChangeOpeningBalance = (e) => {
        const ob = e.target.value;
        setOpeningBalance(ob);
    };

    const onChangeCreditReference = (e) => {
        const cr = e.target.value;
        setCreditReference(cr);
    };

    const onChangeExposureLimit = (e) => {
        const el = e.target.value;
        setExposureLimit(el);
    };

    // const [input, setInput] = useState({
    //     password: '',
    //     confirmPassword: ''
    // });

    // const [error, setError] = useState({
    //     password: '',
    //     confirmPassword: ''
    // });

    // const onInputChange = e => {
    //     const { name, value } = e.target;
    //     setInput(prev => ({
    //         ...prev,
    //         [name]: value
    //     }));
    //     validateInput(e);
    // }

    // const validateInput = e => {
    //     let { name, value } = e.target;
    //     setError(prev => {
    //         const stateObj = { ...prev, [name]: "" };
    //         // console.log("Password", name, value);
    //         switch (name) {
    //             case "password":
    //                 if (input.confirmPassword && value !== input.confirmPassword) {
    //                     stateObj[name] = "Password and Confirm Password does not match.";
    //                 } else if (input.confirmPassword && value === input.confirmPassword) {
    //                     stateObj[name] = "";
    //                 } else {
    //                     stateObj[name] = input.confirmPassword ? "" : error.confirmPassword;
    //                 }
    //                 setPassword(value)
    //                 break;

    //             case "confirmPassword":
    //                 if (input.password && value !== input.password) {
    //                     stateObj[name] = "Password and Confirm Password does not match.";
    //                 } else if (input.password && value === input.password) {
    //                     stateObj[name] = "";
    //                 } else {
    //                     stateObj[name] = input.password ? "" : error.password;
    //                 }
    //                 setConfirmPassword(value)
    //                 break;
    //             default:
    //                 break;
    //         }
    //         return stateObj;
    //     });
    // }

    const handleEdit = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log("register user", role);
            let blockedStatus = "ACTIVE";
            if (agentBlocked) {
                blockedStatus = "BLOCKED";
            } else if (betsBlocked) {
                blockedStatus = "BETSBLOCKED";
            }

            if (child.userType === "user") {
                dispatch(editUser(userName, name, blockedStatus, yourPassword, exposureLimit))
                    .then((data) => {
                        console.log("edit agent data: ", data);
                        if (data.status === 401) {
                            if (data.data === "Wrong password") {
                                setSuccessful(false);
                            } else {
                                logout();
                            }
                        } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                            setSuccessful(false);
                        } else if (data.status === 200) {
                            setSuccessful(true);
                        }
                    })
                    .catch(() => {
                    });
            } else {
                dispatch(editAgent(userName, name, share, blockedStatus, yourPassword, creditReference))
                    .then((data) => {
                        console.log("edit agent data: ", data);
                        if (data.status === 401) {
                            if (data.data === "Wrong password") {
                                setSuccessful(false);
                            } else {
                                logout();
                            }
                        } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                            setSuccessful(false);
                        } else if (data.status === 200) {
                            setSuccessful(true);
                        }
                    })
                    .catch(() => {
                    });
            }
        }
    };

    return (
        <div className="register-top-container">
            <div className="register-container">
                <div className="card" style={{ marginTop: 90 }}>
                    {!successful && <div>
                        <label className="register-header">Update</label>
                    </div>}
                    <div className="card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
                        {newCreatedUserName && <label style={{ fontSize: "30px", fontWeight: "bold" }}>{newCreatedUserName}</label>}

                        <Form onSubmit={handleEdit} ref={form}>
                            {!successful && (
                                <div>
                                    {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="name">Name</label>
                                        </div>
                                        <Input
                                            id="reg_name"
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            maxLength="20"
                                            value={name}
                                            onChange={onChangeName}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="userName">User Name</label>
                                        </div>
                                        <Input
                                            id="reg_username"
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            value={userName}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    {child && child.userType !== "user" && <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="share">Share (%)</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_share"
                                            className="form-control"
                                            name="share"
                                            value={share}
                                            onChange={onChangeShare}
                                            validations={!successful && [required]}
                                        />
                                        {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>Fix Limit can be set from 0 to</label>
                  </div>
                  <div className="div-note-2">
                    <label>1059254.00</label>
                  </div>
                </div> */}
                                    </div>}
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="openingbalance">Opening Balance</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_openingbalance"
                                            className="form-control"
                                            name="openingbalance"
                                            value={openingBalance}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                        {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>My Match Share can be set from 0 to</label>
                  </div>
                  <div className="div-note-2">
                    <label>95</label>
                  </div>
                </div> */}
                                    </div>
                                    {child && child.userType !== "user" && <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="creditreference">Credit Reference</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_creditreference"
                                            className="form-control"
                                            name="creditreference"
                                            value={creditReference}
                                            onChange={onChangeCreditReference}
                                            validations={!successful && [required]}
                                        />
                                        {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>
                      {regType} Match Share can be set to
                    </label>
                  </div>
                  <div className="div-note-2">
                    <label>95</label>
                  </div>
                </div> */}
                                    </div>}
                                    {child && child.userType === "user" && <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="exposurelimit">Exposure Limit</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_exposurelimit"
                                            className="form-control"
                                            name="exposurelimit"
                                            value={exposureLimit}
                                            onChange={onChangeExposureLimit}
                                            validations={!successful && [required]}
                                        />
                                        {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>
                      {regType} Match Commission can be set from 0 to
                    </label>
                  </div>
                  <div className="div-note-2">
                    <label>3</label>
                  </div>
                </div> */}
                                    </div>}
                                    {/* <div className="form-group">
                <div className="div-label-form">
                  <label className="label-form" htmlFor="sessioncommission">
                    {regType} Session Commission
                  </label>
                </div>
                <Input
                  type="tel"
                  id="reg_sessioncommission"
                  className="form-control"
                  name="sessioncommision"
                  value={sessionCommission}
                  onChange={onChangeSessionCommission}
                  validations={!successful && [required]}
                />
                <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>
                      {regType} Session Commission can be set from 0 to
                    </label>
                  </div>
                  <div className="div-note-2">
                    <label>3</label>
                  </div>
                </div>
              </div> */}
                                    {/* <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="password">Password</label>
                                        </div>
                                        <Input
                                            id="reg_password"
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={password}
                                            onChange={onInputChange}
                                            onBlur={validateInput}
                                            validations={!successful && [required, vpassword]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="confirmPassword">Confirm Password</label>
                                        </div>
                                        <Input
                                            id="reg_confirmpassword"
                                            type="password"
                                            className="form-control"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={onInputChange}
                                            onBlur={validateInput}
                                            validations={!successful && [required, vpassword]}
                                        />
                                    </div> */}
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="agentblocked">
                                                Agent Blocked
                                            </label>
                                        </div>
                                        <div className="toggle-button-container">
                                            <ToggleButton
                                                value={agentBlocked}
                                                inactiveLabel={"Off"}
                                                activeLabel={"On"}
                                                thumbStyle={borderRadiusStyle}
                                                trackStyle={borderRadiusStyle}
                                                onToggle={value => {
                                                    setAgentBlocked(!value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="betsblocked">
                                                Bets Blocked
                                            </label>
                                        </div>
                                        <div className="toggle-button-container">
                                            <ToggleButton
                                                value={betsBlocked}
                                                inactiveLabel={"Off"}
                                                activeLabel={"On"}
                                                thumbStyle={borderRadiusStyle}
                                                trackStyle={borderRadiusStyle}
                                                onToggle={value => {
                                                    setBetsBlocked(!value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="hr-solid"></div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="yourpassword">Your Password</label>
                                        </div>
                                        <Input
                                            id="reg_yourpassword"
                                            type="password"
                                            className="form-control"
                                            name="yourpassword"
                                            value={yourPassword}
                                            onChange={onChangeYourPassword}
                                            validations={!successful && [required, vpassword]}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-small">Update</button>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>

                    {(message || successful) && (<div className="form-group">
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {message ? message : ("User Name " + userName + " is Updated Successfully!")}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default Edit;