import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { depositAgent, makeTransaction, withdrawAgent } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import { SET_MESSAGE } from "../../actions/types";
import { DEPOSIT_COINS, WITHDRAW_COINS } from "./common/Constants.js";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

const AgentNotificationPopup = ({ role, logout, notificationMessage, closeAgentNotificationPopup }) => {
    let registerBy = role;
    const user = TokenService.getUser();

    if (user === null) {
        logout();
    }

    return (
        <div className="cric-agent-actions-notification-popup-container">
            <div className="agent-notification-popup-header">
                Cric247 Important Notice
            </div>
            <div className="agent-notification-popup-body">
                <label>{notificationMessage}</label>
            </div>
            <div className="agent-notification-popup-separator"></div>
            <div className="agent-notification-popup-close-button">
                <button
                    className="board-buttons board-buttons-nav-bar-dark-small-agent-notification-close"
                    onClick={(e) => closeAgentNotificationPopup(e)} >Close</button>
            </div>
            {/* <div>
                <div className="cric-card" style={{ paddingBottom: "10px" }}>
                    <div className="cric-alert-popup-header">
                        <label>Agent Notification</label>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ width: "100%", textAlign: "left", marginLeft: "15px", marginRight: "15px", marginBottom: "15px", marginTop: "5px", fontSize: "14px" }}>
                            <label>{notificationMessage}</label>
                        </div>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <button
                                className="board-buttons board-buttons-nav-bar-dark-smaller-thin-add-agent"
                                onClick={(e) => closeAgentNotificationPopup(e)} >Ok</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default AgentNotificationPopup;