import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { useNavigate, useLocation } from 'react-router-dom';
import TransactionArrow from '../../assets/arrow_crop_20.png';
// import DatePickerCalender from './DatePickerCalender';
import DateRangePresetsPicker from "./DateRangePresetsPicker";
import DateRangePicker from "./DateRangePicker";
import TableHeader from "./TableHeader";

export default function Ledgers({ role, logout }) {

    const { state } = useLocation();

    if (state === null) {
        logout();
    }
    const { child } = state ? state : "";
    console.log("statement", child);
    const userName = child ? child.userName : "";

    let getStatement = "beta/getStatement";
    if (userName) {
        getStatement = "beta/getStatement?userName=" + userName;
    }
    const api = httpHelpers();
    const [statement, setStatement] = useState(null);
    let navigate = useNavigate();

    const fetchStatement = (startDate, endDate) => {
        api
            .get(`${getStatement}`)
            .then(res => {
                console.log("statement res", res);
                if (res && res.data && res.data.length > 0) {
                    setStatement(res.data);
                } else {
                    setStatement(null);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        fetchStatement();
    }, []);

    const Row = ({ row }) => {
        return (
            <div>
                <tr>
                    <td className="master-list-title-td-stmt-long">{row.transactionTime}</td>
                    <td className={row.transactionType === "DEPOSIT" ? "master-list-title-td-green" : "master-list-title-td"}>{row.transactionType === "DEPOSIT" ? row.amount : "-"}</td>
                    <td className={row.transactionType === "WITHDRAW" ? "master-list-title-td-red" : "master-list-title-td"}>{row.transactionType === "WITHDRAW" ? row.amount : "-"}</td>
                    <td className="master-list-title-td">{row.balance}</td>
                    <td className="master-list-title-td">{row.remark}</td>
                    {row.transactionType === "WITHDRAW" && <td className="master-list-title-td-stmt-long">
                        {row.toUser}
                        <img src={TransactionArrow} style={{ paddingLeft: "2px", paddingRight: "2px" }}></img>
                        {row.fromUser}
                    </td>}
                    {row.transactionType === "DEPOSIT" && <td className="master-list-title-td-stmt-long">
                        {row.fromUser}
                        <img src={TransactionArrow} style={{ paddingLeft: "2px", paddingRight: "2px" }}></img>
                        {row.toUser}
                    </td>}
                </tr>
            </div>
        )
    };

    return (
        <div>
            <div className="date-range-presets-picker-container">
                {/* <DatePickerCalender fetchStatement={fetchStatement} /> */}
                {/* <DateRangePresetsPicker /> */}
                <DateRangePicker fetchStatement={fetchStatement} />
                <div style={{ marginTop: "35px"}}>
                    <TableHeader title={"Agent Ledger"} />
                </div>
            </div>
            <div className="cric-statement-root">
                <div className="table-top-container">
                    <div className="table-container">
                        <div className="table-content-container">
                            <div className="tbl-header">
                                <table cellpadding="0" cellspacing="0" border="0">
                                    <thead>
                                        <tr>
                                            <th className="master-list-title-th-stmt-long">Date/Time</th>
                                            <th className="master-list-title-th">Entry</th>
                                            <th className="master-list-title-th">Debit</th>
                                            <th className="master-list-title-th">Credit</th>
                                            <th className="master-list-title-th">Balance</th>
                                            <th className="master-list-title-th-stmt-long">Note</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="tbl-content">
                                <table cellpadding="0" cellspacing="0" border="0">
                                    <tbody>
                                        {statement &&
                                            statement.map((row, index) =>
                                                <Row
                                                    row={row}
                                                    key={index}
                                                    index={index} />)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
