import React, { useState, useEffect } from 'react';
import '../css/MarketTable.css';
import CustomizedCheckBox from '../customized/CustomizedCheckBox';
import { MARKET_STATUS_BLOCKED, MARKET_STATUS_UNBLOCKED, MARKET_STATUS_BLOCKEDUP } from '../common/Constants';
import { LIVE_CASINO, VIRTUAL_CASINO, LIVE_CASINO_STRING, VIRTUAL_CASINO_STRING } from '../common/Constants';
import { Link } from 'react-router-dom';
import TableTitle from '../customized/TableTitle';

const MarketTable = ({ columns, data, tableHeader, onCheckBoxClick, hideTitle, userId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(5);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const totalPages = Math.ceil(data && data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data && data.slice(startIndex, startIndex + itemsPerPage);

  // Generate pagination buttons
  const pageNumbers = [];
  const visiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  if (endPage - startPage + 1 < visiblePages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={hideTitle ? "market-table-container-popup" : "market-table-container"}>
      {hideTitle && <TableTitle
        text={"Sport List for " + userId}
        color="#ffffff"
        fontSize="14px"
        textAlign="center"
        width="100%"
        height="30px"
        marginLeft="0px"
        marginRight="0px"
      />}
      <table className={hideTitle ? "market-table-popup" : "market-table"}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} style={{ textAlign: column.includes("Action") ? "center" : "left" }} >{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData && currentData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                {rowIndex + 1}
              </td>
              <td>
                <Link className="td-market-sport-link" style={{ textDecoration: "none" }}>
                  {row.sportType}
                </Link>
              </td>
              {row.status === MARKET_STATUS_UNBLOCKED && <td>
                <div className="td-market-unblocked">
                  {row.sportType !== LIVE_CASINO && row.sportType !== VIRTUAL_CASINO && row.sportType[0].toUpperCase() + row.sportType.slice(1) + " is ON"}
                  {row.sportType === LIVE_CASINO && LIVE_CASINO_STRING + " is ON"}
                  {row.sportType === VIRTUAL_CASINO && VIRTUAL_CASINO_STRING + " is ON"}
                </div>
              </td>}
              {row.status === MARKET_STATUS_BLOCKED && <td>
                <div className="td-market-blocked">
                  {row.sportType !== LIVE_CASINO && row.sportType !== VIRTUAL_CASINO && row.sportType[0].toUpperCase() + row.sportType.slice(1) + " is OFF"}
                  {row.sportType === LIVE_CASINO && LIVE_CASINO_STRING + " is OFF"}
                  {row.sportType === VIRTUAL_CASINO && VIRTUAL_CASINO_STRING + " is OFF"}
                </div>
              </td>}
              {row.status === MARKET_STATUS_BLOCKEDUP && <td>
                <div className="td-market-blockedup">
                  {row.sportType !== LIVE_CASINO && row.sportType !== VIRTUAL_CASINO && row.sportType[0].toUpperCase() + row.sportType.slice(1) + " is Blocked"}
                  {row.sportType === LIVE_CASINO && LIVE_CASINO_STRING + " is Blocked"}
                  {row.sportType === VIRTUAL_CASINO && VIRTUAL_CASINO_STRING + " is Blocked"}
                </div>
              </td>}
              <td style={{ textAlign: "center" }}>
                <CustomizedCheckBox market={row} onCheckBoxClick={onCheckBoxClick} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="pagination-button"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="pagination-button"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default MarketTable;
