import React from 'react';
import '../css/BetSlip.css';

const BetSlipMarketPositionRunners = ({ headerTitle, runnerPositionOne, runnerPositionTwo }) => {
    return (
        <div className="bet-slip-market-position-runner-container">
            <div className="header">
                {headerTitle}
            </div>
            <div className='market-separator-below-detail'></div>
            <div className="market-position-detail">
                {runnerPositionOne}
            </div>
            <div className='market-separator-below-detail'></div>
            <div className="market-position-detail">
                {runnerPositionTwo}
            </div>
        </div>
    );
}

export default BetSlipMarketPositionRunners;
