import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { logout, updateGameStatus } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../actions/message";
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import {
    MURGA,
    CRICKET,
    FOOTBALL,
    TENNIS
} from './common/constants';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default function MUIPUpcomingMatches({ role, logout }) {
    console.log("show", role);
    const { state } = useLocation();
    const { sportId } = state ? state : "";
    const getUpcomingGames = "/alpha/getUpcomingGames?sportType=" + sportId;
    const api = httpHelpers();
    const [upcomingGames, setUpcomingGames] = useState(null);
    const [disableGameStart, setDisableGameStart] = useState(false);
    let navigate = useNavigate();
    const [successful, setSuccessful] = useState(false);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - upcomingGames.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchUpcomingGames = () => {
        api
            .get(`${getUpcomingGames}`)
            .then(res => {
                console.log("upcoming games", res);
                if (res && res.data) {
                    setUpcomingGames(res.data.upcomingGames);
                    setDisableGameStart(res.data.disableGameStart);
                } else {
                    setUpcomingGames(null);
                }
            })

            .catch(err => {
                console.log(err.response.status);
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    const openRegisterGames = (e) => {
        e.preventDefault();
        navigate('/registergames');
    };

    const start = (e, games) => {
        e.preventDefault();
        navigate('/startgame', { state: { games } });
    };

    const cancel = (e, games) => {
        e.preventDefault();
        handleGameStatus(games.id, "CANCELLED");
    };

    const suspend = (e, games) => {
        e.preventDefault();
        handleGameStatus(games.id, "SUSPENDED");
    };

    useEffect(() => {
        fetchUpcomingGames();
    }, []);

    const handleGameStatus = (id, gameStatus) => {
        setSuccessful(false);
        console.log("game status", role);
        dispatch(updateGameStatus(id, gameStatus))
            .then((data) => {
                console.log("game status data: ", data);
                if (data.status === 401) {
                    if (data.data === "Wrong password") {
                        setSuccessful(false);
                    } else {
                        logout();
                    }
                } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                    setSuccessful(false);
                } else if (data.status === 200) {
                    setSuccessful(true);
                }
            })
            .catch(() => {
            });
    };

    const Row = ({ games }) => {
        return (
            <tr>
                <td className="master-list-title-td-game-title">{games.title}</td>
                <td className="master-list-title-td">{games.date}</td>
                <td className="master-list-title-td">{games.startTime}</td>
                <td className="master-list-title-td">{games.player1}</td>
                <td className="master-list-title-td">{games.player2}</td>
                <td className="master-list-title-td">
                    {<label className="label-status-new">{games.gameStatus}</label>}
                </td>
                {role === "admin" && sportId && sportId.includes(MURGA) && <td className="master-list-title-actions-td-small">
                    <div>
                        <ButtonToolbar style={{ padding: 1 }}>
                            <Button color="green" appearance="ghost" size="xs"
                                onClick={(e) => start(e, games)} disabled={disableGameStart} >
                                Start
                            </Button>
                            <Button color="red" appearance="ghost" size="xs"
                                onClick={(e) => cancel(e, games)} >
                                Cancel
                            </Button>
                            {/* <Button color="cyan" appearance="ghost" size="xs"
                                onClick={(e) => suspend(e, games)} >
                                Suspend
                            </Button> */}
                        </ButtonToolbar>
                    </div>
                </td>}
            </tr>
        )
    };

    return (
        <div className="upcoming-match-list-root">
            <div style={{ background: "white" }}>
                {role && role === "admin" && sportId && sportId.includes(MURGA) && <div className="add-games-container-upcoming">
                    <div>
                        <button
                            className="board-buttons board-buttons-nav-bar-dark-small-thin"
                            onClick={(e) => openRegisterGames(e)} >Add Games</button>
                    </div>
                </div>}
            </div>
            <div className="table-top-container">
                {(message || successful) && (<div>
                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                        {message ? message : ("Game Status Updated Successfully!")}
                    </div>
                </div>)}
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th-game-title">Title</th>
                                        <th className="master-list-title-th">Date</th>
                                        <th className="master-list-title-th">Time</th>
                                        <th className="master-list-title-th">Player 1</th>
                                        <th className="master-list-title-th">Player 2</th>
                                        <th className="master-list-title-th">Status</th>
                                        {role === "admin" && sportId && sportId.includes(MURGA) && <th className="master-list-title-actions-th-small">Actions</th>}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tbl-content">
                            <table>
                                <tbody>
                                    {upcomingGames && (rowsPerPage > 0
                                        ? upcomingGames.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : upcomingGames
                                    ).map((games, index) =>
                                        <Row
                                            games={games}
                                            key={index}
                                            index={index} />)}
                                    {emptyRows > 0 && (
                                        <tr style={{ height: 34 * emptyRows }}>
                                            <td colSpan={3} aria-hidden />
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {upcomingGames && upcomingGames.length > rowsPerPage && <div>
                <div className="table-pagination-container">
                    <div className="table-pagination-container-child">
                        <CustomTablePagination
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            rowsPerPageOptions={10}
                            colSpan={3}
                            count={upcomingGames && upcomingGames.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                                select: {
                                    'aria-label': 'rows per page',
                                },
                                actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

const blue = {
    50: '#48aaad',
    200: '#48aaad',
    400: '#48aaad',
    900: '#48aaad',
};

const grey = {
    50: '#E5E4E2',
    100: '#C0C0C0',
    200: '#E5E4E2',
    300: '#C0C0C0',
    400: '#C0C0C0',
    500: '#C0C0C0',
    600: '#C0C0C0',
    700: '#C0C0C0',
    800: '#C0C0C0',
    900: '#C0C0C0',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #48aaad;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);