import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { updateGameStatus } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const MatchComplete = ({ role, logout }) => {

    const { state } = useLocation();
    const { newGame } = state ? state : "";
    console.log("match complete", newGame);

    const form = useRef();
    const checkBtn = useRef();

    const [gameId, setGameId] = useState(newGame ? newGame.id : "");
    const [gameTitle, setGameTitle] = useState(newGame ? newGame.title : "");
    const [player1, setPlaeyer1] = useState(newGame ? newGame.player1 : "");
    const [player2, setPlaeyer2] = useState(newGame ? newGame.player2 : "");
    const [yourPassword, setYourPassword] = useState("");
    const [winner, setWinner] = useState(player1);
    const [winnerName, setWinnerName] = useState(player1);
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeYourPassword = (e) => {
        const yp = e.target.value;
        setYourPassword(yp);
    };

    const handleWinnerChange = (event) => {
        const player = event.target.value;
        if (player === "PLAYER1") {
            setWinnerName(player1);
        } else {
            setWinnerName(player2);
        }
        setWinner(player);
    };

    const handleMatchComplete = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log("match complete", role);
            dispatch(updateGameStatus(gameId, "COMPLETED", winner))
                .then((data) => {
                    console.log("game status data: ", data);
                    if (data.status === 401) {
                        if (data.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                        setSuccessful(false);
                    } else if (data.status === 200) {
                        setSuccessful(true);
                    }
                })
                .catch(() => {
                });
        }
    };

    return (
        <div className="register-top-container">
            <div className="register-container">
                <div className="card" style={{ marginTop: 90 }}>
                    {!successful && <div>
                        <label className="register-header">Match Complete</label>
                    </div>}
                    <div className="card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}

                        <Form onSubmit={handleMatchComplete} ref={form}>
                            {!successful && (
                                <div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="gameid">Game ID</label>
                                        </div>
                                        <Input
                                            id="reg_gameid"
                                            type="text"
                                            className="form-control"
                                            name="gameid"
                                            value={gameId}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="gametitle">Game Title</label>
                                        </div>
                                        <Input
                                            id="reg_gametitle"
                                            type="text"
                                            className="form-control"
                                            name="gametitle"
                                            value={gameTitle}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="winner">Winner</label>
                                        </div>
                                        <div className="div-match-winner-radio-button">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={winner}
                                                    onChange={handleWinnerChange}
                                                >
                                                    <FormControlLabel value="PLAYER1" control={<Radio />} label={player1} />
                                                    <FormControlLabel value="PLAYER2" control={<Radio />} label={player2} />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="hr-solid"></div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="yourpassword">Your Password</label>
                                        </div>
                                        <Input
                                            id="reg_yourpassword"
                                            type="password"
                                            className="form-control"
                                            name="yourpassword"
                                            value={yourPassword}
                                            onChange={onChangeYourPassword}
                                            validations={!successful && [required, vpassword]}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-small">Complete</button>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>

                    {(message || successful) && (<div className="form-group" style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {message ? message : ("Match " + gameId + " Completed & Winner " + winnerName + " is Set Successfully!")}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default MatchComplete;