import React, { useCallback, useEffect, useState } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export const SidebarData = [
    {
        title:"Dashboard",
        icon: <DashboardIcon />,
        link: "/dashboard"
    },
    {
        title:"Statements",
        icon: <AccountBalanceWalletIcon />,
        link: "/statements"
    },
    {
        title:"Add Agent",
        icon: <PersonAddIcon />,
        link: "/addAgent"
    }
]