import MarketHeader from './MarketHeader';
import CricMarketHeader from '../cric247/CricMarketHeader';
import CricMarketHeaderFancy from '../cric247/CricMarketHeaderFancy';
// import Nations from './Nations';
// import Nation from './Nation';
import NationsPosition from '../cric247/NationsPosition';
import NationPosition from '../cric247/NationPosition';
import './style/MatchOdds.css';
import {
    TIED_MATCH,
    DRAW_MATCH
} from './common/constants';
import AddSession from '../cric247/AddSession';

export default function MatchBetPanel({ role, marketType, oddsList, isFancy, userPosition, minLimit, maxLimit, isSmallScreen }) {

    const BACK = "LAGAI";
    const LAY = "KHAI";
    const YES = "Yes";
    const NO = "No";
    // const TIED_MATCH = "Tied Match";
    // const DRAW_MATCH = "Draw Match";

    let teamA;
    let teamB;
    let theDraw;
    let tiedYes;
    let tiedNo;

    let hasOdds = false;

    if (!isFancy) {
        oddsList.forEach((odds, index) => {
            // console.log("market bet panel", odds.nation);
            if (odds.nation === "Yes" || odds.nation === "YES") {
                if (odds.marketName === "Tied Match" || odds.marketName === "TIED_MATCH") {
                    hasOdds = true;
                    tiedYes = odds;
                }
            } else if (odds.nation === "No" || odds.nation === "NO") {
                if (odds.marketName === "Tied Match" || odds.marketName === "TIED_MATCH") {
                    hasOdds = true;
                    tiedNo = odds;
                }
            } else if (odds.nation !== "No" && odds.nation !== "NO"
                && odds.nation !== "Yes" && odds.nation !== "YES") {
                if (odds.nation === DRAW_MATCH) {
                    hasOdds = true;
                    theDraw = odds;
                } else if (teamA) {
                    hasOdds = true;
                    teamB = odds;
                } else {
                    hasOdds = true;
                    teamA = odds;
                }
            }
        });
    } else {
        oddsList.forEach((odds, index) => {
            hasOdds = true;
        });
    }

    let teams = null;
    let tied = null;

    if (teamA && teamB) {
        teams = [
            {
                name: teamA.nation, backRate: teamA.backRate, backStake: teamA.backStake,
                layRate: teamA.layRate, layStake: teamA.layStake, status: teamA.status,
                marketId: teamA.marketId, userPosition: teamA.userPosition
            },
            {
                name: teamB.nation, backRate: teamB.backRate, backStake: teamB.backStake,
                layRate: teamB.layRate, layStake: teamB.layStake, status: teamB.status,
                marketId: teamB.marketId, userPosition: teamB.userPosition
            }
        ];

        if (theDraw) {
            teams.push({
                name: theDraw.nation, backRate: theDraw.backRate, backStake: theDraw.backStake,
                layRate: theDraw.layRate, layStake: theDraw.layStake, status: theDraw.status,
                marketId: theDraw.marketId, userPosition: theDraw.userPosition
            });
        }
    }

    if (tiedYes && tiedNo) {
        tied = [
            {
                name: tiedYes.nation, backRate: tiedYes.backRate, backStake: tiedYes.backStake,
                layRate: tiedYes.layRate, layStake: tiedYes.layStake, status: tiedYes.status,
                marketId: tiedYes.marketId, userPosition: tiedYes.userPosition
            },
            {
                name: tiedNo.nation, backRate: tiedNo.backRate, backStake: tiedNo.backStake,
                layRate: tiedNo.layRate, layStake: tiedNo.layStake, status: tiedNo.status,
                marketId: tiedNo.marketId, userPosition: tiedNo.userPosition
            }
        ];
    }

    return (
        <div>
            <div className="match-odds-betting-top-container">
                {hasOdds && !isFancy && teams && <div className="match-odds-betting-market-container">
                    {/* <MarketHeader marketHeader={marketType} nationOne={BACK} nationTwo={LAY} />
                <Nations teams={teams} /> */}
                    <CricMarketHeader marketHeader={marketType} nationOne={BACK} nationTwo={LAY} isFancy={isFancy} />
                    <NationsPosition teams={teams} />
                </div>}
                {hasOdds && !isFancy && tied && <div style={{ marginTop: "1px" }} className="match-odds-betting-market-container">
                    {/* <MarketHeader marketHeader={TIED_MATCH} nationOne={BACK} nationTwo={LAY} marketType={marketType} />
                <Nations teams={tied} /> */}
                    <CricMarketHeader marketHeader={TIED_MATCH} nationOne={BACK} nationTwo={LAY} marketType={marketType} isFancy={isFancy} />
                    <NationsPosition teams={tied} />
                </div>}
                {hasOdds && isFancy && oddsList && <div className="match-odds-betting-market-container-fancy">
                    {/* <MarketHeader marketHeader={marketType} nationOne={YES} nationTwo={NO} />
                <Nation teams={oddsList} /> */}
                    {/* <CricMarketHeader marketHeader={marketType} nationOne={YES} nationTwo={NO} isFancy={isFancy} /> */}
                    <CricMarketHeaderFancy marketHeader={marketType} nationOne={NO} nationTwo={YES} isFancy={isFancy} userPosition={userPosition} />
                    <NationPosition teams={oddsList} isSmallScreen={isSmallScreen} />
                </div>}
            </div>
        </div>
    );
}