import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
// import data from "./data";
// import ReactPaginate from "react-paginate";
// import { httpHelpers } from "../../services/httpHelpers";
// import { 
//   ZERO,
//   ONE,
//   TWO,
//   THREE,
//   FOUR,
//   FIVE,
//   SIX,
//   SEVEN,
//   EIGHT,
//   NINE,
//   GREEN,
//   PURPLE,
//   RED
// } from '../LuckyNumber/BetCandidatesTypes';

export default function ImmediateChildrens({ role, logout }) {
    console.log("show", role);
    const { state } = useLocation();
    const { child } = state ? state : "";
    const userName = child ? child.userId : null;
    const getImmediateChildrens = "/beta/getImmediateChildren?username=" + userName;
    const api = httpHelpers();
    const [immediateChildrens, setImmediateChildrens] = useState(null);
    let navigate = useNavigate();

    const fetchImmediateChildrens = () => {
        api
            .get(`${getImmediateChildrens}`)
            .then(res => {
                console.log("imm childrens", res);
                if (res && res.data) {
                    setImmediateChildrens(res.data);
                } else {
                    setImmediateChildrens(null);
                }
            })
            .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    useEffect(() => {
        if (userName) {
            fetchImmediateChildrens();
        } else {
            logout();
        }
    }, []);

    const openHome = (e, child) => {
        e.preventDefault();
        navigate('/dashboard', { state: { child } });
    }

    const statement = (e, child) => {
        e.preventDefault();
        navigate('/statement', { state: { child } });
    };

    const betHistory = (e, child) => {
        e.preventDefault();
        const userId = child.userId;
        navigate('/bethistory', { state: { userId } });
    };

    const handleChildren = (e, child) => {
        e.preventDefault();
        if (child.userType.includes("subcompany")) {
            navigate('/childrenssc', { state: { child } });
        } else if (child.userType.includes("superstockist")) {
            navigate('/childrenssst', { state: { child } });
        } else if (child.userType.includes("stockist")) {
            navigate('/childrensst', { state: { child } });
        } else if (child.userType.includes("agent")) {
            navigate('/childrensagent', { state: { child } });
        }
    }

    const Row = ({ child }) => {
        return (
            <tr>
                {child.userType !== "user" && <td className="master-list-title-td-username">
                    {child.userType === "superstockist" && <label className="label-tag-sst">{"Sup. Sst."}</label>}
                    {child.userType === "stockist" && <label className="label-tag-st">{"Stockist"}</label>}
                    {child.userType === "agent" && <label className="label-tag-agent">{"Agent"}</label>}
                    <Button appearance="link"
                        onClick={(e) => handleChildren(e, child)}>{child.userName}</Button>
                </td>}
                {child.userType === "user" && <td className="master-list-title-td-username">
                    <label className="label-tag-user">{"user"}</label>
                    {child.userName}
                </td>}
                <td className="master-list-title-td-blue">{child.creditReference}</td>
                {child.userType !== "user" && <td className="master-list-title-td-blue">{child.share}</td>}
                {child.userType === "user" && <td className="master-list-title-td-blue">-</td>}
                <td className="master-list-title-td">{child.openingBalance}</td>
                <td className="master-list-title-td">{child.availableBalance}</td>
                {child.exposure < 0 && <td className="master-list-title-td-red">(-{child.exposure})</td>}
                {child.exposure == 0 && <td className="master-list-title-td-red">({child.exposure})</td>}
                {child.exposure > 0 && <td className="master-list-title-td-green">{child.exposure}</td>}
                <td className="master-list-title-td">{child.exposureLimit}</td>
                {child.referenceProfitLoss < 0 && <td className="master-list-title-td-red">(-{child.referenceProfitLoss})</td>}
                {child.referenceProfitLoss == 0 && <td className="master-list-title-td-red">({child.referenceProfitLoss})</td>}
                {child.referenceProfitLoss > 0 && <td className="master-list-title-td-green">{child.referenceProfitLoss}</td>}
                <td className="master-list-title-td">
                    {child.status === "ACTIVE" &&
                        <label className="label-status-active">{child.status}</label>}
                    {child.status === "BLOCKED" &&
                        <label className="label-status-blocked">{child.status}</label>}
                    {child.status === "NEW" &&
                        <label className="label-status-new">{child.status}</label>}
                </td>
                {child.userType !== "user" && <td className="master-list-title-actions-td-small">
                    <ButtonToolbar style={{ padding: 1 }}>
                        <Button color="cyan" appearance="ghost" size="xs"
                            onClick={(e) => openHome(e, child)} >
                            Prof.
                        </Button>
                        <Button color="violet" appearance="ghost" size="xs"
                            onClick={(e) => statement(e, child)} >
                            Stmt.
                        </Button>
                    </ButtonToolbar>
                </td>}
                {child.userType === "user" && <td className="master-list-title-actions-td-small">
                    <ButtonToolbar style={{ padding: 1 }}>
                        <Button color="cyan" appearance="ghost" size="xs"
                            onClick={(e) => openHome(e, child)} >
                            Prof.
                        </Button>
                        <Button color="violet" appearance="ghost" size="xs"
                            onClick={(e) => statement(e, child)} >
                            Stmt.
                        </Button>
                        <Button color="orange" appearance="ghost" size="xs"
                            onClick={(e) => betHistory(e, child)}>
                            Bet Hist.
                        </Button>
                    </ButtonToolbar>
                </td>}
            </tr>
        )
    };

    return (
        <div className="table-top-container">
            <div className="table-container">
                <div className="table-content-container">
                    <div className="tbl-header">
                        <table>
                            <thead>
                                <tr>
                                    <th className="master-list-title-th-username">User Name</th>
                                    <th className="master-list-title-th">Credit Ref.</th>
                                    <th className="master-list-title-th">Share</th>
                                    <th className="master-list-title-th">Opening Bal.</th>
                                    <th className="master-list-title-th">Avail. Bal.</th>
                                    <th className="master-list-title-th">Exposure</th>
                                    <th className="master-list-title-th">Exposure Lim.</th>
                                    <th className="master-list-title-th">Ref. P/L</th>
                                    <th className="master-list-title-th">Status</th>
                                    <th className="master-list-title-actions-th-small">Actions</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                {immediateChildrens &&
                                    immediateChildrens.map((child, index) =>
                                        <Row
                                            child={child}
                                            key={index}
                                            index={index} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
