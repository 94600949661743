const CricFooter = ({ role }) => {

    const siteName = "Cric247";

    let agentType = "User";
    if (role.includes("subcompany")) {
        agentType = "Sub Company";
    } else if (role.includes("superstockist")) {
        agentType = "Super Stockist";
    } else if (role.includes("stockist")) {
        agentType = "Stockist";
    } else if (role.includes("agent")) {
        agentType = "Agent";
    } else if (role.includes("admin")) {
        agentType = "Admin";
    }

    return (
        <div>
            <div className="cric-footer-holder">
                <div style={{ width: "50%", textAlign: "left", justifyContent: "center"}}>
                    <b>{siteName}</b> | Powered By <b>{siteName} Gaming</b> | Copyright &copy; 2024-2025
                </div>
                <div style={{ width: "50%", textAlign: "right", justifyContent: "center"}}>
                    {agentType} Panel <b>v1.0.0</b>
                </div>
            </div>
        </div>
    );
};

export default CricFooter;