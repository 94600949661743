// import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useState, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Login from "./components/cric247/Login";
// import Register from "./components/Register";
// import Register from "./components/parvati/Register";
import CricRegister from "./components/cric247/Register";
import Edit from "./components/parvati/Edit";
import ChangeUserPassword from "./components/parvati/ChangeUserPassword";
// import ChangeMyPassword from "./components/parvati/ChangeMyPassword";
import CricChangeMyPassword from "./components/cric247/CricChangeMyPassword";
import Deposit from "./components/parvati/Deposit";
import Withdraw from "./components/parvati/Withdraw";
import Home from "./components/parvati/Home";
import CricHome from "./components/cric247/Home";
// import Statement from "./components/parvati/Statement";
import CricStatement from "./components/cric247/Statement";
import Ledgers from "./components/cric247/Ledgers";
import MatchesLiveTable from "./components/parvati/MatchesLiveTable";
import MatchesUpcomingTable from "./components/parvati/MatchesUpcomingTable";
import MatchesPastTable from "./components/parvati/MatchesPastTable";
import RegisterGames from "./components/parvati/RegisterGames";
import GameStart from "./components/parvati/GameStart";
import GameScreen from "./components/parvati/GameScreen";
import GameScreen2 from "./components/parvati/GameScreen2";
import MatchComplete from "./components/parvati/MatchComplete";
import MUIPaginationBetHistory from "./components/parvati/MUIPaginationBetHistory";
import MUIPaginationMasterDownline from "./components/parvati/MUIPaginationMasterDownline";
import MUIPMasterBook from "./components/parvati/MUIPMasterBook";
import MUIPMasterBook1 from "./components/parvati/MUIPMasterBook1";
import MUIPMasterBook2 from "./components/parvati/MUIPMasterBook2";
import MUIPMasterBook3 from "./components/parvati/MUIPMasterBook3";
import MUIPMasterBook4 from "./components/parvati/MUIPMasterBook4";
import MUIPImmediateChildren from "./components/parvati/MUIPImmediateChildren";
import MUIPImmediateChildren1 from "./components/parvati/MUIPImmediateChildren1";
import MUIPImmediateChildren2 from "./components/parvati/MUIPImmediateChildren2";
import MUIPImmediateChildren3 from "./components/parvati/MUIPImmediateChildren3";
import MUIPUpcomingMatches from "./components/parvati/MUIPUpcomingMatches";
import MUIPPastMatches from "./components/parvati/MUIPPastMatches";
import MUIPPastEventExposure from "./components/parvati/MUIPPastEventExposure";
import MUIPPastEventExposure1 from "./components/parvati/MUIPPastEventExposure1";
import MUIPPastEventExposure2 from "./components/parvati/MUIPPastEventExposure2";
import MUIPPastEventExposure3 from "./components/parvati/MUIPPastEventExposure3";
import MUIPPastEventExposure4 from "./components/parvati/MUIPPastEventExposure4";
import SupportedSports from "./components/parvati/SupportedSports";
import MatchScreenCricket from './components/parvati/MatchScreenCricket';
import LiveMatchesTable from './components/parvati/LiveMatchesTable';
import LiveMatches from "./components/cric247/LiveMatches";
// import Home from "./components/Home";
import Profile from "./components/Profile";
// import ReportAdmin from "./components/ReportAdmin";
// import ChangePassword from "./components/ChangePassword";
import CricChangePassword from "./components/cric247/ChangePassword";
import { httpHelpers } from "./services/httpHelpers";
import TokenService from "./services/token-service";
// import NavbarNew  from "./components/NavbarNew";
import MarqeeNavPath from "./components/cric247/MarqeeNavPath";
import CricFooter from "./components/cric247/Footer";
import { sidebarDataAdmin } from "./components/cric247/navbar/SidebarDataAdmin.jsx";
import { sidebarDataAgent } from "./components/cric247/navbar/SidebarDataAgent.jsx";
import { sidebarDataSC } from "./components/cric247/navbar/SidebarDataSC.jsx";
import { sidebarDataSST } from "./components/cric247/navbar/SidebarDataSST.jsx";
import { sidebarDataStockist } from "./components/cric247/navbar/SidebarDataStockist.jsx";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMore icon
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FaRupeeSign, FaSignOutAlt } from 'react-icons/fa';
import Button from '@mui/material/Button';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    paddingTop: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const customDrawerStyles = {
  listItem: {
    color: '#3963af'
  },
  listItemIcon: {
    color: '#3963af'
  },
  sublListItem: {
    color: '#3963af',
    paddingLeft: '45px'
  },
  drawer: {
    flexShrink: 0,
  },
};

export default function SideDrawer({ role, logout, user, visitedPaths, supportedSports }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [balance, setBalance] = useState();
  let url = "alpha/getBalance";
  const api = httpHelpers()

  let sideBarData;
  let myRole;
  let mRole = role;
  const [dropDown, setDropDown] = useState();

  if (mRole === null) {
    mRole = TokenService.getRole();
  }

  if (mRole.includes("admin")) {
    sideBarData = sidebarDataAdmin;
    myRole = "Admin";
  } else if (mRole.includes("agent")) {
    sideBarData = sidebarDataAgent;
    myRole = "Agent";
  } else if (mRole.includes("subcompany")) {
    sideBarData = sidebarDataSC;
    myRole = "Sub Company";
  } else if (mRole.includes("superstockist")) {
    sideBarData = sidebarDataSST;
    myRole = "Super Stockist";
  } else if (mRole.includes("stockist")) {
    sideBarData = sidebarDataStockist;
    myRole = "Stockist";
  }

  const handleToggle = (index) => {
    if (dropDown === index) {
      setDropDown(null);
    } else {
      setDropDown(index);
    }
  };

  const fetchBalance = () => {
    api
      .get(`${"beta/getBalance"}`)
      .then(res => {
        console.log("fetch balance", res);
        if (res && res.data) {
          setBalance(res.data);
        }
      })
      .catch(err => {
        console.log(err.response.status)
        if (err.response.status === 401) {
          logout();
        }
      });
  };

  useEffect(() => {
    if (user && user.accountStatus.includes("ACTIVE")) {
      fetchBalance();

      // Set up a timer to fetch the balance every 5 seconds
      const intervalId = setInterval(() => {
        fetchBalance();
      }, 100000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    } else {
      const newUser = TokenService.getUser();
      if (newUser && newUser.availableBalance) {
        setBalance(user.availableBalance);
      }
    }
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" style={{ paddingTop: "0px", background: '#ffffff', paddingRight: "0px" }} open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
          <span style={{ color: balance > 0 ? 'green' : '#D0011C', flexGrow: "1" }}>
            <FaRupeeSign style={{ color: "#3963af", marginBottom: "3px", height: "12px" }} /> {balance}
          </span>
          <Button style={{ color: "#3963af", fontSize: "12px" }} onClick={() => logout()}><FaSignOutAlt style={{ color: "#3963af", marginRight: "5px", height: "12px" }} />Sign out</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {sideBarData.map((item, index) => (
            <div key={index}>
              {item.subItems ? (
                <>
                  <ListItem
                    onClick={() => handleToggle(index)}
                  >
                    <ListItemButton>
                      <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />
                      {dropDown === index ? (
                        <ExpandLessIcon /> // Display the 'expand less' icon when open
                      ) : (
                        <ExpandMoreIcon /> // Display the 'expand more' icon when closed
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={dropDown === index} timeout="auto" unmountOnExit>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        component={Link}
                        to={subItem.link}
                        sx={customDrawerStyles.sublListItem}
                      // onClick={() => setOpenDrawer(false)}
                      >
                        <ListItemButton>
                          <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText primary={subItem.label} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </Collapse>
                </>
              ) : (
                <ListItem
                  key={index}
                  component={Link} // Use Link as the component for routing
                  to={item.link} // Set the link
                // onClick={() => setOpenDrawer(false)}

                >
                  <ListItemButton>
                    <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText sx={customDrawerStyles.listItem} primary={item.label} />
                  </ListItemButton>
                </ListItem>
              )}
            </div>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="top-div">
          {/* <NavbarNew /> */}
          {/* {role && <Navbar role={role} logout={logout} user={currentUser} />} */}
          {role && visitedPaths && <MarqeeNavPath visitedPaths={visitedPaths} />}
          <Routes>
            {/* <Route path="/login" exact element={role ? <Home role={role} logout={logout} /> : <Login role={role} logout={logout} />} />
            <Route path="/" exact element={role ? <Home role={role} logout={logout} /> : <Login role={role} logout={logout} />} /> */}
            <Route path="/dashboard"
              element={<Home role={role} logout={logout} />} />
            <Route path="/home"
              element={<CricHome role={role} logout={logout} />} />
            <Route path="/edit"
              element={<Edit role={role} logout={logout} />} />
            {/* <Route path="/childrenssc1"
            element={<ImmediateChildrens role={role} logout={logout} />} />
          <Route path="/childrenssst1"
            element={<ImmediateChildrens1 role={role} logout={logout} />} />
          <Route path="/childrensst1"
            element={<ImmediateChildrens2 role={role} logout={logout} />} />
          <Route path="/childrensagent1"
            element={<ImmediateChildrens3 role={role} logout={logout} />} />
          <Route path="/showsc1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showsst1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showst1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showagent1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showuser1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} /> */}
            <Route path="/registersc"
              element={<CricRegister role={role} logout={logout} />} />
            <Route path="/registersst"
              element={<CricRegister role={role} logout={logout} />} />
            <Route path="/registerst"
              element={<CricRegister role={role} logout={logout} />} />
            <Route path="/registeragent"
              element={<CricRegister role={role} logout={logout} />} />
            <Route path="/registeruser"
              element={<CricRegister role={role} logout={logout} />} />
            <Route path="/profile"
              element={<Profile role={role} logout={logout} />} />
            <Route path="/changepassword"
              element={<CricChangePassword role={role} logout={logout} />} />
            <Route path="/changemypassword"
              element={<CricChangeMyPassword role={role} logout={logout} />} />
            <Route path="/changeuserpassword"
              element={<ChangeUserPassword role={role} logout={logout} />} />
            <Route path="/deposit"
              element={<Deposit role={role} logout={logout} />} />
            <Route path="/withdraw"
              element={<Withdraw role={role} logout={logout} />} />
            <Route path="/statement"
              element={<CricStatement role={role} logout={logout} />} />
            <Route path="/ledgers"
              element={<Ledgers role={role} logout={logout} />} />
            <Route path="/livematches"
              element={<MatchesLiveTable role={role} logout={logout} />} />
            <Route path="/upcomingmatches1"
              element={<MatchesUpcomingTable role={role} logout={logout} />} />
            <Route path="/pastmatches1"
              element={<MatchesPastTable role={role} logout={logout} />} />
            <Route path="/registergames"
              element={<RegisterGames role={role} logout={logout} />} />
            <Route path="/startgame"
              element={<GameStart role={role} logout={logout} />} />
            <Route path="/gamescreen"
              element={<GameScreen role={role} logout={logout} />} />
            <Route path="/gamescreen"
              element={<GameScreen2 role={role} logout={logout} />} />
            <Route path="/matchcomplete"
              element={<MatchComplete role={role} logout={logout} />} />
            {/* <Route path="/bethistory1"
            element={<BetHistory role={role} logout={logout} />} />
          <Route path="/masterbook1"
            element={<MasterBook role={role} logout={logout} />} />
          <Route path="/mbsc1"
            element={<MasterBook1 role={role} logout={logout} />} />
          <Route path="/mbsst1"
            element={<MasterBook2 role={role} logout={logout} />} />
          <Route path="/mbst1"
            element={<MasterBook3 role={role} logout={logout} />} />
          <Route path="/mbagent1"
            element={<MasterBook4 role={role} logout={logout} />} />
          <Route path="/muipagination"
            element={<MUIPagination role={role} logout={logout} />} /> */}
            <Route path="/bethistory"
              element={<MUIPaginationBetHistory role={role} logout={logout} />} />
            <Route path="/showsc"
              element={<MUIPaginationMasterDownline role={role} logout={logout} />} />
            <Route path="/showsst"
              element={<MUIPaginationMasterDownline role={role} logout={logout} />} />
            <Route path="/showst"
              element={<MUIPaginationMasterDownline role={role} logout={logout} />} />
            <Route path="/showagent"
              element={<MUIPaginationMasterDownline role={role} logout={logout} />} />
            <Route path="/showuser"
              element={<MUIPaginationMasterDownline role={role} logout={logout} />} />
            <Route path="/masterbook"
              element={<MUIPMasterBook role={role} logout={logout} />} />
            <Route path="/mbsc"
              element={<MUIPMasterBook1 role={role} logout={logout} />} />
            <Route path="/mbsst"
              element={<MUIPMasterBook2 role={role} logout={logout} />} />
            <Route path="/mbst"
              element={<MUIPMasterBook3 role={role} logout={logout} />} />
            <Route path="/mbagent"
              element={<MUIPMasterBook4 role={role} logout={logout} />} />
            <Route path="/childrenssc"
              element={<MUIPImmediateChildren role={role} logout={logout} />} />
            <Route path="/childrenssst"
              element={<MUIPImmediateChildren1 role={role} logout={logout} />} />
            <Route path="/childrensst"
              element={<MUIPImmediateChildren2 role={role} logout={logout} />} />
            <Route path="/childrensagent"
              element={<MUIPImmediateChildren3 role={role} logout={logout} />} />
            <Route path="/upcomingmatches"
              element={<MUIPUpcomingMatches role={role} logout={logout} supportedSports={supportedSports} />} />
            <Route path="/pastmatches"
              element={<MUIPPastMatches role={role} logout={logout} supportedSports={supportedSports} />} />
            <Route path="/eventexposure"
              element={<MUIPPastEventExposure role={role} logout={logout} />} />
            <Route path="/eesc"
              element={<MUIPPastEventExposure1 role={role} logout={logout} />} />
            <Route path="/eesst"
              element={<MUIPPastEventExposure2 role={role} logout={logout} />} />
            <Route path="/eest"
              element={<MUIPPastEventExposure3 role={role} logout={logout} />} />
            <Route path="/eeagent"
              element={<MUIPPastEventExposure4 role={role} logout={logout} />} />
            <Route path="/sports/:event"
              element={<SupportedSports role={role} logout={logout} supportedSports={supportedSports} />} />
            <Route path="/matchscreen/:sport/:id"
              element={<MatchScreenCricket role={role} logout={logout} />} />
            <Route path="/matchlist"
              element={<LiveMatchesTable role={role} logout={logout} />} />
            <Route path="/matcheslist"
              element={<LiveMatches role={role} logout={logout} />} />
          </Routes>
        </div>
        {role && <CricFooter role={role} />}
      </Main>
    </Box>
  );
}
