import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

export default function MUIPImmediateChildren({ role, logout }) {
    console.log("show", role);
    const { state } = useLocation();
    const { child } = state ? state : "";
    const userName = child ? child.userName : null;
    const getImmediateChildrens = "/beta/getImmediateChildren?username=" + userName;
    const api = httpHelpers();
    const [immediateChildrens, setImmediateChildrens] = useState(null);
    let navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - immediateChildrens.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchImmediateChildrens = () => {
        api
            .get(`${getImmediateChildrens}`)
            .then(res => {
                console.log("imm childrens", res);
                if (res && res.data) {
                    setImmediateChildrens(res.data);
                } else {
                    setImmediateChildrens(null);
                }
            })
            .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    useEffect(() => {
        if (userName) {
            fetchImmediateChildrens();
        } else {
            logout();
        }
    }, []);

    const openHome = (e, child) => {
        e.preventDefault();
        navigate('/dashboard', { state: { child } });
    }

    const statement = (e, child) => {
        e.preventDefault();
        navigate('/statement', { state: { child } });
    };

    const betHistory = (e, child) => {
        e.preventDefault();
        const userId = child.userName;
        navigate('/bethistory', { state: { userId } });
    };

    const handleChildren = (e, child) => {
        e.preventDefault();
        if (child.userType.includes("subcompany")) {
            navigate('/childrenssc', { state: { child } });
        } else if (child.userType.includes("superstockist")) {
            navigate('/childrenssst', { state: { child } });
        } else if (child.userType.includes("stockist")) {
            navigate('/childrensst', { state: { child } });
        } else if (child.userType.includes("agent")) {
            navigate('/childrensagent', { state: { child } });
        }
    }

    const Row = ({ child }) => {
        return (
            <tr>
                {child.userType !== "user" && <td className="master-list-title-td-username">
                    {child.userType === "superstockist" && <label className="label-tag-sst">{"Sup. Sst."}</label>}
                    {child.userType === "stockist" && <label className="label-tag-st">{"Stockist"}</label>}
                    {child.userType === "agent" && <label className="label-tag-agent">{"Agent"}</label>}
                    <Button appearance="link"
                        onClick={(e) => handleChildren(e, child)}>{child.userName}</Button>
                </td>}
                {child.userType === "user" && <td className="master-list-title-td-username">
                    <label className="label-tag-user">{"user"}</label>
                    {child.userName}
                </td>}
                {child.userType !== "user" && <td className="master-list-title-td-blue">{child.creditReference}</td>}
                {child.userType === "user" && <td className="master-list-title-td-blue">-</td>}
                {child.userType !== "user" && <td className="master-list-title-td-blue">{child.share}</td>}
                {child.userType === "user" && <td className="master-list-title-td-blue">-</td>}
                <td className="master-list-title-td">{child.openingBalance}</td>
                <td className="master-list-title-td">{child.availableBalance}</td>
                {child.exposure < 0 && <td className="master-list-title-td-red">(-{child.exposure})</td>}
                {child.exposure == 0 && <td className="master-list-title-td-red">({child.exposure})</td>}
                {child.exposure > 0 && <td className="master-list-title-td-green">{child.exposure}</td>}
                <td className="master-list-title-td">{child.userType === "user" ? child.exposureLimit : "-"}</td>
                {child.referenceProfitLoss < 0 && <td className="master-list-title-td-red">(-{child.referenceProfitLoss})</td>}
                {child.referenceProfitLoss == 0 && <td className="master-list-title-td-red">({child.referenceProfitLoss})</td>}
                {child.referenceProfitLoss > 0 && <td className="master-list-title-td-green">{child.referenceProfitLoss}</td>}
                <td className="master-list-title-td">
                    {child.status === "ACTIVE" &&
                        <label className="label-status-active">{child.status}</label>}
                    {child.status === "BLOCKED" &&
                        <label className="label-status-blocked">{child.status}</label>}
                    {child.status === "NEW" &&
                        <label className="label-status-new">{child.status}</label>}
                </td>
                {child.userType !== "user" && <td className="master-list-title-actions-td-small">
                    <ButtonToolbar style={{ padding: 1 }}>
                        <Button color="cyan" appearance="ghost" size="xs"
                            onClick={(e) => openHome(e, child)} >
                            Prof.
                        </Button>
                        <Button color="violet" appearance="ghost" size="xs"
                            onClick={(e) => statement(e, child)} >
                            Stmt.
                        </Button>
                    </ButtonToolbar>
                </td>}
                {child.userType === "user" && <td className="master-list-title-actions-td-small">
                    <ButtonToolbar style={{ padding: 1 }}>
                        <Button color="cyan" appearance="ghost" size="xs"
                            onClick={(e) => openHome(e, child)} >
                            Prof.
                        </Button>
                        <Button color="violet" appearance="ghost" size="xs"
                            onClick={(e) => statement(e, child)} >
                            Stmt.
                        </Button>
                        <Button color="orange" appearance="ghost" size="xs"
                            onClick={(e) => betHistory(e, child)}>
                            Bet Hist.
                        </Button>
                    </ButtonToolbar>
                </td>}
            </tr>
        )
    };

    return (
        <div className="manage-master-downline-root">
            <div className="table-top-container">
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th-username">User Name</th>
                                        <th className="master-list-title-th">Credit Ref.</th>
                                        <th className="master-list-title-th">Share</th>
                                        <th className="master-list-title-th">Opening Bal.</th>
                                        <th className="master-list-title-th">Avail. Bal.</th>
                                        <th className="master-list-title-th">Exposure</th>
                                        <th className="master-list-title-th">Exposure Lim.</th>
                                        <th className="master-list-title-th">Ref. P/L</th>
                                        <th className="master-list-title-th">Status</th>
                                        <th className="master-list-title-actions-th-small">Actions</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div>
                            <table>
                                <tbody>
                                    {immediateChildrens && (rowsPerPage > 0
                                        ? immediateChildrens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : immediateChildrens
                                    ).map((child, index) =>
                                        <Row
                                            child={child}
                                            key={index}
                                            index={index} />)}
                                    {emptyRows > 0 && (
                                        <tr style={{ height: 34 * emptyRows }}>
                                            <td colSpan={3} aria-hidden />
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {immediateChildrens && immediateChildrens.length > rowsPerPage && <div>
                    <div className="table-pagination-container">
                        <div className="table-pagination-container-child">
                            <CustomTablePagination
                                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                rowsPerPageOptions={7}
                                colSpan={3}
                                count={immediateChildrens && immediateChildrens.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        'aria-label': 'rows per page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

const blue = {
    50: '#48aaad',
    200: '#48aaad',
    400: '#48aaad',
    900: '#48aaad',
};

const grey = {
    50: '#E5E4E2',
    100: '#C0C0C0',
    200: '#E5E4E2',
    300: '#C0C0C0',
    400: '#C0C0C0',
    500: '#C0C0C0',
    600: '#C0C0C0',
    700: '#C0C0C0',
    800: '#C0C0C0',
    900: '#C0C0C0',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #48aaad;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);