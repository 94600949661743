import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout, depositAgent } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";
import { SET_MESSAGE } from "../../actions/types";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const Deposit = ({ role, logout }) => {

    const { state } = useLocation();

    if (state === null) {
        logout();
    }
    const { child } = state ? state : null;
    console.log("deposit", child);

    const form = useRef();
    const checkBtn = useRef();

    const [name, setName] = useState(child ? child.name : "");
    const [userName, setUserName] = useState(child ? child.userName : "");
    const [depositAmount, setDepositAmount] = useState(0);
    const [remark, setRemark] = useState("");
    const [yourPassword, setYourPassword] = useState("");
    const [openingBalance, setOpeningBalance] = useState(child ? child.openingBalance : "");
    const [creditReference, setCreditReference] = useState(child ? child.creditReference : "");
    const [availableBalance, setAvailableBalance] = useState(child ? child.availableBalance : "");
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeDepositAmount = (e) => {
        const deposit = e.target.value;
        setDepositAmount(deposit);
    };

    const onChangeRemark = (e) => {
        const remark = e.target.value;
        setRemark(remark);
    };

    const onChangeCreditReference = (e) => {
        const cr = e.target.value;
        setCreditReference(cr);
    };

    const onChangeYourPassword = (e) => {
        const yp = e.target.value;
        setYourPassword(yp);
    };

    const handleDeposit = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        dispatch(clearMessage());

        if (checkBtn.current.context._errors.length === 0) {
            console.log("deposit", role);
            dispatch(depositAgent(depositAmount, remark, userName, yourPassword, creditReference))
                .then((data) => {
                    console.log("deposit data: ", data);
                    if (data.status === 401) {
                        if (data.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (data.status === 200) {
                        setSuccessful(true);
                    } else {
                        setSuccessful(false);
                        dispatch({
                            type: SET_MESSAGE,
                            payload: data.data,
                            role: role
                        });
                    }
                })
                .catch(() => {
                });
        }
    };

    return (
        <div className="register-top-container">
            <div className="register-container">
                <div className="card" style={{ marginTop: 90 }}>
                    {(message || successful) && (<div className="form-group" style={{ marginTop: "20px", marginLeft: "15px", marginRight: "15px" }}>
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {message ? message : ("Amount " + depositAmount + " is Deposited Successfully!")}
                        </div>
                    </div>)}
                    {!successful && <div>
                        <label className="register-header">Deposit</label>
                    </div>}
                    <div className="card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}

                        <Form onSubmit={handleDeposit} ref={form}>
                            {!successful && (
                                <div>
                                    {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="name">Name</label>
                                        </div>
                                        <Input
                                            id="reg_name"
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={name}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="userName">User Name</label>
                                        </div>
                                        <Input
                                            id="reg_username"
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            value={userName}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="openingbalance">Opening Balance</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_openingbalance"
                                            className="form-control"
                                            name="openingbalance"
                                            value={openingBalance}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="availablebalance">Available Balance</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_availablebalance"
                                            className="form-control"
                                            name="availablebalance"
                                            value={availableBalance}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    {child.userType !== "user" && <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="creditreference">Credit Reference</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_creditreference"
                                            className="form-control"
                                            name="creditreference"
                                            value={creditReference}
                                            onChange={onChangeCreditReference}
                                            validations={!successful && [required]}
                                        />
                                    </div>}
                                    <div className="hr-solid"></div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="depositamount">Deposit Amount</label>
                                        </div>
                                        <Input
                                            type="tel"
                                            id="reg_depositamount"
                                            className="form-control"
                                            name="depositamount"
                                            value={depositAmount}
                                            onChange={onChangeDepositAmount}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="remark">Remark</label>
                                        </div>
                                        <Input
                                            type="text"
                                            id="reg_remark"
                                            className="form-control"
                                            name="remark"
                                            value={remark}
                                            onChange={onChangeRemark}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="yourpassword">Your Password</label>
                                        </div>
                                        <Input
                                            id="reg_yourpassword"
                                            type="password"
                                            className="form-control"
                                            name="yourpassword"
                                            value={yourPassword}
                                            onChange={onChangeYourPassword}
                                            validations={!successful && [required, vpassword]}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-small">Deposit</button>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deposit;