import React, { useState } from 'react';
import FirstTable from './FirstTable';
import SecondTable from './SecondTable';
import './CardView.css';
import ExpandableTable from './ExpandableTable';

const CardView = ({ date, title, isExpanded, onClick, secondTableData, expandableTableData, matchOddsList, bookmakerOddsList, fancyList }) => {
  const [currentExpandableIndex, setCurrentExpandableIndex] = useState(null);

  const handleTableClick = (index) => {
    setCurrentExpandableIndex(currentExpandableIndex === index ? null : index);
  };

  return (
    <div className="card">
      <div className="card-header" onClick={onClick}>
        <div className="card-title">{date}</div>
        <div className="card-title">{title}</div>
      </div>
      {isExpanded && (
        <div className="card-content">
          <FirstTable />
          <div className="second-table-container">
            <SecondTable data={secondTableData} />
          </div>
          {expandableTableData.map((data, index) => (
            <div key={index}>
              <div className="expandable-table-header" onClick={() => handleTableClick(index)}>
                <span>{data.header}</span>
                <span>{currentExpandableIndex === index ? '-' : '+'}</span>
              </div>
              {currentExpandableIndex === index && (
                <ExpandableTable data={data} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardView;
