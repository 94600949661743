// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/DynamicTable.css';

const DynamicTable = ({ columns, data, sport, liveMatches }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(5);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  // Generate pagination buttons
  const pageNumbers = [];
  const visiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  if (endPage - startPage + 1 < visiblePages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  let navigate = useNavigate();
  const openLiveReport = (e, id, title) => {
    e.preventDefault();
    navigate(`/matchscreen/${sport}/${id}/${title}`);
    // navigate(`/livematchscreen/${sport}/${id}/${title}`);
  };

  return (
    <div className="dynamic-table-container">
      <table className="dynamic-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                {row.id}
              </td>
              <td>
                {row.id}
              </td>
              <td className="td-match-link">
                <Link to={`/matchesdashboard/${sport}/${row.id}/${row.name}`} className="name-link">
                  {row.name}
                </Link>
              </td>
              <td>
                {sport && sport.toUpperCase()}
              </td>
              <td>
                {row.openDate}
              </td>
              {!liveMatches && <td>{row.winner}</td>}
              <td>
                {!liveMatches ? <label className={row.profitLoss < 0 ? "label-loss" : "label-profit"}>{row.profitLoss.toFixed(1)}</label> :
                  <button className="cric-board-buttons board-buttons-nav-bar-dark-small2"
                    style={{ marginLeft: "5px" }}
                    onClick={(e) => openLiveReport(e, row.id, row.name)}>
                    Live Report
                  </button>}
              </td>
              {/* {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column === 'name' ? (
                    <Link to={`/profile/${row[column]}`} className="name-link">
                      {row[column]}
                    </Link>
                  ) : (
                    row[column]
                  )}
                </td>
              ))} */}
            </tr>
          ))}
        </tbody>
      </table>

      {currentData && <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="pagination-button"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="pagination-button"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>}
    </div>
  );
};

export default DynamicTable;
