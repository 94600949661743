import React, { useState, useEffect } from 'react';
import './MyPage.css';
import CardView from './CardView';
import { useParams } from 'react-router-dom';
import { httpHelpers } from "../../../services/httpHelpers";
// import "../App.css";
import "../../parvati/style/Video.css";
import "../../parvati/style/CricketBattersBowlersTable.css";
import { clearMessage } from '../../../actions/message';
import { useDispatch, useSelector } from "react-redux";
import CricketScoreBoard from '../../parvati/CricketScoreBoard';
import MatchBetPanel from '../../parvati/MatchBetPanel';
import {
  MATCH_ODDS,
  BOOKMAKER,
  FANCY_BET
} from '../../parvati/common/constants';

const LiveMatchScreen = ({ role, logout }) => {
  const { sport, id } = useParams();
  const [successful, setSuccessful] = useState(false);
  const api = httpHelpers();
  const getOFBRates = "/beta/getOFBForMatch?matchId=" + id;

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  // const matchOdds = "Match Odds";
  // const bookmaker = "Bookmaker";
  // const fancyBet = "Fancy Bet";
  const [matchOddsList, setMatchOddsList] = useState();
  const [bookmakerOddsList, setBookmakerOddsList] = useState();
  const [fancyList, setFancyList] = useState();
  const [matchDetails, setMatchDetails] = useState();

  const fetchMatchMarkets = () => {
    api
      .get(`${getOFBRates}`)
      .then(res => {
        console.log("live markets", res);
        if (res && res.data) {
          if (res.data.matchOddsList) {
            setMatchOddsList(res.data.matchOddsList);
          } else {
            setMatchOddsList(null);
          }
          if (res.data.bookmakerOddsList) {
            setBookmakerOddsList(res.data.bookmakerOddsList);
          } else {
            setBookmakerOddsList(null);
          }
          if (res.data.fancyList) {
            setFancyList(res.data.fancyList);
          } else {
            setFancyList(null);
          }
          if (res.data.matchScore) {
            setMatchDetails(null);
            setMatchDetails(res.data.matchScore);
          } else {
            setMatchDetails(null);
          }
        }
      })
      .catch(err => {
        console.log("error error", err);
        if (err) {
          if (err.data) {
            if (err.data.status && err.data.status === 401) {
              logout();
            }
          } else if (err.response) {
            if (err.response.status && err.response.status === 401) {
              logout();
            }
          }
        }
      });
  };

  // const fetchMatchMarkets = async () => {
  //     try {
  //         // const response = await api.customFetch("http://43.204.11.211:8080/cricket/getMatchBets?matchId=" + id);
  //         // const response = await api.customFetch("http://192.168.1.12:8090/getMatchDetails?matchId=9");
  //         const response = await api.customFetch("http://192.168.1.7:8080/alpha/getOFBRates?matchId=" + id);
  //         // const response = await api.customFetch("http://192.168.1.12:8080/getMatchBets?matchId=32902230");
  //         // console.log("fetch market res", response);
  //         if (response && response.status) {
  //             if (response.status === 200) {
  //                 const data = await response.json();
  //                 console.log("fetch market data 1", data.matchScore);
  //                 console.log("fetch market data 2", data);
  //                 if (data.matchOddsList) {
  //                     setMatchOddsList(data.matchOddsList);
  //                 } else {
  //                     setMatchOddsList(null);
  //                 }
  //                 if (data.bookmakerOddsList) {
  //                     setBookmakerOddsList(data.bookmakerOddsList);
  //                 } else {
  //                     setBookmakerOddsList(null);
  //                 }
  //                 if (data.fancyList) {
  //                     setFancyList(data.fancyList);
  //                 } else {
  //                     setFancyList(null);
  //                 }
  //                 if (data.matchScore) {
  //                     setMatchDetails(null);
  //                     setMatchDetails(data.matchScore);
  //                 } else {
  //                     setMatchDetails(null);
  //                 }
  //             }
  //         } else if (response === 401) {
  //             logout();
  //         }
  //         // if (response.status === 200) {
  //         //     setMatches(data);
  //         // } else if (response.status === 401) {
  //         //     logout();
  //         // } else {
  //         //     alert(data);
  //         // }
  //     } catch (error) {
  //         console.error("Error fetching cricket matches:", error);
  //     }
  // };

  useEffect(() => {
    fetchMatchMarkets();
    const intervalId = setInterval(() => {
      fetchMatchMarkets();
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [currentExpandableIndex, setCurrentExpandableIndex] = useState(null);

  const cardData = [
    {
      date: '2024-08-08',
      title: 'Event 1 Description',
      secondTableData: [
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
      ],
      expandableTableData: [
        {
          headers: ['Header 1', 'Header 2', 'Header 3'],
          rows: [
            ['Row 1 Cell 1', 'Row 1 Cell 2', 'Row 1 Cell 3'],
            ['Row 2 Cell 1', 'Row 2 Cell 2', 'Row 2 Cell 3']
          ]
        },
        {
          headers: ['Header A', 'Header B', 'Header C'],
          rows: [
            ['Row A1 Cell 1', 'Row A1 Cell 2', 'Row A1 Cell 3'],
            ['Row A2 Cell 1', 'Row A2 Cell 2', 'Row A2 Cell 3']
          ]
        }
      ]
    },
    {
      date: '2024-08-08',
      title: 'Event 1 Description',
      secondTableData: [
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
        { col1: 'Data 1', col2: 'Data 2', col3: 'Data 3', col4: 'Data 4', col5: 'Data 5', col6: 'Data 6', col7: 'Data 7', col8: 'Data 8' },
      ],
      expandableTableData: [
        {
          headers: ['Header 1', 'Header 2', 'Header 3'],
          rows: [
            ['Row 1 Cell 1', 'Row 1 Cell 2', 'Row 1 Cell 3'],
            ['Row 2 Cell 1', 'Row 2 Cell 2', 'Row 2 Cell 3']
          ]
        },
        {
          headers: ['Header A', 'Header B', 'Header C'],
          rows: [
            ['Row A1 Cell 1', 'Row A1 Cell 2', 'Row A1 Cell 3'],
            ['Row A2 Cell 1', 'Row A2 Cell 2', 'Row A2 Cell 3']
          ]
        }
      ]
    },
  ];

  const handleCardClick = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };

  return (
    <div className="container">
      <div className="left-column">
        {/* <div className="live-streaming">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/dQw4w9WgXcQ"
            title="Live Streaming"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}
        <div className="live-match-screen-scoreboard-container">
          {sport === "cricket" && matchDetails && <CricketScoreBoard matchId={id} matchDetails={matchDetails} />}
        </div>
      </div>
      <div className="right-column">
        {matchDetails && cardData.map((card, index) => (
          <CardView
            key={index}
            date={card.date}
            title={card.title}
            isExpanded={expandedCardIndex === index}
            onClick={() => handleCardClick(index)}
            secondTableData={card.secondTableData}
            expandableTableData={card.expandableTableData}
            currentExpandableIndex={currentExpandableIndex}
            setCurrentExpandableIndex={setCurrentExpandableIndex}
            matchDetails={matchDetails}
            matchOddsList={matchOddsList}
            bookmakerOddsList={bookmakerOddsList}
            fancyList={fancyList}
          />
        ))}
      </div>
    </div>
  );
};

export default LiveMatchScreen;
