import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service.js";
import { register, logout } from "../../actions/auth.js";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message.js";
import { SET_MESSAGE } from "../../actions/types.js";
import { httpHelpers } from "../../services/httpHelpers";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  const re = /^\S*$/;
  if (!re.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        User Name can not contain spaces.
      </div>
    );
  } else if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const validPhone = (value) => {
  console.log(value)
  if (!isMobilePhone(value) || value.length < 10) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid phone.
      </div>
    );
  }
};

const validCommission = (value) => {
  console.log(value)
  if (value > 100 || value < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter in valid range (0 - 100).
      </div>
    );
  }
};

const UpdateGlobalProperties = ({ role, logout }) => {
  let registerBy = role;
  const user = TokenService.getUser();

  if (user === null) {
    logout();
  }

  const href = window.location.href;
  let regType = "User ";
  let registerType = "user";
  if (href.includes("registersc")) {
    regType = "SC ";
    registerType = "subcompany";
  } else if (href.includes("registersst")) {
    regType = "SST ";
    registerType = "superstockist";
  } else if (href.includes("registerst")) {
    regType = "Stockist ";
    registerType = "stockist";
  } else if (href.includes("registeragent")) {
    regType = "Agent ";
    registerType = "agent";
  }

  const form = useRef();
  const checkBtn = useRef();
  const [globalMin, setGlobalMin] = useState();
  const [globalMax, setGlobalMax] = useState();
  const [marketMin, setMarketMin] = useState();
  const [marketMax, setMarketMax] = useState();
  const [sessionMin, setSessionMin] = useState();
  const [sessionMax, setSessionMax] = useState();
  const [successful, setSuccessful] = useState(false);
  const [newCreatedUserName, setNewCreatedUserName] = useState(null);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    dispatch(clearMessage());
  }, [])

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const onChangeGlobalMin = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      setGlobalMin(newValue);
    } else {
      setGlobalMin(0);
    }
  };

  const onChangeGlobalMax = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      setGlobalMax(newValue);
    } else {
      setGlobalMax(0);
    }
  };

  const onChangeMarketMin = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      setMarketMin(newValue);
    } else {
      setMarketMin(0);
    }
  };

  const onChangeMarketMax = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      setMarketMax(newValue);
    } else {
      setMarketMax(0);
    }
  };

  const onChangeSessionMin = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      setSessionMin(newValue);
    } else {
      setSessionMin(0);
    }
  };

  const onChangeSessionMax = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      setSessionMax(newValue);
    } else {
      setSessionMax(0);
    }
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    // setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    // setConfirmPassword(confirmPassword);
  };

  const onChangeYourPassword = (e) => {
    const yp = e.target.value;
    // setYourPassword(yp);
  };

  // const onChangeMyMatchShare = (e) => {
  //   const newValue = e.target.value;
  //   if (newValue === '' || (/^\d+$/.test(newValue))) {
  //     if (Number(newValue) > Number(user.share)) {
  //       setMyMatchShare(user.share);
  //     } else if (Number(newValue) < 0) {
  //       setMyMatchShare(0);
  //     } else {
  //       setMyMatchShare(newValue);
  //     }
  //   } else {
  //     setMyMatchShare(0);
  //   }
  // };

  // useEffect(() => {
  //   setAgentMatchShare(Number(user.share) - Number(myMatchShare));
  // }, [myMatchShare])

  // const [input, setInput] = useState({
  //   password: '',
  //   confirmPassword: ''
  // });

  // const [error, setError] = useState({
  //   password: '',
  //   confirmPassword: ''
  // });

  // const onInputChange = e => {
  //   const { name, value } = e.target;
  //   setInput(prev => ({
  //     ...prev,
  //     [name]: value
  //   }));
  //   validateInput(e);
  // }

  // const validateInput = e => {
  //   let { name, value } = e.target;
  //   setError(prev => {
  //     const stateObj = { ...prev, [name]: "" };
  //     // console.log("Password", name, value);
  //     switch (name) {
  //       case "password":
  //         if (input.confirmPassword && value !== input.confirmPassword) {
  //           stateObj[name] = "Password and Confirm Password does not match.";
  //         } else if (input.confirmPassword && value === input.confirmPassword) {
  //           stateObj[name] = "";
  //         } else {
  //           stateObj[name] = input.confirmPassword ? "" : error.confirmPassword;
  //         }
  //         setPassword(value)
  //         break;

  //       case "confirmPassword":
  //         if (input.password && value !== input.password) {
  //           stateObj[name] = "Password and Confirm Password does not match.";
  //         } else if (input.password && value === input.password) {
  //           stateObj[name] = "";
  //         } else {
  //           stateObj[name] = input.password ? "" : error.password;
  //         }
  //         setConfirmPassword(value)
  //         break;
  //       default:
  //         break;
  //     }
  //     return stateObj;
  //   });
  // }

  const handleRegister = (e) => {
    // dispatch(clearMessage());
    // e.preventDefault();
    // setSuccessful(false);
    // form.current.validateAll();

    // if (checkBtn.current.context._errors.length === 0) {
    //   console.log("register user", role, firstName, lastName, password, registerType, fixLimit, agentMatchShare, agentMatchCommission, agentSessionCommission);
    //   dispatch(register(firstName, lastName, password, registerType, fixLimit, agentMatchShare, agentMatchCommission, agentSessionCommission, role))
    //     .then((data) => {
    //       console.log("reg data: ", data);
    //       if (data.status === 401) {
    //         if (data.data === "Wrong password") {
    //           setSuccessful(false);
    //         } else {
    //           logout();
    //         }
    //       } else if (data.status === 200) {
    //         setNewCreatedUserName(data.data.userId);
    //         setSuccessful(true);
    //       } else {
    //         setSuccessful(false);
    //         dispatch({
    //           type: SET_MESSAGE,
    //           payload: data.data,
    //           role: role
    //         });
    //       }
    //     })
    //     .catch(() => {
    //     });
    // }
  };

  // const handleAddMore = () => {
  //   setFirstName("");
  //   setLastName("");
  //   setUserName("");
  //   setPassword("");
  //   setConfirmPassword("");
  //   setMyMatchShare("");
  //   setAgentMatchShare("");
  //   setFixLimit("");
  //   setAgentMatchCommission("");
  //   setAgentSessionCommission("");
  //   setSuccessful(false);
  //   setNewCreatedUserName(null);
  // }

  const [value, setValue] = useState('');

  // const handleChange = (e) => {
  //   const newValue = e.target.value;
  //   if (newValue === '' || (/^\d+$/.test(newValue))) {
  //     if (Number(newValue) > 100) {
  //       setValue('100');
  //     } else {
  //       setValue(newValue);
  //     }
  //   }
  // };

  // const handleBlur = () => {
  //   if (value !== '' && Number(value) > 100) {
  //     setValue('100');
  //   }
  // };

  let getGlobalProperties = "alpha/getAllGlobalProperties";
  const api = httpHelpers();
  const [globalProperties, setGlobalProperties] = useState(null);

  const fetchGlobalProperties = () => {
    api
      .get(`${getGlobalProperties}`)
      .then(res => {
        console.log("global prop res", res);
        if (res && res.data && res.data.length > 0) {
          setGlobalProperties(res.data);
        } else {
          setGlobalProperties(null);
        }
      })

      .catch(err => {
        console.log("error error", err);
        if (err) {
          if (err.data) {
            if (err.data.status && err.data.status === 401) {
              logout();
            }
          } else if (err.response) {
            if (err.response.status && err.response.status === 401) {
              logout();
            }
          }
        }
      });
  };

  useEffect(() => {
    fetchGlobalProperties();
  }, []);

  return (
    <div className="cric-register-top-container">
      <div className="register-container">
        <div className="cric-card">
          {!successful && <div>
            <label className="cric-register-header">Update Properties</label>
          </div>}
          <div className="cric-card-container">
            {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
            {newCreatedUserName && <label style={{ marginTop: "250px", fontSize: "30px", fontWeight: "bold" }}>{newCreatedUserName}</label>}
            {(message || successful) && (<div className="form-group" style={{ marginTop: "10px" }}>
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {message ? message : ("New User " + newCreatedUserName + " is Created Successfully!")}
              </div>
            </div>)}
            {/* {successful && (<div>
              <button className="board-buttons board-buttons-nav-bar-dark-small" onClick={() => handleAddMore()}>Add More</button>
            </div>)} */}

            <Form onSubmit={handleRegister} ref={form}>
              {!successful && (
                <div>
                  {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                  {/* <input
                    type="tel"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />   */}
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="gmin">Global Min</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          id="reg_gmin"
                          type="number"
                          className="form-control"
                          name="gmin"
                          maxLength="20"
                          value={globalMin}
                          onChange={onChangeGlobalMin}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="gmax">Global Max</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          id="reg_gmax"
                          type="number"
                          className="form-control"
                          name="gmax"
                          maxLength="20"
                          value={globalMax}
                          onChange={onChangeGlobalMax}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="mmin">Market Min</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="0"
                          type="number"
                          id="reg_min"
                          className="form-control"
                          name="marketMin"
                          value={marketMin}
                          onChange={onChangeMarketMin}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                        {/* <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>Fix Limit can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.balance}</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>My Match Share can be set from 0 to</label>
                  </div>
                  <div className="div-note-2">
                    <label>95</label>
                  </div>
                </div> */}
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="mmax">Market Max</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          type="number"
                          id="reg_mmshare"
                          className="form-control"
                          name="mmax"
                          value={marketMax}
                          onChange={onChangeMarketMax}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                        {/* <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>My Match Share can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.share}</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="smin">Session Min</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="0"
                          type="number"
                          id="reg_smin"
                          className="form-control"
                          name="smin"
                          value={sessionMin}
                          onChange={onChangeSessionMin}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                        {/* <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>{regType} Match Commission can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.matchCommission}</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="smax">Session Max</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="0"
                          type="number"
                          id="reg_smax"
                          className="form-control"
                          name="smax"
                          value={sessionMax}
                          onChange={onChangeSessionMax}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                        {/* <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>{regType} Match Commission can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.matchCommission}</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="share">{regType} Session Commission</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="0"
                          type="tel"
                          id="reg_asc"
                          className="form-control"
                          name="asc"
                          value={agentSessionCommission}
                          onChange={onChangeAgentSessionCommission}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                          maxLength={(user.sessionCommission.toString().length).toString()}
                        />
                        <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>{regType} Session Commission can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.sessionCommission}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="form-group">
                <div className="div-label-form">
                  <label className="label-form" htmlFor="sessioncommission">
                    {regType} Session Commission
                  </label>
                </div>
                <Input
                  type="tel"
                  id="reg_sessioncommission"
                  className="form-control"
                  name="sessioncommision"
                  value={sessionCommission}
                  onChange={onChangeSessionCommission}
                  validations={!successful && [required]}
                />
                <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>
                      {regType} Session Commission can be set from 0 to
                    </label>
                  </div>
                  <div className="div-note-2">
                    <label>3</label>
                  </div>
                </div>
              </div> */}
                  {/* <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="password">Password</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="Enter Password"
                          id="reg_password"
                          type="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={onInputChange}
                          onBlur={validateInput}
                          validations={!successful && [required, vpassword]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="confirmPassword">Confirm Password</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="Confirm Password"
                          id="reg_confirmpassword"
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={onInputChange}
                          onBlur={validateInput}
                          validations={!successful && [required, vpassword]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="hr-solid"></div>
                  <div className="form-group">
                    <div className="div-label-form">
                      <label className="label-form" htmlFor="yourpassword">Your Password</label>
                    </div>
                    <Input
                      id="reg_yourpassword"
                      type="password"
                      className="form-control"
                      name="yourpassword"
                      value={yourPassword}
                      onChange={onChangeYourPassword}
                      validations={!successful && [required, vpassword]}
                    />
                  </div> */}
                  <div className="form-group" style={{ marginTop: "30px" }}>
                    <button className="board-buttons board-buttons-nav-bar-dark-medium">Update Properties</button>
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateGlobalProperties;