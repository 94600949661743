import { Link, useLocation } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import './css/Breadcrumbs.css';

const routes = [
    { path: '/home', breadcrumb: 'Home' },
    { path: '/registersc', breadcrumb: 'Create SC' },
    { path: '/registersst', breadcrumb: 'Create SST' },
    { path: '/registerst', breadcrumb: 'Create ST' },
    { path: '/registeragent', breadcrumb: 'Create Agent' },
    { path: '/registeruser', breadcrumb: 'Create User' },
    { path: '/changepassword', breadcrumb: 'Change Password' },
    { path: '/changemypassword', breadcrumb: 'Change Password' },
    { path: '/showsc', breadcrumb: 'SC' },
    { path: '/showsst', breadcrumb: 'SST' },
    { path: '/showst', breadcrumb: 'ST' },
    { path: '/showagent', breadcrumb: 'Agent' },
    { path: '/showuser', breadcrumb: 'User' },
    { path: '/icdashboard/:uid/:etype', breadcrumb: 'Dashboard' },
];

function Breadcrumbs() {
    const breadcrumbs = useBreadcrumbs(routes);
    const location = useLocation()

    let currentPage = '';
    let previousPages = [];
    let crumbs = location.pathname.split('/')
        .filter(crumb => crumb !== '')
        .map(crumb => {
            let prevPage = '';
            if (crumb.includes('home')) {
                prevPage = "Home";
            } else if (crumb.includes('showsc')) {
                prevPage = " / SC";
            } else if (crumb.includes('showsst')) {
                prevPage = " / SST";
            } else if (crumb.includes('showst')) {
                prevPage = " / ST";
            } else if (crumb.includes('showagent')) {
                prevPage = " / Agent";
            } else if (crumb.includes('showuser')) {
                prevPage = " / User";
            } else if (crumb.includes('registersc')) {
                prevPage = " / Create SC";
            } else if (crumb.includes('registersst')) {
                prevPage = " / Create SST";
            } else if (crumb.includes('registerst')) {
                prevPage = " / Create ST";
            } else if (crumb.includes('registeragent')) {
                prevPage = " / Create Agent";
            } else if (crumb.includes('registeruser')) {
                prevPage = " / Create User";
            } else if (crumb.includes('icdashboard')) {
                prevPage = " / Agent Dashboard";
            }

            previousPages.push(prevPage);
            currentPage = prevPage;
        })

    return (
        <div className="nav-path-holder" style={{ marginTop: "-6px", paddingTop: "15px" }}>
            <label className="nav-path-current-page">{currentPage}</label>
            <div className="breadcrumbs">
                {crumbs}
                {/* {breadcrumbs.map(({ match, breadcrumb }) => (
                    <label
                        key={match.url}
                        className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        {breadcrumb} /
                    </label>
                ))} */}
            </div>
        </div>
    );
}

export default Breadcrumbs;