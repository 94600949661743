// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/DynamicTable.css';
import '../css/EntityStatementTable.css';
import '../css/AgentTablePagination.css';
import { DEPOSIT_COINS, WITHDRAW_COINS } from '../common/Constants';

const EntityStatementTable = ({ columns, data, tableHeader }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setItemsPerPage(5);
            } else {
                setItemsPerPage(5);
            }
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // let navigate = useNavigate();
    // const openLiveReport = (e, id, title) => {
    //     e.preventDefault();
    //     navigate(`/matchscreen/${sport}/${id}/${title}`);
    //     // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    // };

    return (
        <div className="estatement-table-root">
            {/* <div className="estatement-table-container"> */}
            <table className="estatement-table-custom">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="estatement-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="estatement-table-row">
                            <td className="estatement-table-custom-td">
                                {row.transactionTime}
                            </td>
                            <td className="estatement-table-custom-td">
                                {row.remark}
                            </td>
                            <td className="estatement-table-custom-td">
                                {row.transactionType === DEPOSIT_COINS && "-"}
                                {row.transactionType === WITHDRAW_COINS && row.amount.toFixed(1)}
                            </td>
                            <td className="estatement-table-custom-td">
                                {row.transactionType === DEPOSIT_COINS && row.amount.toFixed(1)}
                                {row.transactionType === WITHDRAW_COINS && "-"}
                            </td>
                            <td className="estatement-table-custom-td">
                                0
                            </td>
                            <td className="estatement-table-custom-td">
                                0
                            </td>
                            <td className="estatement-table-custom-td">
                                {row.toUserBalance.toFixed(1)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* </div> */}
            {/* {currentData && <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="agent-table-pagination-prev-button"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`estatement-table-pagination-button ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="agent-table-pagination-next-button"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>} */}
        </div>
    );
};

export default EntityStatementTable;
