import React, { useCallback, useEffect, useState } from "react";
import ListSharpIcon from '@mui/icons-material/ListSharp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SportsEsportsSharpIcon from '@mui/icons-material/SportsEsportsSharp';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const AdminSidebarData = [
    {
        title:"Agents",
        icon: <ListSharpIcon />,
        link: "/boardadmin"
    },
    {
        title:"Statement",
        icon: <SummarizeIcon />,
        link: "/statementadmin"
    },
    {
        title:"Game Board",
        icon: <SportsEsportsSharpIcon />,
        link: "/gameboardadmin"
    },
    {
        title:"Add Agent",
        icon: <AddCircleOutlineIcon />,
        link: "/regAdmin"
    }
]