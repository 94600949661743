// import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useState, useEffect, useRef } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import CricHome from "./components/cric247/Home";
import { httpHelpers } from "./services/httpHelpers";
import TokenService from "./services/token-service";
// import NavbarNew  from "./components/NavbarNew";
import MarqeeNavPath from "./components/cric247/MarqeeNavPath";
import CricFooter from "./components/cric247/Footer";
import { FaSignOutAlt } from 'react-icons/fa';
import Button from '@mui/material/Button';
import {
  MdDashboard, MdManageAccounts,
  MdPassword, MdPages, MdSettings,
  MdBlock,
  MdPercent,
  MdSportsCricket,
  MdMoney,
  MdCalculate,
  MdBalance,
  MdBusiness,
  MdCasino,
} from "react-icons/md";

const isSmallScreen = window.matchMedia("(max-width: 700px)").matches ? true : false;
const drawerWidth = isSmallScreen ? 240 : 240;

const openedMixin = (theme) => ({
  width: (isSmallScreen ? 240 : 240),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: (isSmallScreen ? 0 : 64),
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: 12,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const SubMenuContainer = styled('div')({
  position: 'absolute',
  width: '200px',
  marginLeft: (isSmallScreen ? -177 : -1),
  transition: 'transform 0ms ease-in-out',
  transform: 'translateX(100%)',
  zIndex: 1201,
  backgroundColor: 'white',
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)',
  marginTop: '15px',
});

const SubMenuList = styled(List)({
  padding: 0,
  margin: 0,
});

const customDrawerStyles = {
  listItem: {
    color: 'lightgrey'
  },
  listItemIcon: {
    color: 'lightgrey',
    minWidth: 0,
    justifyContent: 'center',
    paddingLeft: 0.5,
    fontSize: 20,
  },
  listItemText: {
    color: 'lightgrey',
    marginLeft: 3,
  },
  sublListItem: {
    color: 'lightgrey'
  },
  sublListItem: {
    color: 'lightgrey'
  },
  drawer: {
    flexShrink: 0,
  },
};

const sidebarDataAdmin2 = [
  { label: 'Dashboard', icon: <MdDashboard />, link: '/home' },
  {
    label: 'Manage',
    icon: <MdManageAccounts />,
    link: '/manage',
    subItems: [
      { label: 'SM', icon: <MdManageAccounts />, link: '/showsm' },
      { label: 'SC', icon: <MdManageAccounts />, link: '/showsc' },
      { label: 'SST', icon: <MdManageAccounts />, link: '/showsst' },
      { label: 'Stockists', icon: <MdManageAccounts />, link: '/showst' },
      { label: 'Agents', icon: <MdManageAccounts />, link: '/showagent' },
    ],
  },
  { label: 'Live Matches', icon: <MdSportsCricket />, link: 'matcheslist' },
  { label: 'Completed Matches', icon: <MdSportsCricket />, link: 'compmatcheslist' },
  { label: 'Live Casino', icon: <MdCasino /> },
  { label: 'Virtual Casino', icon: <MdCasino /> },
  { label: 'Block Market', icon: <MdBlock />, link: 'blockmarket' },
  {
    label: 'Manage Clients',
    icon: <MdManageAccounts />,
    link: '/manageclients',
    subItems: [
      { label: 'My Clients', icon: <MdManageAccounts />, link: '/clients' },
      { label: 'Blocked Clients', icon: <MdBlock />, link: '/blockedclients' },
      { label: 'Commission & Limits', icon: <MdPercent />, link: '/commissionlimits' },
    ],
  },
  { label: 'Settings', icon: <MdSettings />, link: '/settings' },
  {
    label: 'Manage Ledgers',
    icon: <MdPages />,
    link: '/manage',
    subItems: [
      { label: 'Collection Report', icon: <MdCalculate />, link: '/collectionreport' },
      { label: 'Company Len-Den', icon: <MdBusiness />, link: '/eledger' },
      { label: 'In-Out', icon: <MdBalance />, link: '/estatement' },
      { label: 'P & L', icon: <MdMoney />, link: '/agents' },
    ],
  },
  { label: 'Change Password', icon: <MdPassword />, link: '/changemypassword' },
  // Add more sidebar items as needed
];

export default function MiniNavBar({ role, logout, user, visitedPaths, supportedSports }) {

  console.log("MiniNavBar user", user);
  const [activeUser, setActiveUser] = useState(user && user.accountStatus === "ACTIVE");
  console.log("MiniNavBar activeUser", activeUser);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedSubIndex, setSelectedSubIndex] = useState(null);
  const [subMenuItem, setSubMenuItem] = useState(null);
  const [subMenuPosition, setSubMenuPosition] = useState({ top: 0, left: 50 });
  const listItemRefs = useRef([]);

  const handleClickOutside = (event) => {
    setActiveSubMenu(null);
    handleSubMenuToggle(null);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (activeSubMenu !== null && listItemRefs.current[activeSubMenu]) {
      document.addEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      window.addEventListener('scroll', handleClickOutside);
      const rect = listItemRefs.current[activeSubMenu].getBoundingClientRect();
      setSubMenuPosition({
        top: rect.top + window.scrollY + rect.height - 64,
        left: open ? 40 : -135,
      });
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      window.removeEventListener('scroll', handleClickOutside);
    }
  }, [open, theme, activeSubMenu]);

  const handleDrawerOpen = () => {
    setOpen(true);
    setActiveSubMenu(null);
    handleSubMenuToggle(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setActiveSubMenu(null);
    handleSubMenuToggle(null);
  };

  const handleMenuToggle = (index, item) => {
    console.log("handleSubMenu", index, item);
    if (item) {
      setSelectedSubIndex(null);
      setSubMenuItem((prev) => (prev === item ? null : item));
      setActiveSubMenu((prev) => (prev === index ? null : index));
      setSelectedIndex((prev) => (prev === index ? null : index));
    } else {
      handleSubMenuToggle(null);
      setSelectedIndex((prev) => (prev === index ? null : index));
      if (index != null)
        handleDrawerClose();
    }
  };

  const handleSubMenuToggle = (index, item) => {
    setSubMenuItem((prev) => (prev === item ? null : item));
    setActiveSubMenu((prev) => (prev === index ? null : index));

    if (index != null) {
      setSelectedSubIndex((prev) => (prev === index ? null : index));
      if (index != null)
        handleDrawerClose();
    }
  };

  const [balance, setBalance] = useState();

  let sideBarData;
  let myRole;
  let mRole = role;
  let eType = "";

  if (mRole === null) {
    mRole = TokenService.getRole();
  }

  sideBarData = sidebarDataAdmin2;
  myRole = "Admin";
  eType = "Admin";

  console.log("MiniNavBar sidebardata", sideBarData);
  const [bannerMessage, setBannerMessage] = useState();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" style={{ paddingTop: "0px", background: '#ffffff', paddingRight: "0px" }} open={open}>
        <Toolbar>
          {!open ? <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
            : <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>}
          <div style={{ width: "100%", textAlign: "right" }}>
            <label style={{ fontSize: "11px", marginRight: "-15px" }}>
              Bal:
            </label>
            <span style={{ fontSize: "11px", fontWeight: "bold" }}>
              {balance}
            </span>
            <Button style={{ color: "#3963af", fontSize: "11px" }} onClick={() => logout()}><FaSignOutAlt style={{ color: "#3963af", marginRight: "5px", height: "12px" }} />Sign out</Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{
        '& .MuiDrawer-paper': {
          boxShadow: 'none',  // Remove the shadow
          borderRight: 'none', // If there's a border, remove it
        },
      }}>
        <List sx={{ pt: 0.1, background: "#1b2227", height: "100%" }}>
          {!isSmallScreen && <div className="mini-nav-bar-profile-block">
            {user && <div>
              <ListItemText primary={user.userId + `(${user.firstName})`} primaryTypographyProps={{ fontSize: '12px' }} />
              <ListItemText primary={user.balance} primaryTypographyProps={{ fontSize: '12px' }} />
              <ListItemText sx={{ color: "steelblue" }} primary={eType} primaryTypographyProps={{ fontSize: '12px' }} />
            </div>}
          </div>}
          {isSmallScreen && <div className="mini-nav-bar-profile-block">
            {user && <div>
              <ListItemText primary={user.userId} primaryTypographyProps={{ fontSize: '12px' }} />
              <ListItemText primary={`(${user.firstName})`} primaryTypographyProps={{ fontSize: '12px' }} />
              <ListItemText sx={{ color: "steelblue" }} primary={eType} primaryTypographyProps={{ fontSize: '12px' }} />
            </div>}
          </div>}
          {sideBarData.map((item, index) => (
            <ListItem
              key={item.id}
              ref={(el) => (listItemRefs.current[index] = el)}
              onMouseEnter={() => handleSubMenuToggle(index, item)}
              onMouseLeave={() => handleSubMenuToggle(index, item)}
              sx={{
                ...(selectedIndex === index && {
                  backgroundColor: '#1c313a',
                }),
                cursor: 'pointer',
              }}
            >
              <ListItemButton
                onClick={() => handleMenuToggle(index, item)}
                sx={{
                  color: selectedIndex === index ? 'white' : 'lightgrey',
                }}
              >
                <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.title} primaryTypographyProps={{ fontSize: '12px' }} />
              </ListItemButton>
              {activeSubMenu === index && (
                <SubMenuContainer
                  style={{
                    top: `${subMenuPosition.top}px`,
                    left: `${subMenuPosition.left}px`,
                  }}
                >
                  <SubMenuList>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem key={subItem.id} disablePadding>
                        <ListItemButton
                          component={Link}
                          to={subItem.link}
                          sx={customDrawerStyles.sublListItem}
                          onClick={stopPropagation}
                        >
                          <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                            <subItem.icon />
                          </ListItemIcon>
                          <ListItemText primary={subItem.title} primaryTypographyProps={{ fontSize: '12px' }} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </SubMenuList>
                </SubMenuContainer>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
      {/* {subMenuItem && <SubMenuContainer
        style={{
          top: subMenuPosition.top,
          left: subMenuPosition.left,
          background: "#2d333a",
          transform: 'translateX(100%)',
        }}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <SubMenuList component="div" disablePadding>
          {subMenuItem.subItems.map((subItem, subIndex) => (
            <ListItem
              key={subIndex}
              component={Link}
              to={subItem.link}
              disablePadding
              sx={{
                display: 'block',
                pt: "4px",
                bgcolor: selectedSubIndex === subIndex ? '#171a1d' : 'transparent',
                color: selectedSubIndex === subIndex ? 'white' : customDrawerStyles.listItem,
                '&:hover': {
                  bgcolor: '#171a1d',
                  color: "white"
                },
              }}
              style={{ textDecoration: "none" }}
              onClick={() => handleSubMenuToggle(subIndex)}
            >
              <ListItemButton>
                <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                  {subItem.icon}
                </ListItemIcon>
                <ListItemText sx={customDrawerStyles.listItemText} primary={subItem.label} primaryTypographyProps={{ fontSize: '12px' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </SubMenuList>
      </SubMenuContainer>} */}
      <Box component="main" sx={{ flexGrow: 1, paddingTop: 0, height: "100%" }}>
        <DrawerHeader />
        <div className="top-div">
          <div>
            {role && visitedPaths && <MarqeeNavPath visitedPaths={visitedPaths} bannerMessage={bannerMessage} />}
          </div>
          <div className="active-user-content-footer-holder">
            <div className="active-user-content-holder">
              <Routes>
                <Route path="/home"
                  element={<CricHome role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
              </Routes>
            </div>
            <div className="cric-footer-container">
              {role && <CricFooter role={role} />}
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
