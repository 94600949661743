import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import TokenService from '../../services/token-service';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

export default function MUIPPastEventExposure3({ role, logout }) {
    console.log("show", role);
    const { state } = useLocation();
    const { userId, gameId } = state ? state : "";

    const getEventExposure = "/alpha/getEventExposure?agentId=" + userId + "&gameId=" + gameId;
    const api = httpHelpers();
    const [eventExposure, setEventExposure] = useState(null);
    let navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - eventExposure.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchEventExposure = () => {
        api
            .get(`${getEventExposure}`)
            .then(res => {
                console.log("event exposure", res);
                if (res && res.data && res.data.length > 0) {
                    setEventExposure(res.data);
                } else {
                    setEventExposure(null);
                }
            })
            .catch(err => {
                console.log(err.response.status);
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    const handleImmediateChildren = (e, child) => {
        e.preventDefault();
        const userId = child.userId;
        navigate('/eeagent', { state: { userId, gameId } });
    };

    useEffect(() => {
        fetchEventExposure();
    }, []);

    const ButtonStyle = { margin: "0px 10px" };

    const Row = ({ child }) => {
        return (
            <tr>
                <td className="master-list-title-td">
                    <Button appearance="link"
                        onClick={(e) => handleImmediateChildren(e, child)}>{child.userId}</Button>
                </td>
                <td className="master-list-title-td-past-game-title">{child.title}</td>
                {child.profitLoss < 0 && <td className="master-list-title-td-red">-({child.profitLoss})</td>}
                {child.profitLoss === 0 && <td className="master-list-title-td-red">({child.profitLoss})</td>}
                {child.profitLoss > 0 && <td className="master-list-title-td-green">{child.profitLoss}</td>}
                {(child.profitLoss * (-1)) < 0 && <td className="master-list-title-td-red">-({child.profitLoss})</td>}
                {(child.profitLoss * (-1)) === 0 && <td className="master-list-title-td-red">({child.profitLoss})</td>}
                {(child.profitLoss * (-1)) > 0 && <td className="master-list-title-td-green">{child.profitLoss}</td>}
                <td className="master-list-title-td">
                    {child.gameStatus === "COMPLETED" && <label className="label-status-active">{child.gameStatus}</label>}
                    {child.gameStatus === "CANCELLED" && <label className="label-status-blocked">{child.gameStatus}</label>}
                </td>
            </tr>
        )
    };

    return (
        <div>
            <div className="table-top-container">
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th">User Name</th>
                                        <th className="master-list-title-th-past-game-title">Title</th>
                                        <th className="master-list-title-th">P/L</th>
                                        <th className="master-list-title-th">Downline P/L</th>
                                        <th className="master-list-title-th">Status</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tbl-content">
                            <table>
                                <tbody>
                                    {eventExposure && (rowsPerPage > 0
                                        ? eventExposure.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : eventExposure
                                    ).map((child, index) =>
                                        <Row
                                            child={child}
                                            key={index}
                                            index={index} />)}
                                    {emptyRows > 0 && (
                                        <tr style={{ height: 34 * emptyRows }}>
                                            <td colSpan={3} aria-hidden />
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {eventExposure && eventExposure.length > rowsPerPage && <div>
                <div className="table-pagination-container">
                    <div className="table-pagination-container-child">
                        <CustomTablePagination
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            rowsPerPageOptions={10}
                            colSpan={3}
                            count={eventExposure && eventExposure.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                                select: {
                                    'aria-label': 'rows per page',
                                },
                                actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

const blue = {
    50: '#48aaad',
    200: '#48aaad',
    400: '#48aaad',
    900: '#48aaad',
};

const grey = {
    50: '#E5E4E2',
    100: '#C0C0C0',
    200: '#E5E4E2',
    300: '#C0C0C0',
    400: '#C0C0C0',
    500: '#C0C0C0',
    600: '#C0C0C0',
    700: '#C0C0C0',
    800: '#C0C0C0',
    900: '#C0C0C0',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #48aaad;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);