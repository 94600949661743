import React from 'react';
import '../css/BetSlip.css';

const HeaderDetailComponent = ({ headerTitle, detailContent }) => {
    return (
        <div className="header-detail-container">
            <div className="header">
                {headerTitle}
            </div>
            <div className='separator'></div>
            <div className="detail">
                {detailContent}
            </div>
        </div>
    );
}

export default HeaderDetailComponent;
