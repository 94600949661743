import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout, changePasswordProfile } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";
import TableTitle from "./customized/TableTitle.js";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const CricChangeMyPassword = ({ role, logout }) => {

    const form = useRef();
    const checkBtn = useRef();

    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const onChangeOldPassword = (e) => {
        const password = e.target.value;
        setOldPassword(password);
    };

    const [input, setInput] = useState({
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        password: '',
        confirmPassword: ''
    });

    const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            // console.log("Password", name, value);
            switch (name) {
                case "password":
                    if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    } else if (input.confirmPassword && value === input.confirmPassword) {
                        stateObj[name] = "";
                    } else {
                        stateObj[name] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    setPassword(value)
                    break;

                case "confirmPassword":
                    if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    } else if (input.password && value === input.password) {
                        stateObj[name] = "";
                    } else {
                        stateObj[name] = input.password ? "" : error.password;
                    }
                    setConfirmPassword(value)
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    }

    const handleChangeMyPassword = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log("change self pwd", role);
            dispatch(changePasswordProfile(oldPassword, password, role))
                .then((data) => {
                    console.log("change self pwd data: ", data);
                    if (data.status === 401) {
                        logout();
                    } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                        setSuccessful(false);
                    } else if (data.status === 200) {
                        setSuccessful(true);
                    }
                })
                .catch(() => {
                });
        }
    };

    const handleAddMore = () => {
        setPassword("");
        setConfirmPassword("");
        setSuccessful(false);
    }

    return (
        <div className="cric-change-password-top-container">
            <div className="register-container">
                {successful && (<div className="form-group">
                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert" style={{ marginTop: "20px" }}>
                        Password is Changed Successfully!
                    </div>
                </div>)}
                {!successful && message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}
                {!successful && <div className="cric-card">
                    {!successful && <div>
                        {/* <label className="cric-register-header">Change Password</label> */}
                        <TableTitle
                            text="Change Password"
                            color="#ffffff"
                            fontSize="14px"
                            textAlign="left"
                            width="100%"
                            height="42px"
                            marginLeft="0px"
                            marginRight="0px"
                            paddingLeft="10px"
                        />
                    </div>}
                    <div className="cric-card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}

                        <Form onSubmit={handleChangeMyPassword} ref={form}>
                            {!successful && (
                                <div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container-add">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="oldpassword">OLD PASSWORD</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="OLD PASSWORD"
                                                    id="reg_oldpassword"
                                                    type="password"
                                                    className="form-control"
                                                    name="oldpassword"
                                                    value={oldPassword}
                                                    onChange={onChangeOldPassword}
                                                    validations={!successful && [required, vpassword]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container-add">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="password">NEW PASSWORD</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="NEW PASSWORD"
                                                    id="reg_password"
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    value={password}
                                                    onChange={onInputChange}
                                                    onBlur={validateInput}
                                                    validations={!successful && [required, vpassword]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container-add">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="confirmPassword">CONFIRM PASSWORD</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="CONFIRM PASSWORD"
                                                    id="reg_confirmpassword"
                                                    type="password"
                                                    className="form-control"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={onInputChange}
                                                    onBlur={validateInput}
                                                    validations={!successful && [required, vpassword]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-medium">Change Password</button>
                                    </div> */}
                                    <div className="form-buttons-cancel-submit">
                                        <div className="form-group">
                                            <button className="board-buttons board-buttons-nav-bar-dark-small-save-changes">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>
                </div>}
                {(message || successful) && (<div className="form-group">
                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                        {message ? message : ("Password Changed Successfully!")}
                    </div>
                </div>)}
            </div>
        </div>
    );
};

export default CricChangeMyPassword;