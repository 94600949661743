import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/system';

const CustomPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-spacer': {
    flex: 'none',
  },
  '& .MuiTablePagination-toolbar': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    margin: 0,
  },
  '& .MuiTablePagination-select': {
    marginRight: '16px',
    marginLeft: '8px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '4px 8px',
    backgroundColor: theme.palette.background.paper,
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiTablePagination-actions': {
    display: 'flex',
    gap: '8px',
  },
}));

const CustomTablePagination = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
  return (
    <CustomPagination
      component="div"
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default CustomTablePagination;
