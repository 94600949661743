import React, { useState } from 'react';
import { Box, Typography, MenuItem, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBack from './ArrowBack'; // Custom back arrow
import ArrowForward from './ArrowForward'; // Custom forward arrow

const CustomDropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <Box position="relative" width="fit-content">
      <Box
        onClick={handleToggle}
        style={{
          fontSize: '12px',
          fontWeight: '300',
          color: '#333',
          border: '1px solid #333',
          borderRadius: '4px',
          padding: '2px 6px',
          textAlign: 'center',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          width: '44px', // Adjust width as needed
          boxSizing: 'border-box',
        }}
      >
        {value}
        <ArrowDropDownIcon style={{ marginLeft: '4px', fontSize: '16px' }} />
      </Box>
      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          zIndex="1000"
          style={{
            border: '1px solid lightgrey',
            borderRadius: '4px',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            width: '100%',
            boxSizing: 'border-box',
            maxHeight: '150px',
            overflowY: 'auto',
            marginTop: '4px',
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleSelect(option)}
              style={{
                padding: '2px 12px',
                fontSize: '12px',
                fontWeight: '300',
                textAlign: 'center',
                cursor: 'pointer',
                color: '#333',
                backgroundColor: 'transparent', // Ensure no background color
                '&:hover': {
                  backgroundColor: '#f5f5f5 !important', // Ensure hover background color
                },
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Box>
      )}
    </Box>
  );
};

const StyledTablePagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box marginTop="50px" marginBottom="50px" display="flex" flexDirection="column">
      {/* Rows per page selector */}
      {/* <Box display="flex" alignItems="center" marginBottom="10px">
        <Typography variant="body2" component="div" style={{ marginRight: 8, lineHeight: 'normal' }}>
          Rows per page:
        </Typography>
        <CustomDropdown
          options={[10, 25, 50]}
          value={rowsPerPage}
          onChange={(newRowsPerPage) => onRowsPerPageChange({ target: { value: newRowsPerPage } })}
        />
      </Box> */}

      {/* Custom Pagination Controls */}
      <Box display="flex" alignItems="center" justifyContent="flex-start" padding={0} margin={0}>
        <Button
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          style={{
            fontSize: '12px',
            fontWeight: '300',
            textTransform: 'none',
            color: page === 0 ? '#333' : '#0087bd',
            backgroundColor: 'transparent', // Ensure no background color
            border: 'none', // Remove border
            padding: '4px 0px', // Adjust padding
            minWidth: 'auto', // Ensure button fits content
            '&:hover': {
              backgroundColor: 'transparent', // Ensure no background on hover
              color: '#333', // Set hover color
            },
            '&:focus': {
              backgroundColor: 'transparent', // Ensure no background on focus
              color: '#333', // Set focus color
            },
          }}
        >
          <ArrowBack
            style={{
              width: '16px',
              height: '8px',
              strokeWidth: '1.5px', // Thin line for the arrow
            }}
          />
          <Typography variant="body2" style={{ marginLeft: 4 }}>
            Previous
          </Typography>
        </Button>

        {/* Page numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={(event) => onPageChange(event, index)}
            style={{
              fontSize: '14px',
              fontWeight: index === page ? '300' : 'normal',
              fontStyle: index === page ? 'italic' : 'normal',
              color: index === page ? '#333' : '#0087bd', // Charcoal color for selected, Indigo for unselected
              margin: '0 2px',
              padding: '1px 1px', // Adjust padding
              minWidth: 'auto', // Ensure button fits content
              backgroundColor: 'transparent', // Ensure no background color
              border: 'none', // Remove border
              '&:hover': {
                backgroundColor: 'transparent', // Ensure no background on hover
                color: '#333', // Set hover color
              },
              '&:focus': {
                backgroundColor: 'transparent', // Ensure no background on focus
                color: '#333', // Set focus color
              },
            }}
          >
            {index + 1}
          </Button>
        ))}

        <Button
          onClick={handleNextButtonClick}
          disabled={page >= totalPages - 1}
          aria-label="next page"
          style={{
            fontSize: '12px',
            fontWeight: '300',
            textTransform: 'none',
            color: page >= totalPages - 1 ? '#333' : '#0087bd',
            backgroundColor: 'transparent', // Ensure no background color
            border: 'none', // Remove border
            padding: '4px 8px', // Adjust padding
            minWidth: 'auto', // Ensure button fits content
            '&:hover': {
              backgroundColor: 'transparent', // Ensure no background on hover
              color: '#333', // Set hover color
            },
            '&:focus': {
              backgroundColor: 'transparent', // Ensure no background on focus
              color: '#333', // Set focus color
            },
          }}
        >
          <Typography variant="body2" style={{ marginRight: 4 }}>
            Next
          </Typography>
          <ArrowForward
            style={{
              width: '16px',
              height: '8px',
              strokeWidth: '1.5px', // Thin line for the arrow
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default StyledTablePagination;
