import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";
import { MURGA } from './common/constants';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const GameStart = ({ role, logout }) => {

    const { state } = useLocation();
    const { games } = state ? state : "";
    console.log("game start", games);

    const form = useRef();
    const checkBtn = useRef();

    const [gameId, setGameId] = useState(games.id);
    const [title, setTitle] = useState(games.title);
    const [date, setDate] = useState(games.date);
    const [startTime, setStartTime] = useState(games.startTime);
    const [player1, setPlayer1] = useState(games.player1);
    const [player2, setPlayer2] = useState(games.player2);
    const [gameStatus, setGameStatus] = useState(games.gameStatus);
    const [streamLink, setStreamLink] = useState();
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeStreamLink = (e) => {
        const link = e.target.value;
        setStreamLink(link);
    };

    const getPlayableStreamLink = (link) => {
        if (link.includes("iframe")) {
            let startIndex = link.indexOf("src=\"") + 5;
            let endIndex = link.indexOf("\" title=");
            const embedLink = link.substring(startIndex, endIndex) + "?autoplay=1";
            console.log("embedLink", embedLink);
            return embedLink;
        } else {
            const linkArray = link.split("?");
            const embedLink = linkArray[0].replace("/live/", "/embed/") + "?autoplay=1";
            console.log("embedlink", embedLink);
            return embedLink;
        }
    }

    const handleStartGame = (e) => {
        e.preventDefault();
        const sportId = MURGA;
        let link = streamLink;
        const embedLink = getPlayableStreamLink(link);
        console.log("embedlink", embedLink);
        navigate('/livematches', { state: { embedLink, games, sportId } });
    };

    return (
        <div className="register-top-container">
            <div className="register-container">
                <div className="card" style={{ marginTop: 90 }}>
                    {!successful && <div>
                        <label className="register-header">Start Game</label>
                    </div>}
                    <div className="card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}

                        <Form onSubmit={handleStartGame} ref={form}>
                            {!successful && (
                                <div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="title">Title</label>
                                        </div>
                                        <Input
                                            id="reg_title"
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            value={title}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="date">Date</label>
                                        </div>
                                        <Input
                                            id="reg_date"
                                            type="text"
                                            className="form-control"
                                            name="date"
                                            value={date}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="starttime">Start Time</label>
                                        </div>
                                        <Input
                                            id="reg_starttime"
                                            type="text"
                                            className="form-control"
                                            name="starttime"
                                            value={startTime}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="player1">Player 1</label>
                                        </div>
                                        <Input
                                            id="reg_player1"
                                            type="text"
                                            className="form-control"
                                            name="player1"
                                            value={player1}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="player2">Player 2</label>
                                        </div>
                                        <Input
                                            id="reg_player2"
                                            type="text"
                                            className="form-control"
                                            name="player2"
                                            value={player2}
                                            disabled
                                            style={{ backgroundColor: "#fff1d7" }}
                                        />
                                    </div>
                                    <div className="hr-solid"></div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="streamlink">Stream Link</label>
                                        </div>
                                        <Input
                                            id="reg_streamlink"
                                            type="text"
                                            className="form-control"
                                            name="streamlink"
                                            value={streamLink}
                                            onChange={onChangeStreamLink}
                                            onPasteCapture={onChangeStreamLink}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-small">Start Game</button>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameStart;