import React from 'react';
import './ExpandableTable.css';
import AddClass from './AddClass';

const ExpandableTable = ({ data }) => {
    return (
        <div className="expandable-table">
            <table>
                <thead>
                    <tr>
                        {data.headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <AddClass />
            </div>
        </div>
    );
};

export default ExpandableTable;
