import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import { SET_MESSAGE } from "../../actions/types";
import TableTitle from "./customized/TableTitle.js";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  const re = /^\S*$/;
  if (!re.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        User Name can not contain spaces.
      </div>
    );
  } else if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const validPhone = (value) => {
  console.log(value)
  if (!isMobilePhone(value) || value.length < 10) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid phone.
      </div>
    );
  }
};

const validCommission = (value) => {
  console.log(value)
  if (value > 100 || value < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter in valid range (0 - 100).
      </div>
    );
  }
};

const CricRegister = ({ role, logout, isSmallScreen }) => {
  let registerBy = role;
  const user = TokenService.getUser();

  if (user === null) {
    logout();
  }

  const href = window.location.href;
  let formHeader = "User";
  let regType = "User ";
  let registerType = "user";
  if (href.includes("registersm")) {
    formHeader = "Sub Master";
    regType = "SM ";
    registerType = "submaster";
  } else if (href.includes("registersc")) {
    formHeader = "Sub Company";
    regType = "SC ";
    registerType = "subcompany";
  } else if (href.includes("registersst")) {
    formHeader = "Super Stockist";
    regType = "SST ";
    registerType = "superstockist";
  } else if (href.includes("registerst")) {
    formHeader = "Stockist";
    regType = "Stockist ";
    registerType = "stockist";
  } else if (href.includes("registeragent")) {
    formHeader = "Agent";
    regType = "Agent ";
    registerType = "agent";
  }

  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [yourPassword, setYourPassword] = useState("");
  const [myMatchShare, setMyMatchShare] = useState(registerType.includes("user") ? user.share : "");
  const [agentMatchShare, setAgentMatchShare] = useState();
  const [agentMatchCommission, setAgentMatchCommission] = useState();
  const [agentSessionCommission, setAgentSessionCommission] = useState();
  const [fixLimit, setFixLimit] = useState();
  const [creditReference, setCreditReference] = useState("");
  const [exposureLimit, setExposureLimit] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [newCreatedUserName, setNewCreatedUserName] = useState(null);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    dispatch(clearMessage());
  }, [])

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const onChangeFirstName = (e) => {
    const fname = e.target.value;
    setFirstName(fname);
  };

  const onChangeLastName = (e) => {
    const lname = e.target.value;
    setLastName(lname);
  };

  const onChangeUserName = (e) => {
    const username = e.target.value;
    setUserName(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };

  const onChangeYourPassword = (e) => {
    const yp = e.target.value;
    setYourPassword(yp);
  };

  const onChangeMyMatchShare = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      if (Number(newValue) > Number(user.share)) {
        setMyMatchShare(user.share);
      } else if (Number(newValue) < 0) {
        setMyMatchShare(0);
      } else {
        setMyMatchShare(newValue);
      }
    } else {
      setMyMatchShare(0);
    }
  };

  useEffect(() => {
    setAgentMatchShare(Number(user.share) - Number(myMatchShare));
  }, [myMatchShare])

  const onChangeAgentMatchShare = (e) => {
    const ams = e.target.value;
    setAgentMatchShare(ams);
  };

  const onChangeAgentMatchCommission = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      if (Number(newValue) > Number(user.matchCommission)) {
        setAgentMatchCommission(Number(user.matchCommission));
      } else if (Number(newValue) < 0) {
        setAgentMatchCommission(0);
      } else {
        setAgentMatchCommission(newValue);
      }
    } else {
      setAgentMatchCommission('');
    }
  };

  const onChangeAgentSessionCommission = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      if (Number(newValue) > Number(user.sessionCommission)) {
        setAgentSessionCommission(user.sessionCommission);
      } else if (Number(newValue) < 0) {
        setAgentSessionCommission(0);
      } else {
        setAgentSessionCommission(newValue);
      }
    } else {
      setAgentSessionCommission('');
    }
  };

  const onChangeFixLimit = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (/^\d+$/.test(newValue))) {
      if (Number(newValue) > Number(user.balance)) {
        setFixLimit(user.balance);
      } else if (Number(newValue) < 0) {
        setFixLimit(0);
      } else {
        setFixLimit(newValue);
      }
    } else {
      setFixLimit('');
    }
  };

  const onChangeCreditReference = (e) => {
    const cr = e.target.value;
    setCreditReference(cr);
  };

  const onChangeExposureLimit = (e) => {
    const el = e.target.value;
    setExposureLimit(el);
  };

  const [input, setInput] = useState({
    password: '',
    confirmPassword: ''
  });

  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  });

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
      // console.log("Password", name, value);
      switch (name) {
        case "password":
          if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj[name] = "Password and Confirm Password does not match.";
          } else if (input.confirmPassword && value === input.confirmPassword) {
            stateObj[name] = "";
          } else {
            stateObj[name] = input.confirmPassword ? "" : error.confirmPassword;
          }
          setPassword(value)
          break;

        case "confirmPassword":
          if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          } else if (input.password && value === input.password) {
            stateObj[name] = "";
          } else {
            stateObj[name] = input.password ? "" : error.password;
          }
          setConfirmPassword(value)
          break;
        default:
          break;
      }
      return stateObj;
    });
  }

  const cancel = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const handleRegister = (e) => {
    dispatch(clearMessage());
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      console.log("register user", role, firstName, lastName, password, registerType, fixLimit, agentMatchShare, agentMatchCommission, agentSessionCommission);
      dispatch(register(firstName, lastName, password, registerType, fixLimit, agentMatchShare, agentMatchCommission, agentSessionCommission, role))
        .then((data) => {
          console.log("reg data: ", data);
          if (data.status === 401) {
            if (data.data === "Wrong password") {
              setSuccessful(false);
            } else {
              logout();
            }
          } else if (data.status === 200) {
            setNewCreatedUserName(data.data.userId);
            setSuccessful(true);
            let msg = "New User " + data.data.userId + " is Created Successfully!";
            console.log("new user", registerType);
            if (registerType.includes("user")) {
              // navigate(`/showuser/${data.data.userId}/`);
              navigate('/clients', { state: { msg } });
            } else if (registerType.includes("submaster")) {
              // navigate(`/showsc/${data.data.userId}/`);
              navigate('/showsm', { state: { msg } });
            } else if (registerType.includes("subcompany")) {
              // navigate(`/showsc/${data.data.userId}/`);
              navigate('/showsc', { state: { msg } });
            } else if (registerType.includes("superstockist")) {
              // navigate(`/showsst/${data.data.userId}/`);
              navigate('/showsst', { state: { msg } });
            } else if (registerType.includes("stockist")) {
              // navigate(`/showst/${data.data.userId}/`);
              navigate('/showst', { state: { msg } });
            } else if (registerType.includes("agent")) {
              // navigate(`/showagent/${data.data.userId}/`);
              navigate('/showagent', { state: { msg } });
            }
          } else {
            setSuccessful(false);
            dispatch({
              type: SET_MESSAGE,
              payload: data.data,
              role: role
            });
          }
        })
        .catch(() => {
        });
    }
  };

  const handleAddMore = () => {
    setFirstName("");
    setLastName("");
    setUserName("");
    setPassword("");
    setConfirmPassword("");
    setMyMatchShare("");
    setAgentMatchShare("");
    setFixLimit("");
    setAgentMatchCommission("");
    setAgentSessionCommission("");
    setSuccessful(false);
    setNewCreatedUserName(null);
  }

  const [value, setValue] = useState('');

  // const handleChange = (e) => {
  //   const newValue = e.target.value;
  //   if (newValue === '' || (/^\d+$/.test(newValue))) {
  //     if (Number(newValue) > 100) {
  //       setValue('100');
  //     } else {
  //       setValue(newValue);
  //     }
  //   }
  // };

  // const handleBlur = () => {
  //   if (value !== '' && Number(value) > 100) {
  //     setValue('100');
  //   }
  // };

  return (
    <div className="cric-register-top-container">
      <div className="register-container">
        <div className="cric-card">
          {!successful && <div>
            {/* <label className="cric-register-header">Add {regType}</label> */}
            <TableTitle
              text={"New " + formHeader}
              color="#ffffff"
              fontSize="14px"
              textAlign="left"
              width="100%"
              height="42px"
              marginLeft="0px"
              marginRight="0px"
              paddingLeft="10px"
            />
          </div>}
          <div className="cric-card-container">
            {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
            {newCreatedUserName && <label style={{ marginTop: "250px", fontSize: "30px", fontWeight: "bold" }}>{newCreatedUserName}</label>}
            {(message || successful) && (<div className="form-group" style={{ marginTop: "10px" }}>
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {message ? message : ("New User " + newCreatedUserName + " is Created Successfully!")}
              </div>
            </div>)}
            {successful && (<div>
              <button className="board-buttons board-buttons-nav-bar-dark-small" onClick={() => handleAddMore()}>Add More</button>
            </div>)}

            <Form onSubmit={handleRegister} ref={form}>
              {!successful && (
                <div>
                  {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                  {/* <input
                    type="tel"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />   */}
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="name">First Name</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="First Name"
                          id="reg_fname"
                          type="text"
                          className="form-control"
                          name="fname"
                          maxLength="20"
                          value={firstName || ""}
                          onChange={onChangeFirstName}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="name">Last Name</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="Last Name"
                          id="reg_lname"
                          type="text"
                          className="form-control"
                          name="lname"
                          maxLength="20"
                          value={lastName || ""}
                          onChange={onChangeLastName}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="openingbalance">Fix Limit</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="0"
                          type="tel"
                          id="reg_flimit"
                          className="form-control"
                          name="openingbalance"
                          value={fixLimit}
                          onChange={onChangeFixLimit}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                          maxLength={(user.balance.toString().length).toString()}
                        />
                        <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>Fix Limit can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.balance}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>My Match Share can be set from 0 to</label>
                  </div>
                  <div className="div-note-2">
                    <label>95</label>
                  </div>
                </div> */}
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="share">My Match Share</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="Enter Share"
                          type="tel"
                          id="reg_mmshare"
                          className="form-control"
                          name="share"
                          value={registerType.includes("user") ? user.share : myMatchShare}
                          onChange={onChangeMyMatchShare}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px", color: "#676a6c", backgroundColor: registerType.includes("user") ? "#feefee" : "white" }}
                          disabled={registerType.includes("user")}
                          maxLength={(user.share.toString().length).toString()}
                        />
                        <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>My Match Share can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.share}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!registerType.includes("user") &&
                    <div className="form-group">
                      <div className="cric-form-label-input-container-add">
                        <div className="cric-div-label-form">
                          <label className="label-form" htmlFor="share">{regType} Match Share</label>
                        </div>
                        <div className="cric-div-input-form">
                          <input
                            placeholder="Enter Partnership"
                            type="tel"
                            id="reg_amshare"
                            className="form-control"
                            name="ams"
                            value={agentMatchShare}
                            disabled
                            style={{ backgroundColor: "#feefee", fontSize: "14px", color: "#999999" }}
                          />
                          <div className="div-note">
                            <label className="label-note">Note :</label>
                            <div className="div-note-1">
                              <label>{regType} Match Share can be set from 0 to</label>
                            </div>
                            <div className="div-note-2">
                              <label>{user.share - myMatchShare}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="share">{regType} Match Commission</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder={formHeader + " Match Commission"}
                          type="tel"
                          id="reg_amc"
                          className="form-control"
                          name="amc"
                          value={agentMatchCommission}
                          onChange={onChangeAgentMatchCommission}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                          maxLength={(user.matchCommission.toString().length).toString()}
                        />
                        <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>{regType} Match Commission can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.matchCommission}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="share">{regType} Session Commission</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder={"Enter " + formHeader + " Session Commission"}
                          type="tel"
                          id="reg_asc"
                          className="form-control"
                          name="asc"
                          value={agentSessionCommission}
                          onChange={onChangeAgentSessionCommission}
                          validations={!successful && [required]}
                          style={{ fontSize: "14px" }}
                          maxLength={(user.sessionCommission.toString().length).toString()}
                        />
                        <div className="div-note">
                          <label className="label-note">Note :</label>
                          <div className="div-note-1">
                            <label>{regType} Session Commission can be set from 0 to</label>
                          </div>
                          <div className="div-note-2">
                            <label>{user.sessionCommission}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                <div className="div-label-form">
                  <label className="label-form" htmlFor="sessioncommission">
                    {regType} Session Commission
                  </label>
                </div>
                <Input
                  type="tel"
                  id="reg_sessioncommission"
                  className="form-control"
                  name="sessioncommision"
                  value={sessionCommission}
                  onChange={onChangeSessionCommission}
                  validations={!successful && [required]}
                />
                <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>
                      {regType} Session Commission can be set from 0 to
                    </label>
                  </div>
                  <div className="div-note-2">
                    <label>3</label>
                  </div>
                </div>
              </div> */}
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="password">Password</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="Enter Password"
                          id="reg_password"
                          type="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={onInputChange}
                          onBlur={validateInput}
                          validations={!successful && [required, vpassword]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cric-form-label-input-container-add">
                      <div className="cric-div-label-form">
                        <label className="label-form" htmlFor="confirmPassword">Confirm Password</label>
                      </div>
                      <div className="cric-div-input-form">
                        <Input
                          placeholder="Confirm Password"
                          id="reg_confirmpassword"
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={onInputChange}
                          onBlur={validateInput}
                          validations={!successful && [required, vpassword]}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="hr-solid"></div>
                  <div className="form-group">
                    <div className="div-label-form">
                      <label className="label-form" htmlFor="yourpassword">Your Password</label>
                    </div>
                    <Input
                      id="reg_yourpassword"
                      type="password"
                      className="form-control"
                      name="yourpassword"
                      value={yourPassword}
                      onChange={onChangeYourPassword}
                      validations={!successful && [required, vpassword]}
                    />
                  </div> */}
                  <div className="form-buttons-cancel-submit">
                    <button className="board-buttons board-buttons-nav-bar-dark-smaller-white"
                      onClick={(e) => cancel(e)}>Cancel</button>
                    <div className="form-group">
                      <button className="board-buttons board-buttons-nav-bar-dark-small-save-changes">Save Changes</button>
                    </div>
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CricRegister;