// Breadcrumbs.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './App.css';

// Path-to-name mapping
const breadcrumbNameMap = {
    '/home': 'Home',
    '/showsm': 'SM',
    '/showsc': 'SC',
    '/showsst': 'SST',
    '/showst': 'Stockists',
    '/showagent': 'Agent',
    '/registersm': 'Create Sub Manager',
    '/registersc': 'Create Sub Company',
    '/registersst': 'Create Super Stockist',
    '/registerst': 'Create Stockist',
    '/registeragent': 'Create Agent',
    '/update': 'Edit',
    '/userpassword': 'Change Password',
    '/matcheslist': 'Matches',
    '/matchesdashboard': 'Matches',
    '/icdashboard': 'Agent Dashboard',
    '/receivecash': 'Receive Cash',
    '/paycash': 'Pay Cash',
    '/eledger': 'Ledger',
    '/directagents': 'Direct Agents',
    '/directclients': 'Direct Clients',
    '/compmatcheslist': 'Matches',
    '/matchscreen': 'Matches',
    '/betslipmatch': 'Match Bet Slips',
    '/blockmarket': 'Sports List',
    '/clients': 'CLIENTS',
    '/registeruser': 'Agent',
    '/blockedclients': 'Blocked Clients',
    '/commissionlimits': 'Commission & Limits',
    '/settings': 'Admin Settings',
    '/collectionreport': 'Collection Report',
    '/estatement': 'Statement',
    '/changemypassword': 'Manage Password',
    '/changepassword': 'Manage Password',
    // Add other mappings as needed
};

// Function to replace dynamic segments with actual values
const replaceDynamicSegments = (path, params) => {
    const regex = /:(\w+)/g; // Match dynamic segments like :id
    return path.replace(regex, (_, key) => params[key] || _);
};

// Function to get the base path (without dynamic segments)
const getBasePath = (path) => {
    return path.split('/').slice(0, -1).join('/') || '/';
};

// Function to format breadcrumb labels and exclude irrelevant segments
const formatBreadcrumbLabel = (segment) => {
    // Custom logic to format breadcrumb labels
    if (segment === 'home') return 'Home';
    if (segment === 'matcheslist') return 'Matches';
    if (segment === 'matchesdashboard') return 'Matches';
    if (segment === 'cricket') return 'Cricket';
    if (segment === 'submaster') return 'Sub Master';
    if (segment === 'subcompany') return 'Sub Company';
    if (segment === 'superstockist') return 'Super Stockist';
    if (segment === 'stockist') return 'Stockist';
    if (segment === 'agent') return 'Agent';
    if (segment === 'user') return 'User';
    // Exclude numeric IDs or other irrelevant segments
    if (/^\d+$/.test(segment)) return ''; // Exclude numeric IDs
    return decodeURIComponent(segment.replace(/%20/g, ' ')); // Decode and format
};

const getCurrentPage = (path) => {
    if (path) {
        if (path.includes("/home")) {
            return "Home";
        }
        if (path.includes("/showsm")) {
            return "SM";
        }
        if (path.includes("/showsc")) {
            return "SC";
        }
        if (path.includes("/showsst")) {
            return "SST";
        }
        if (path.includes("/showst")) {
            return "Stockists";
        }
        if (path.includes("/showagent")) {
            return "Agent";
        }
        if (path.includes("/clients") || path.includes("/commissionlimits")) {
            return "CLIENTS";
        }
        if (path.includes("/registersm")) {
            return "New Sub Manager";
        }
        if (path.includes("/registersc")) {
            return "New Sub Company";
        }
        if (path.includes("/registersst")) {
            return "New Super Stockist";
        }
        if (path.includes("/registerst")) {
            return "New Stockist";
        }
        if (path.includes("/registeragent")) {
            return "New Agent";
        }
        if (path.includes("/registeruser") || path.includes("/receivecash")
            || path.includes("/paycash") || path.includes("/icdashboard")
            || path.includes("/eledger") || path.includes("/betslipmatch")) {
            return "Agent";
        }
        if (path.includes("/matchesdashboard") || path.includes("/matcheslist")
            || path.includes("/compmatcheslist")) {
            return "Matches";
        }
        if (path.includes("/directagents") || path.includes("/directclients")) {
            return "Agents";
        }
        if (path.includes("/estatement")) {
            return "MY LEDGERS";
        }
        if (path.includes("/matchscreen")) {
            return "LIVE MATCH REPORT";
        }
        if (path.includes("/blockmarket")) {
            return "Block Sports";
        }
        if (path.includes("/update")) {
            return "Edit";
        }
        if (path.includes("/blockedclients")) {
            return "Clients";
        }
        if (path.includes("/settings")) {
            return "Admin";
        }
        if (path.includes("/collectionreport")) {
            return "COLLECTION REPORT";
        }
        if (path.includes("/userpassword") || path.includes("/changemypassword") || path.includes("/changepassword")) {
            return "Change Password";
        }
    }
};

const Breadcrumbs = () => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState();

    // Split the pathname into parts
    const pathnames = location.pathname.split('/').filter(x => x);

    // Extract URL params from query (if using query params)
    const queryParams = new URLSearchParams(location.search);
    const params = {}; // Map dynamic params (id, etc.) if needed

    // Populate params map based on path (example: /profile/123)
    pathnames.forEach((part, index) => {
        const routePath = `/${pathnames.slice(0, index + 1).join('/')}`;
        if (breadcrumbNameMap[routePath]) {
            const matches = routePath.match(/:(\w+)/g);
            if (matches) {
                matches.forEach(match => {
                    const key = match.replace(':', '');
                    params[key] = part; // Example: /profile/123 -> params: { id: '123' }
                });
            }
        }
    });

    // Track rendered labels to avoid redundancy
    const renderedLabels = new Set();

    useEffect(() => {
        setCurrentPage(getCurrentPage(location.pathname));
    }, [location])

    return (
        <div>
            <label className="nav-path-current-page">{currentPage}</label>
            <div style={{ flex: "row", paddingTop: "0px", paddingBottom: "0px" }}>
                <nav aria-label="breadcrumb">
                    <ol style={{ listStyle: 'none', display: 'flex', paddingLeft: "0px" }}>
                        <li>
                            <Link className="breadcrumbs-previous-paths" style={{ textDecoration: "none" }}>
                                Home
                            </Link>
                        </li>
                        {pathnames.map((value, index) => {
                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                            const isLast = index === pathnames.length - 1;

                            // Get the base path for the current item
                            const basePath = getBasePath(to);
                            const baseLabel = replaceDynamicSegments(breadcrumbNameMap[basePath] || value, params);

                            const routeName = replaceDynamicSegments(breadcrumbNameMap[to] || value, params);

                            // Format and exclude irrelevant segments
                            const displayLabel = formatBreadcrumbLabel(routeName);
                            if (displayLabel === '' || displayLabel === "Home" || renderedLabels.has(displayLabel)) {
                                return null; // Skip rendering if it's irrelevant or redundant
                            }

                            renderedLabels.add(displayLabel);

                            return (
                                <li key={to} style={{ margin: '0px', padding: '0px' }}>
                                    {!isLast ? (
                                        <>
                                            {/* <Link to={to}>{displayLabel}</Link> */}
                                            <label style={{ color: "lightgray" }}>&nbsp;/&nbsp;</label>
                                            <label className="breadcrumbs-previous-paths">
                                                {displayLabel}
                                            </label>
                                        </>
                                    ) : (
                                        <>
                                            <label style={{ color: "lightgray" }}>&nbsp;/&nbsp;</label>
                                            <label className="breadcrumbs-previous-paths">{displayLabel}</label>
                                        </>
                                    )}
                                </li>
                                // <li key={to} style={{ marginLeft: '10px' }}>
                                //     {!isLast ? (
                                //         <>
                                //             <Link to={to}>{displayLabel}</Link>
                                //             <Link className="breadcrumbs-previous-paths" style={{ textDecoration: "none" }}>
                                //                 {displayLabel}
                                //             </Link>
                                //             <span> / </span>
                                //         </>
                                //     ) : (
                                //         <>
                                //             <span>{displayLabel}</span>
                                //         </>
                                //     )}
                                // </li>
                            );
                        })}
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default Breadcrumbs;
