import React, { useCallback, useEffect, useState } from "react";
import ListSharpIcon from '@mui/icons-material/ListSharp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SportsEsportsSharpIcon from '@mui/icons-material/SportsEsportsSharp';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const AgentSidebarData = [
    {
        title:"My Users",
        icon: <ListSharpIcon />,
        link: "/boardagent"
    },
    {
        title:"Add User",
        icon: <SummarizeIcon />,
        link: "/regagent"
    },
    {
        title:"Statement",
        icon: <SportsEsportsSharpIcon />,
        link: "/statementagent"
    },
    {
        title:"My UPI",
        icon: <AddCircleOutlineIcon />,
        link: "/myupiagent"
    },
    {
        title:"Withdraw",
        icon: <AddCircleOutlineIcon />,
        link: "/withdrawagent"
    }
]