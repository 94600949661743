import React, { useState } from 'react';
import './FirstTable.css';

const FirstTable = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [formValue, setFormValue] = useState('');

  const data = [
    { col1: 'Row 1 Col 1', col2: 'Row 1 Col 2', col3: 'Row 1 Col 3', col4: 'Row 1 Col 4' },
    { col1: 'Row 2 Col 1', col2: 'Row 2 Col 2', col3: 'Row 2 Col 3', col4: 'Row 2 Col 4' },
    { col1: 'Row 3 Col 1', col2: 'Row 3 Col 2', col3: 'Row 3 Col 3', col4: 'Row 3 Col 4' },
  ];

  const handleClick = (cellValue) => {
    setSelectedCell(cellValue);
    setFormValue(cellValue);
    setIsFormVisible(true);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with value:", formValue);
    // Add your form submission logic here
    setIsFormVisible(false); // Hide the form after submission
  };

  return (
    <div className="live-match-card-view-table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Header 1</th>
            <th>Header 2</th>
            <th>Header 3</th>
            <th>Header 4</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.col1}</td>
              <td onClick={() => handleClick(row.col2)}>{row.col2}</td>
              <td onClick={() => handleClick(row.col3)}>{row.col3}</td>
              <td>{row.col4}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {isFormVisible && (
        <form className="edit-form" onSubmit={handleFormSubmit}>
          <label>
            Edit Value:
            <input
              type="text"
              value={formValue}
              onChange={(e) => setFormValue(e.target.value)}
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default FirstTable;
