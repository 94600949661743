import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { depositAgent, makeTransaction, withdrawAgent } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import { SET_MESSAGE } from "../../actions/types";
import { DEPOSIT_CASH } from "./common/Constants.js";
import TableTitle from "./customized/TableTitle.js";

const isSmallScreen = window.matchMedia("(max-width: 700px)").matches ? true : false;

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

const PayCash = ({ role, logout }) => {
    let registerBy = role;
    const user = TokenService.getUser();

    if (user === null) {
        logout();
    }

    const { state } = useLocation();

    if (state === null) {
        logout();
    }

    const { child } = state ? state : null;
    console.log("receive cash", child);

    const [userId, setUserId] = useState(child ? child.userId : "");
    const [displayName, setDisplayName] = useState(child ? child.displayName : "");
    const [rsExposure, setRsExposure] = useState(child ? child.balance : "");
    const [childType, setChildType] = useState(child ? child.entityType : "");
    const [updateLedger, setUpdateLedger] = useState();
    const [note, setNote] = useState();
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);
    const form = useRef();
    const checkBtn = useRef();

    useEffect(() => {
        dispatch(clearMessage());
    }, [])

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeUpdateLedger = (e) => {
        const newValue = e.target.value;
        if (newValue === '' || (/^\d+$/.test(newValue))) {
            setUpdateLedger(newValue);
        } else {
            setUpdateLedger(0);
        }
    };

    const onChangeNote = (e) => {
        const n = e.target.value;
        setNote(n);
    };

    const handleDeposit = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        dispatch(clearMessage());

        if (checkBtn.current.context._errors.length === 0) {
            console.log("withdraw", role);
            dispatch(makeTransaction(userId, note, DEPOSIT_CASH, updateLedger))
                .then((data) => {
                    console.log("withdraw data: ", data);
                    if (data.status === 401) {
                        if (data.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (data.status === 200) {
                        setSuccessful(true);
                        let msg = "Cash " + updateLedger + " Paid Successfully!";
                        console.log("child", childType);
                        if (childType.includes("user")) {
                            // navigate(`/showuser/${data.data.userId}/`);
                            navigate('/showuser', { state: { msg } });
                        } else if (childType.includes("subcompany")) {
                            // navigate(`/showsc/${data.data.userId}/`);
                            navigate('/showsc', { state: { msg } });
                        } else if (childType.includes("superstockist")) {
                            // navigate(`/showsst/${data.data.userId}/`);
                            navigate('/showsst', { state: { msg } });
                        } else if (childType.includes("stockist")) {
                            // navigate(`/showst/${data.data.userId}/`);
                            navigate('/showst', { state: { msg } });
                        } else if (childType.includes("agent")) {
                            // navigate(`/showagent/${data.data.userId}/`);
                            navigate('/showagent', { state: { msg } });
                        }
                    } else {
                        setSuccessful(false);
                        dispatch({
                            type: SET_MESSAGE,
                            payload: data.data,
                            role: role
                        });
                    }
                })
                .catch(() => {
                });
        }
    };

    return (
        <div className="cric-register-top-container">
            <div className="register-container">
                <div className="cric-card">
                    {!successful && <div>
                        {/* <label className="cric-register-header">Pay Cash To {userId}</label> */}
                        <TableTitle
                            text={"Pay Cash To " + child.userId}
                            color="#ffffff"
                            fontSize="14px"
                            textAlign="left"
                            width={isSmallScreen ? "98%" : "100%" }
                            height="42px"
                            marginLeft="0px"
                            marginRight="0px"
                            paddingLeft="10px"
                        />
                    </div>}
                    <div className="cric-card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
                        {/* {newCreatedUserName && <label style={{ marginTop: "250px", fontSize: "30px", fontWeight: "bold" }}>{newCreatedUserName}</label>} */}
                        {(message || successful) && (<div className="form-group" style={{ marginTop: "10px" }}>
                            <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                {message ? message : ("Cash Received " + rsExposure + " Successfully!")}
                            </div>
                        </div>)}
                        {/* {successful && (<div>
                            <button className="board-buttons board-buttons-nav-bar-dark-small" onClick={() => handleAddMore()}>Add More</button>
                        </div>)} */}

                        <Form onSubmit={handleDeposit} ref={form}>
                            {!successful && (
                                <div>
                                    {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                                    {/* <input
                    type="tel"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />   */}
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container-add">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="name">Agent Name:</label>
                                            </div>
                                            <div className="cric-div-lable-static-info">
                                                {/* <Input
                                                    id="reg_agname"
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    value={userId || ''}
                                                    validations={!successful && [required]}
                                                    style={{ fontSize: "14px", backgroundColor: "#fff1d7" }}
                                                    disabled
                                                /> */}
                                                <label>{displayName}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container-add">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="rsexp">Rs. Exposure:</label>
                                            </div>
                                            <div className="cric-div-lable-static-info">
                                                {/* <Input
                                                    id="reg_rsexp"
                                                    type="text"
                                                    className="form-control"
                                                    name="rsexp"
                                                    value={rsExposure}
                                                    validations={!successful && [required]}
                                                    style={{ fontSize: "14px", backgroundColor: "#fff1d7" }}
                                                    disabled
                                                /> */}
                                                <label>{rsExposure.toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="upledg">Update Ledger</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="0"
                                                    type="tel"
                                                    id="reg_upledg"
                                                    className="form-control"
                                                    name="upledg"
                                                    value={updateLedger}
                                                    onChange={onChangeUpdateLedger}
                                                    validations={!successful && [required]}
                                                    style={{ fontSize: "14px" }}
                                                    maxLength={(child.balance.toString().length).toString()}
                                                />
                                                {/* <div className="div-note">
                                                    <label className="label-note">Note :</label>
                                                    <div className="div-note-1">
                                                        <label>Fix Limit can be set from 0 to</label>
                                                    </div>
                                                    <div className="div-note-2">
                                                        <label>{user.balance}</label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <div className="div-note">
                  <label className="label-note">Note :</label>
                  <div className="div-note-1">
                    <label>My Match Share can be set from 0 to</label>
                  </div>
                  <div className="div-note-2">
                    <label>95</label>
                  </div>
                </div> */}
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="note">Note</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <textarea
                                                    id="reg_note"
                                                    type="text"
                                                    className="form-control"
                                                    name="note"
                                                    maxLength="50"
                                                    value={note}
                                                    onChange={onChangeNote}
                                                    style={{ fontSize: "14px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-small">Save Changes</button>
                                    </div> */}
                                    <div className="form-buttons-cancel-submit">
                                        {/* <button className="board-buttons board-buttons-nav-bar-dark-smaller-white"
                                            onClick={(e) => cancel(e)}>Cancel</button> */}
                                        <div className="form-group">
                                            <button className="board-buttons board-buttons-nav-bar-dark-small-save-changes">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayCash;