import React, { useCallback, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import TokenService from "../../services/token-service";
import whatsapp from '../../assets/whatsapp_48.png';
import '../../App.css';
import './style/Home.css';
import { httpHelpers } from "../../services/httpHelpers";
import { Container, Card, Typography, CardContent, Button } from "@mui/material";

const Home = ({ role, logout }) => {
    console.log("dashboard", role);
    const { state } = useLocation();
    const { child } = state ? state : "";
    const user = child ? child : TokenService.getUser();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [myUserName, setMyUserName] = useState(user ? user.userName : "");
    const [myName, setMyName] = useState(user ? user.name : "");
    const [myLevel, setMyLevel] = useState(user ? user.agentType : "");
    const [openingBalance, setOpeningBalance] = useState(user ? user.openingBalance : "");
    const [availableBalance, setAvailableBalance] = useState(user ? user.availableBalance : "");
    const [exposure, setExposure] = useState(user ? user.exposure : "");
    const [exposureLimit, setMaximumCompanyShare] = useState(user ? user.exposureLimit : "");
    const [share, setShare] = useState(user ? user.share : "");
    const [creditReference, setCreditReference] = useState(user ? user.creditReference : "");
    const [refPL, setRefPL] = useState(user ? user.referenceProfitLoss : "");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        //fetchBalance();
    }, []);

    let url = "alpha/getBalance";
    const api = httpHelpers();

    const fetchBalance = () => {
        setIsLoading(true);
        api
            .get(`${url}`)
            .then(res => {
                console.log("fetch balance res", res.data)
                let role = "admin";
                let temp = TokenService.getUser();
                temp.balance = res.data;
                TokenService.setUser(temp);
                setIsLoading(false); // Set loading state back to false when done fetching
            })
            .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    logout();
                }
            })
    }

    return (
        <div className="home-root">
            <div className="home-top-container">
                <div className="home-container">
                    <div className="home-profile-container">
                        <div className="home-profile-row">
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>USERNAME</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{myUserName}</label>
                                    </div>
                                    <div>
                                        <label className="home-profile-detail-sub-label">{myName}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>LEVEL</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{myLevel}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>OPENING BALANCE</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{openingBalance}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-profile-row">
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>AVAILABLE BALANCE</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{availableBalance}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>EXPOSURE</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{exposure}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>EXPOSURE LIMIT</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{exposureLimit}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-profile-row">
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>SHARE</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{share}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>CREDIT REFERENCE</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{creditReference}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                <div className="home-profile-title-container">
                                    <label>P & L</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{refPL}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
