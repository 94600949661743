import {
  MdDashboard, MdManageAccounts, MdLiveTv,
  MdUpcoming, MdDone, MdPassword, MdPages
} from "react-icons/md";

export const sidebarDataSST = [
  { label: 'Dashboard', icon: <MdDashboard />, link: '/dashboard' },
  { label: 'Change Password', icon: <MdPassword />, link: '/changemypassword' },
  { label: 'Statement', icon: <MdPages />, link: '/statement' },
  {
    label: 'Manage',
    icon: <MdManageAccounts />,
    link: '/manage',
    subItems: [
      { label: 'Stockists', icon: <MdManageAccounts />, link: '/showst' },
      { label: 'Agents', icon: <MdManageAccounts />, link: '/showagent' },
      { label: 'Users', icon: <MdManageAccounts />, link: '/showuser' },
    ],
    // subItems: [
    //   { label: 'Stockists', icon: <MdManageAccounts/>, link: '/registerst' },
    //   { label: 'Agents', icon: <MdManageAccounts/>, link: '/registeragent' },
    //   { label: 'My Clients', icon: <MdManageAccounts/>, link: '/registeruser' }
    // ],
  },
  // {
  //   label: 'Manage Clients',
  //   icon: <MdManageAccounts />,
  //   link: '/manage',
  //   subItems: [
  //     { label: 'My Clients', icon: <MdManageAccounts />, link: '/regusersstkst' },
  //     { label: 'Blocked Clients', icon: <MdManageAccounts />, link: '/stocklists' },
  //     { label: 'Commission & Limits', icon: <MdManageAccounts />, link: '/agents' },
  //   ],
  // },
  // {
  //   label: 'Manage Ledgers',
  //   icon: <MdManageAccounts />,
  //   link: '/manage',
  //   subItems: [
  //     { label: 'Collection Report', icon: <MdManageAccounts />, link: '/sst' },
  //     { label: 'Company Len-Den', icon: <MdManageAccounts />, link: '/stocklists' },
  //     { label: 'In-Out', icon: <MdManageAccounts />, link: '/agents' },
  //     { label: 'P & L', icon: <MdManageAccounts />, link: '/agents' },
  //   ],
  // },
  { label: 'Live Matches', icon: <MdLiveTv />, link: `/sports/${"live"}` },
  { label: 'Upcoming Matches', icon: <MdUpcoming />, link: `/sports/${"upcoming"}` },
  { label: 'Past Matches', icon: <MdDone />, link: `/sports/${"past"}` },
  // Add more sidebar items as needed
];