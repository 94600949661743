import React, { useState } from 'react';
import './AddClass.css';

const AddClass = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleHeaderClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="add-class">
      <div className="add-class-header" onClick={handleHeaderClick}>
        <h3>Add Class</h3>
        <button className="expand-button">
          {isExpanded ? '-' : '+'}
        </button>
      </div>
      {isExpanded && (
        <div className="add-class-content">
          <input type="text" placeholder="Class Name" />
          <input type="text" placeholder="Class Description" />
          <input type="text" placeholder="Class Code" />
          <button className="add-class-button">Add Class</button>
        </div>
      )}
    </div>
  );
};

export default AddClass;
