import * as React from 'react';
// import Table from '@mui/joy/Table';
import { commisionLimits } from './Columns';
import { useState } from "react";
import { styled } from '@mui/system';
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Button, ButtonToolbar } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import TableTitle from '../customized/TableTitle';

export default function CommisionAndLimitsTable({ rows, deposit, withdraw, tableHeader, isUser, message }) {

  let navigate = useNavigate();

  const [table, setTable] = useState(rows);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchInputChange = (e) => {
    const searchVal = e.target.value;
    if (searchVal === "") { setTable(rows); return; }
    const filterBySearch = rows.filter((item) => {
      if (item.userId.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
    })
    setTable(filterBySearch);
  }

  const openStatements = (e, child) => {
    e.preventDefault();
    navigate(`/estatement/`, { state: { child } });
  };

  return (
    // <Table borderAxis="both">
    <div style={{ width: "100%", maxWidth: '100%', minWidth: "700px", paddingBottom: "15px", background: "white" }}>
      {/* <div style={{ width: "100hw", background: "#3963af", color: "#f8f8f8", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px", textAlign: "left" }}>
        {tableHeader}
      </div> */}
      <TableTitle
        text={tableHeader}
        color="#ffffff"
        fontSize="14px"
        textAlign="left"
        width="100%"
        height="42px"
        marginLeft="0px"
        paddingLeft="10px"
      />
      {message && (<div className="form-group" style={{ marginTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      </div>)}
      <div style={{ justifyContent: "left", textAlign: "left", marginLeft: "10px", marginTop: "15px", maxWidth: "200px" }}>
        <input onChange={(e) => onSearchInputChange(e)} className="form-control" placeholder='Search by ID'></input>
      </div>
      <div style={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px" }}>
        <Root>
          <table aria-label="custom pagination table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>
                </th>
                <th colSpan={2} style={{ textAlign: 'center' }}>
                  Client Commission
                </th>
                <th colSpan={2} style={{ textAlign: 'center' }}>
                  Client Limit
                </th>
                <th rowSpan={2}>Action</th>
              </tr>
              <tr>
                <th rowSpan={2}>Client Name</th>
                <th rowSpan={2} style={{ width: "50px" }}>Match Comm.</th>
                <th rowSpan={2} style={{ width: "50px" }}>Ssn Comm.</th>
                <th rowSpan={2} style={{ width: "100px" }}>Current Limit</th>
                <th rowSpan={2}>{isUser ? "Show Expo" : "Down Balance"}</th>
              </tr>
            </thead>
            <tbody>
              {table && (rowsPerPage > 0
                ? table.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : table
              ).map((row, index) =>
                <tr key={row.userId}>
                  <td style={{ width: "80px" }}>{row.userId}</td>
                  <td>
                    <input style={{ width: "40px", height: "30px" }} disabled value={row.matchCommission}></input>
                  </td>
                  <td>
                    <input style={{ width: "40px", height: "30px" }} disabled value={row.sessionCommission}></input>
                  </td>
                  <td><input style={{ background: "white", width: "100px", height: "30px" }} disabled value={row.balance}></input></td>
                  <td style={{ width: "80px" }}><Button color={isUser ? "red" : "blue"} appearance="ghost" size="sm"
                  // onClick={(e) => edit(e, row)} 
                  >
                    {isUser ? "Expo" : "Balance"}
                  </Button></td>
                  <td style={{ width: "160px" }}>
                    <div>
                      <ButtonToolbar style={{ padding: 1 }}>
                        <Button color="green" appearance="ghost" size="xs"
                          onClick={(e) => deposit(e, row)} sx={{ background: "white" }} >
                          D
                        </Button>
                        <Button color="red" appearance="ghost" size="xs"
                          onClick={(e) => withdraw(e, row)} >
                          W
                        </Button>
                        <Button color="orange" appearance="ghost" size="xs"
                        // onClick={(e) => changeUserPassword(e, child)} 
                        >
                          P&L
                        </Button>
                        <Button color="violet" appearance="ghost" size="xs"
                          onClick={(e) => openStatements(e, row)}
                        >
                          STMT
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </td>
                </tr>)}
              {emptyRows > 0 && (
                <tr style={{ height: 34 * emptyRows }}>
                  <td colSpan={3} aria-hidden />
                </tr>
              )}
            </tbody>
            {table && table.length > rowsPerPage && <tfoot>
              <tr>
                <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={table.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      'aria-label': 'rows page',
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                      slots: {
                        firstPageIcon: FirstPageRoundedIcon,
                        lastPageIcon: LastPageRoundedIcon,
                        nextPageIcon: ChevronRightRoundedIcon,
                        backPageIcon: ChevronLeftRoundedIcon,
                      },
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </tr>
            </tfoot>}
          </table>
          {/* </Table> */}
        </Root>
      </div>
    </div>
  );
};

const blue = {
  50: '#F0F7FF',
  200: '#A5D8FF',
  400: '#3399FF',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Root = styled('div')(
  ({ theme }) => `
  border-radius: 2px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  border-right: none;
  overflow: clip;

  table {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.775rem;
    border-collapse: collapse;
    border: none;
    width: 500px;
    margin: -1px;
    background: white;
  }

  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 12px;
    background: #f8f8f8;
    font-weight: bold;
    color: #36454F;
  }

  td {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 8px;
  }

  // tr:nth-of-type(odd) {
  //   background: #f8f8f8;
  // }
  `,
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (orientation: portrait) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin-left: auto;
    margin-top: 3px;
    align-items: center;

    @media (orientation: portrait) {
      margin-left: 30px;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background-color: transparent;
      }
    }
  }
  `,
);
