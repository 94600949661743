import './style/SupportedSports.css';
import MurgaCover from '../../assets/murga.jpg';
import MurgaCharacters from '../../assets/murga_characters.png';
import CricketCover from '../../assets/cricket.jpg';
import CricketCharacters from '../../assets/cricket_characters.png';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    MURGA,
    CRICKET,
    FOOTBALL,
    TENNIS
} from './common/constants';

export default function SupportedSports({ supportedSports }) {
    console.log("supportedSports", supportedSports);
    const { event } = useParams();
    let navigate = useNavigate();
    const goToUpcomingGames = (sportId) => {
        if (event.includes("upcoming")) {
            navigate('/upcomingmatches', { state: { sportId } });
            // navigate(`/upcomingmatches/${sportId}/`);
        } else if (event.includes("past")) {
            navigate('/pastmatches', { state: { sportId } });
            // navigate(`/pastmatches/${sportId}/`);
        } else if (event.includes("live")) {
            if (sportId.includes(MURGA)) {
                navigate('/livematches', { state: { sportId } });
                // navigate(`/livematches/${sportId}/`);
            } else if (sportId.includes(CRICKET)) {
                navigate('/matchlist', { state: { sportId } });
                // navigate(`/matchlist/${sportId}/`);
            }
        }
    }

    return (
        <div className="cric-supported-sports-root">
            <div className="game-card2-container">
                {/* {supportedSports && supportedSports.includes(MURGA) && <a onClick={() => goToUpcomingGames(MURGA)} >
                    <div className="game-card2-root-container">
                        <div className="game-card2">
                            <div className="game-card2-wrapper">
                                <img src={MurgaCover} className="game-card2-cover-image" />
                            </div>
                            <img src={MurgaCharacters} className="game-card2-character" />
                            <div className="game-title-text">Murga Fight</div>
                        </div>
                    </div>
                </a>} */}

                {/* <div className="landing-page2-coming-soon-container">
                    <img src={ComingSoon} />
                </div> */}

                {supportedSports && supportedSports.includes(CRICKET) && <a onClick={() => goToUpcomingGames(CRICKET)} >
                    <div className="game-card2">
                        <div className="game-card2-wrapper">
                            <img src={CricketCover} className="game-card2-cover-image" />
                        </div>
                        {/* <img src={CricketCharacters} className="game-card2-title" /> */}
                        <img src={CricketCharacters} className="game-card2-character" />
                        <div className="game-title-text">Cricket Match</div>
                    </div>
                </a>}

                {/* <div className="landing-page2-coming-soon-container">
                    <img src={ComingSoon} />
                </div> */}

                {/* <a onClick={() => openGamePage(LUCKY_NUMBER)} >
                    <div className="game-card2">
                        <div className="game-card2-wrapper">
                            <img src={LuckyNumberCover} className="game-card2-cover-image" />
                        </div>
                        <img
                            style={{ paddingLeft: "10px", paddingRight: "10px", marginTop: "160px" }}
                            src={LuckyNumberTitle}
                            className="game-card2-title" />
                        <img src={LuckyNumberCharacter} className="game-card2-character" />
                    </div>
                </a> */}
            </div>
        </div>
    );
}