import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

export default function MasterBook3({ role, logout }) {
    console.log("mb 3", role);
    const { state } = useLocation();
    const { childId, gameId, player1, player2 } = state ? state : "";
    const getMasterBook = "/beta/getSubMasterBook?userId=" + childId + "&gameId=" + gameId;
    const api = httpHelpers();
    const [masterBook, setMasterBook] = useState(null);
    let navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - masterBook.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchMasterBook = () => {
        api
            .get(`${getMasterBook}`)
            .then(res => {
                console.log("master book", res);
                if (res && res.data) {
                    setMasterBook(res.data);
                } else {
                    setMasterBook(null);
                }
            })
            .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    useEffect(() => {
        fetchMasterBook();
    }, []);

    const handleChildren = (e, child) => {
        e.preventDefault();
        const childId = child.userId;
        navigate('/mbagent', { state: { childId, gameId, player1, player2 } });
    }

    const Row = ({ child }) => {
        return (
            <tr>
                {child.userType !== "user" && <td className="master-list-title-td">
                    <Button appearance="link"
                        onClick={(e) => handleChildren(e, child)}>{child.userId}</Button>
                </td>}
                {child.userType === "user" && <td className="master-list-title-td">{child.userId}</td>}
                {child.userType === "admin" && <td className="master-list-title-td">Admin</td>}
                {child.userType === "subcompany" && <td className="master-list-title-td">Sub Company</td>}
                {child.userType === "superstockist" && <td className="master-list-title-td">Super Stockist</td>}
                {child.userType === "stockist" && <td className="master-list-title-td">Stockist</td>}
                {child.userType === "agent" && <td className="master-list-title-td">Agent</td>}
                {child.player1Exposure < 0 && <td className="master-list-title-td-red">(-{child.player1Exposure})</td>}
                {child.player1Exposure == 0 && <td className="master-list-title-td-red">({child.player1Exposure})</td>}
                {child.player1Exposure > 0 && <td className="master-list-title-td-green">{child.player1Exposure}</td>}
                {child.player2Exposure < 0 && <td className="master-list-title-td-red">(-{child.player2Exposure})</td>}
                {child.player2Exposure == 0 && <td className="master-list-title-td-red">({child.player2Exposure})</td>}
                {child.player2Exposure > 0 && <td className="master-list-title-td-green">{child.player2Exposure}</td>}
            </tr>
        )
    };

    return (
        <div className="table-top-container">
            <div className="table-container">
                <div className="table-content-container">
                    <div className="tbl-header">
                        <table>
                            <thead>
                                <tr>
                                    <th className="master-list-title-th">User Name</th>
                                    <th className="master-list-title-th">User Type</th>
                                    <th className="master-list-title-th">{player1}</th>
                                    <th className="master-list-title-th">{player2}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                {masterBook && (rowsPerPage > 0
                                    ? masterBook.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : masterBook
                                ).map((child, index) =>
                                    <Row
                                        child={child}
                                        key={index}
                                        index={index} />)}
                                {emptyRows > 0 && (
                                    <tr style={{ height: 34 * emptyRows }}>
                                        <td colSpan={3} aria-hidden />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {masterBook && masterBook.length > rowsPerPage && <div>
                <div className="table-pagination-container">
                    <div className="table-pagination-container-child">
                        <CustomTablePagination
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            rowsPerPageOptions={7}
                            colSpan={3}
                            count={masterBook && masterBook.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                                select: {
                                    'aria-label': 'rows per page',
                                },
                                actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

const blue = {
    50: '#48aaad',
    200: '#48aaad',
    400: '#48aaad',
    900: '#48aaad',
};

const grey = {
    50: '#E5E4E2',
    100: '#C0C0C0',
    200: '#E5E4E2',
    300: '#C0C0C0',
    400: '#C0C0C0',
    500: '#C0C0C0',
    600: '#C0C0C0',
    700: '#C0C0C0',
    800: '#C0C0C0',
    900: '#C0C0C0',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #48aaad;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);