import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import TableHeader from "./TableHeader";
import CollectionReportTable from "./tables/CollectionReportTable";
import { collectionReport } from './tables/Columns';
import TableTitle from "./customized/TableTitle";

export default function CollectionReport({ role, logout, supportedSports, isSmallScreen }) {
    console.log("show", role);
    // const { state } = useLocation();
    // const { embedLink, games, sportId } = state ? state : "";
    const getCollectionReport = "beta/getCollectionReport";
    const api = httpHelpers();
    const [paying, setPaying] = useState(null);
    const [receiving, setReceiving] = useState(null);
    const [clear, setClear] = useState(null);
    const [payingTotal, setPayingTotal] = useState(null);
    const [receivingTotal, setReceivingTotal] = useState(null);
    const [clearTotal, setClearTotal] = useState(null);
    let navigate = useNavigate();

    const fetchCollectionReport = () => {
        api
            .get(`${getCollectionReport}`)
            .then(res => {
                console.log("collection report", res);
                if (res && res.data) {
                    setPaying(res.data.paying);
                    setReceiving(res.data.receiving);
                    setClear(res.data.clear);
                    setPayingTotal(res.data.payingTotal)
                    setReceivingTotal(res.data.receivingTotal)
                    setClearTotal(res.data.clearTotal)
                } else {
                    setPaying(null);
                    setReceiving(null);
                    setClear(null);
                    setPayingTotal(null);
                    setReceivingTotal(null);
                    setClearTotal(null);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        fetchCollectionReport();
    }, []);

    return (
        <div>
            <div className="collection-report-container">
                <div className="collection-container">
                    <div>
                        {/* <TableHeader title={"PAYMENT RECEIVING FROM (Lena Hai)"} fontSize={"13px"} /> */}
                        <TableTitle
                            text="PAYMENT RECEIVING FROM (Lena Hai)"
                            color="#ffffff"
                            fontSize="14px"
                            textAlign="left"
                            width="100%"
                            height="46px"
                            marginLeft="0px"
                            paddingLeft="10px"
                        />
                    </div>
                    <div>
                        {receiving && <CollectionReportTable columns={collectionReport} data={receiving} total={receivingTotal} />}
                    </div>
                </div>
                <div className="collection-container">
                    <div>
                        {/* <TableHeader title={"PAYMENT PAID TO (Dena Hai)"} fontSize={"13px"} /> */}
                        <TableTitle
                            text="PAYMENT PAID TO (Dena Hai)"
                            color="#ffffff"
                            fontSize="14px"
                            textAlign="left"
                            width="100%"
                            height="46px"
                            marginLeft="0px"
                            paddingLeft="10px"
                        />
                    </div>
                    <div>
                        {paying && <CollectionReportTable columns={collectionReport} data={paying} total={payingTotal} />}
                    </div>
                </div>
                <div className="collection-container">
                    <div>
                        {/* <TableHeader title={"PAYMENT CLEAR (Clear Hai)"} fontSize={"13px"} /> */}
                        <TableTitle
                            text="PAYMENT CLEAR (Clear Hai)"
                            color="#ffffff"
                            fontSize="14px"
                            textAlign="left"
                            width="100%"
                            height="46px"
                            marginLeft="0px"
                            paddingLeft="10px"
                        />
                    </div>
                    <div>
                        {clear && <CollectionReportTable columns={collectionReport} data={clear} total={clearTotal} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
