import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

export default function MasterDownlineTableLite({ role, logout, open, isSmallScreen }) {
    console.log("show", role);
    const href = window.location.href;
    let agentType = "user";
    let addType = "User";
    if (href.includes("showsc")) {
        agentType = "subcompany";
        addType = "SC";
    } else if (href.includes("showsst")) {
        agentType = "superstockist";
        addType = "SST";
    } else if (href.includes("showst")) {
        agentType = "stockist";
        addType = "ST";
    } else if (href.includes("showagent")) {
        agentType = "agent";
        addType = "Agent";
    }
    console.log("agentType", agentType);

    const getChildrenByType = "/beta/getChildrenByType?type=" + agentType + "&offset=0&limit=20";
    const api = httpHelpers();
    const [childrenByType, setChildrenByType] = useState(null);
    let navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - childrenByType.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchChildrenByType = () => {
        api
            .get(`${getChildrenByType}`)
            .then(res => {
                console.log("children", res);
                if (res && res.data && res.data.length > 0) {
                    setChildrenByType(res.data);
                } else {
                    setChildrenByType(null);
                }
            })
            .catch(err => {
                console.log(err.response.status);
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    const openRegister = (e) => {
        e.preventDefault();
        if (agentType.includes("subcompany")) {
            navigate('/registersc');
        } else if (agentType.includes("superstockist")) {
            navigate('/registersst');
        } else if (agentType.includes("stockist")) {
            navigate('/registerst');
        } else if (agentType.includes("agent")) {
            navigate('/registeragent');
        } else if (agentType.includes("user")) {
            navigate('/registeruser');
        }
    };

    const deposit = (e, child) => {
        e.preventDefault();
        navigate('/deposit', { state: { child } });
    };

    const withdraw = (e, child) => {
        e.preventDefault();
        navigate('/withdraw', { state: { child } });
    };

    const handleImmediateChildren = (e, child) => {
        e.preventDefault();
        if (child.userType.includes("subcompany")) {
            navigate('/childrenssc', { state: { child } });
        } else if (child.userType.includes("superstockist")) {
            navigate('/childrenssst', { state: { child } });
        } else if (child.userType.includes("stockist")) {
            navigate('/childrensst', { state: { child } });
        } else if (child.userType.includes("agent")) {
            navigate('/childrensagent', { state: { child } });
        }
    };

    const edit = (e, child) => {
        e.preventDefault();
        navigate('/edit', { state: { child } });
    };

    const statement = (e, child) => {
        e.preventDefault();
        navigate('/statement', { state: { child } });
    };

    const changeUserPassword = (e, child) => {
        e.preventDefault();
        navigate('/changeuserpassword', { state: { child } });
    };

    useEffect(() => {
        fetchChildrenByType();
    }, [agentType]);

    const ButtonStyle = { margin: "0px 10px" };

    const Row = ({ child }) => {
        return (
            <tr>
                {child.userType !== "user" && <td className="master-list-title-td">
                    <Button appearance="link"
                        onClick={(e) => handleImmediateChildren(e, child)}>{child.userName}</Button>
                </td>}
                {child.userType === "user" && <td className="master-list-title-td">
                    {child.userName}
                </td>}
                {child.userType !== "user" && <td className="master-list-title-td-blue">{child.creditReference}</td>}
                {child.userType !== "user" && <td className="master-list-title-td-blue">{child.share}</td>}
                <td className="master-list-title-td">{child.openingBalance}</td>
                <td className="master-list-title-td">{child.availableBalance}</td>
                {child.exposure < 0 && <td className="master-list-title-td-red">(-{child.exposure})</td>}
                {child.exposure == 0 && <td className="master-list-title-td-red">({child.exposure})</td>}
                {child.exposure > 0 && <td className="master-list-title-td-green">{child.exposure}</td>}
                {child.userType === "user" && <td className="master-list-title-td">{child.exposureLimit}</td>}
                {child.referenceProfitLoss < 0 && <td className="master-list-title-td-red">(-{child.referenceProfitLoss})</td>}
                {child.referenceProfitLoss == 0 && <td className="master-list-title-td-red">({child.referenceProfitLoss})</td>}
                {child.referenceProfitLoss > 0 && <td className="master-list-title-td-green">{child.referenceProfitLoss}</td>}
                <td className="master-list-title-td">
                    {child.status === "ACTIVE" &&
                        <label className="label-status-active">{child.status}</label>}
                    {child.status === "BLOCKED" &&
                        <label className="label-status-blocked">{child.status}</label>}
                    {child.status === "NEW" &&
                        <label className="label-status-new">{child.status}</label>}
                </td>
                <td className="master-list-title-actions-td">
                    <div>
                        <ButtonToolbar style={{ padding: 1 }}>
                            <Button color="green" appearance="ghost" size="xs"
                                onClick={(e) => deposit(e, child)} >
                                D
                            </Button>
                            <Button color="red" appearance="ghost" size="xs"
                                onClick={(e) => withdraw(e, child)} >
                                W
                            </Button>
                            <Button color="cyan" appearance="ghost" size="xs"
                                onClick={(e) => edit(e, child)} >
                                Edit
                            </Button>
                            <Button color="violet" appearance="ghost" size="xs"
                                onClick={(e) => statement(e, child)} >
                                Stmt.
                            </Button>
                            <Button color="orange" appearance="ghost" size="xs"
                                onClick={(e) => changeUserPassword(e, child)} >
                                Ch. Pwd.
                            </Button>
                        </ButtonToolbar>
                    </div>
                </td>
            </tr>
        )
    };

    return (
        <div className="manage-master-downline-root">
            <div className="table-top-container">
                <div>
                    {open && <div className="add-master-container" style={{ paddingRight: isSmallScreen ? "65px" : "135px"}}>
                        <div>
                            <button
                                className="board-buttons board-buttons-nav-bar-dark-smaller-thin"
                                onClick={(e) => openRegister(e)} >Add {addType}</button>
                        </div>
                    </div>}
                    {!open && <div className="add-master-container" style={{ paddingRight: isSmallScreen ? "0px" : "15px"}}>
                        <div>
                            <button
                                className="board-buttons board-buttons-nav-bar-dark-smaller-thin"
                                onClick={(e) => openRegister(e)} >Add {addType}</button>
                        </div>
                    </div>}
                </div>
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th">User Name</th>
                                        {agentType !== "user" && <th className="master-list-title-th">Credit Ref.</th>}
                                        {agentType !== "user" && <th className="master-list-title-th">Share</th>}
                                        <th className="master-list-title-th">Opening Bal.</th>
                                        <th className="master-list-title-th">Avail. Bal.</th>
                                        <th className="master-list-title-th">Exposure</th>
                                        {agentType === "user" && <th className="master-list-title-th">Exposure Lim.</th>}
                                        <th className="master-list-title-th">Ref. P/L</th>
                                        <th className="master-list-title-th">Status</th>
                                        <th className="master-list-title-actions-th">Actions</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tbl-content">
                            <table>
                                <tbody>
                                    {childrenByType && (rowsPerPage > 0
                                        ? childrenByType.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : childrenByType
                                    ).map((child, index) =>
                                        <Row
                                            child={child}
                                            key={index}
                                            index={index} />)}
                                    {emptyRows > 0 && (
                                        <tr style={{ height: 34 * emptyRows }}>
                                            <td colSpan={3} aria-hidden />
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {childrenByType && childrenByType.length > rowsPerPage && <div>
                <div className="table-pagination-container">
                    <div className="table-pagination-container-child">
                        <CustomTablePagination
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            rowsPerPageOptions={7}
                            colSpan={3}
                            count={childrenByType && childrenByType.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                                select: {
                                    'aria-label': 'rows per page',
                                },
                                actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

const blue = {
    50: '#48aaad',
    200: '#48aaad',
    400: '#48aaad',
    900: '#48aaad',
};

const grey = {
    50: '#E5E4E2',
    100: '#C0C0C0',
    200: '#E5E4E2',
    300: '#C0C0C0',
    400: '#C0C0C0',
    500: '#C0C0C0',
    600: '#C0C0C0',
    700: '#C0C0C0',
    800: '#C0C0C0',
    900: '#C0C0C0',
};

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #48aaad;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: white;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);