import {
    MdLiveTv, MdSettings,
    MdBlock,
    MdWeb,
    MdSupervisorAccount
  } from "react-icons/md";
  
  export const sidebarDataSuperAdmin = [
    { label: 'Domain', icon: <MdWeb />, link: '/domain' },
    { label: 'Match Manager', icon: <MdSupervisorAccount />, link: '/matchmanager' },
    { label: 'Live Matches', icon: <MdLiveTv />, link: 'matcheslist' },
    { label: 'Settings', icon: <MdSettings />, link: '/settings' },
    { label: 'Block', icon: <MdBlock />, link: '/block' },
  ];