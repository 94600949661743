import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import BetSlipTable from './BetSlipTable';
import HeaderDetailComponent from './BetSlipBetHolder';
import BetSlipMarketPosition from './BetSlipMarketPosition';
import StyledTablePagination from './BetSlipStyledTablePagination';
import BetSlipStyledPaginationTable from './BetSlipStylePaginationTable';


// Sample data for dynamic menu items
const tabOptions = [
  { id: 1, label: "Match winner" },
  { id: 2, label: "Who will win the Toss" },
  // Add more options here as needed
];

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  // borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'flex-start', // Align tabs to the left
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.text.primary, // Color of the underline/indicator
    height: '4px', // Height of the underline
    transition: 'transform 0.3s ease', // Smooth transition for indicator movement
  },
  height: "20px",
  padding: "0px",
  marginTop: "40px",
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#9cadc7",
  fontWeight: "bold",
  backgroundColor: '#f5f5f5', // Default background color for unselected tabs
  minWidth: 0, // Allow the tab to fit its content
  padding: '3px 16px', // Adjust padding to fit text
  textTransform: 'none', // Disable uppercase transformation
  '&.Mui-selected': {
    backgroundColor: 'white', // Background color for selected tab
    color: '#555', // Charcoal color for text of selected tab
    border: "1px solid #e5e4e2",
    borderBottom: 'none',
  },
  '&:hover': {
    // backgroundColor: '#e0e0e0', // Background color on hover for all tabs
    color: "#444",
  },
  fontSize: '13px',
  height: '20px',
}));

const BetSlipWinnerTab = ({ open, isSmallScreen }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <StyledTabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="tabs"
        variant="scrollable" // Ensure tabs do not stretch
        TabIndicatorProps={{ style: { display: 'none' } }} // Hide default indicator
      >
        {tabOptions.map(option => (
          <StyledTab key={option.id} label={option.label} value={option.id} />
        ))}
      </StyledTabs>
      <div className={selectedTab === 1 ? "bet-slip-box-container" : "bet-slip-box-container-2"}></div>
      <Box
        sx={{
          position: 'relative',
          width: '100%', // Full width for the content
          height: "auto",
          '& .MuiTabs-indicator': {
            position: 'absolute',
            bottom: 0,
            left: `${(selectedTab - 1) * 100}%`,
            width: '100%',
            transform: 'translateX(0%)',
          },
          pt: "10px",
          pl: '0px',
          background: "white",
          border: '1px solid #e5e4e2',
          borderTop: 'none',
        }}
      >
        <div className="bet-slip-tab-header-container">
          {tabOptions[selectedTab - 1].label}
        </div>
        <div className="bet-slip-holder-container-tab">
          <HeaderDetailComponent
            headerTitle="Total Bets"
            detailContent="20"
          />
          <HeaderDetailComponent
            headerTitle="Settled Bets"
            detailContent="20"
          />
          <HeaderDetailComponent
            headerTitle="Unsettled Bets"
            detailContent="0"
          />
          <HeaderDetailComponent
            headerTitle="Reverted Bets"
            detailContent="0"
          />
        </div>
        <div className="bet-slip-market-position-container">
          <BetSlipMarketPosition />
        </div>
        <div>
          <div className="header-market-position-container">
            <div className="header">
              Bet Slips
            </div>
            <div className='market-separator-below-header'></div>
          </div>
          {/* <BetSlipTable open={open} isSmallScreen={isSmallScreen} /> */}
          <BetSlipStyledPaginationTable open={open} isSmallScreen={isSmallScreen} />
        </div>
      </Box>
    </div>
  );
};

export default BetSlipWinnerTab;

