import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout, registerGames } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

const RegisterGames = ({ role, logout }) => {

    const form = useRef();
    const checkBtn = useRef();

    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [player1, setPlayer1] = useState("");
    const [player2, setPlayer2] = useState("");
    const [newCreatedGame, setNewCreatedGame] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);

    useEffect(() => {
        dispatch(clearMessage());
    }, [])

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const onChangeTitle = (e) => {
        const title = e.target.value;
        setTitle(title);
    };

    const onChangeDate = (e) => {
        const date = e.target.value;
        setDate(date);
    };

    const onChangeTime = (e) => {
        const time = e.target.value;
        setTime(time);
    };

    const onChangePlayer1 = (e) => {
        const player1 = e.target.value;
        setPlayer1(player1);
    };

    const onChangePlayer2 = (e) => {
        const player2 = e.target.value;
        setPlayer2(player2);
    };

    const handleRegisterGames = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log("register game");
            dispatch(registerGames(title, date, time, player1, player2, role))
                .then((data) => {
                    console.log("reg game data: ", data);
                    if (data.status === 401) {
                        logout();
                    } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                        setSuccessful(false);
                    } else if (data.status === 200) {
                        setNewCreatedGame(data.data.id);
                        setSuccessful(true);
                    }
                })
                .catch(() => {
                });
        }
    };

    const handleAddMore = () => {
        setTitle("");
        setDate("");
        setTime("");
        setPlayer1("");
        setPlayer2("");
        setSuccessful(false);
        setNewCreatedGame(null);
    }

    return (
        <div className="register-top-container">
            <div className="register-container">
                <div className="card" style={{ marginTop: 90 }}>
                    {!successful && <div>
                        <label className="register-header">Add Game</label>
                    </div>}
                    <div className="card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
                        {newCreatedGame && <label style={{ fontSize: "30px", fontWeight: "bold" }}>{newCreatedGame}</label>}

                        <Form onSubmit={handleRegisterGames} ref={form}>
                            {!successful && (
                                <div>
                                    {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="title">Title</label>
                                        </div>
                                        <Input
                                            id="reg_title"
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            maxLength="30"
                                            value={title}
                                            onChange={onChangeTitle}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="date">Date (YYYY-MM-DD)</label>
                                        </div>
                                        <Input
                                            id="reg_date"
                                            type="text"
                                            className="form-control"
                                            name="date"
                                            maxLength="10"
                                            value={date}
                                            onChange={onChangeDate}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="time">Time (HH:mm:ss)</label>
                                        </div>
                                        <Input
                                            id="reg_time"
                                            type="text"
                                            className="form-control"
                                            name="time"
                                            maxLength="8"
                                            value={time}
                                            onChange={onChangeTime}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="player1">Player 1</label>
                                        </div>
                                        <Input
                                            id="reg_player1"
                                            type="text"
                                            className="form-control"
                                            name="player1"
                                            maxLength="15"
                                            value={player1}
                                            onChange={onChangePlayer1}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="div-label-form">
                                            <label className="label-form" htmlFor="player2">Player 2</label>
                                        </div>
                                        <Input
                                            id="reg_player2"
                                            type="text"
                                            className="form-control"
                                            name="player2"
                                            maxLength="15"
                                            value={player2}
                                            onChange={onChangePlayer2}
                                            validations={!successful && [required]}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-smaller">Add</button>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>

                    {(message || successful) && (<div className="form-group" style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {message ? message : ("New Game " + newCreatedGame + " is Added Successfully!")}
                        </div>
                        <button className="btn btn-primary btn-block" onClick={() => handleAddMore()}>Add More</button>
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default RegisterGames;