import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from 'react-router-dom';
import { logout, updateGameStatus } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../actions/message";
// import data from "./data";
// import ReactPaginate from "react-paginate";
// import { httpHelpers } from "../../services/httpHelpers";
// import { 
//   ZERO,
//   ONE,
//   TWO,
//   THREE,
//   FOUR,
//   FIVE,
//   SIX,
//   SEVEN,
//   EIGHT,
//   NINE,
//   GREEN,
//   PURPLE,
//   RED
// } from '../LuckyNumber/BetCandidatesTypes';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default function MatchesUpcomingTable({ role, logout }) {
    console.log("show", role);
    const getUpcomingGames = "/alpha/getUpcomingGames";
    const api = httpHelpers();
    const [upcomingGames, setUpcomingGames] = useState(null);
    let navigate = useNavigate();
    const [successful, setSuccessful] = useState(false);
    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const fetchUpcomingGames = () => {
        api
            .get(`${getUpcomingGames}`, `${role}`)
            .then(res => {
                console.log("upcoming games", res);
                if (res && res.data && res.data.length > 0) {
                    setUpcomingGames(res.data);
                } else {
                    setUpcomingGames(null);
                }
            })

            .catch(err => {
                console.log(err.response.status);
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    const openRegisterGames = (e) => {
        e.preventDefault();
        navigate('/registergames');
    };

    const start = (e, games) => {
        e.preventDefault();
        navigate('/startgame', { state: { games } });
    };

    const cancel = (e, games) => {
        e.preventDefault();
        handleGameStatus(games.id, "CANCELLED");
    };

    const suspend = (e, games) => {
        e.preventDefault();
        handleGameStatus(games.id, "SUSPENDED");
    };

    useEffect(() => {
        fetchUpcomingGames();
    }, []);

    const handleGameStatus = (id, gameStatus) => {
        setSuccessful(false);
        console.log("game status", role);
        dispatch(updateGameStatus(id, gameStatus))
            .then((data) => {
                console.log("game status data: ", data);
                if (data.status === 401) {
                    if (data.data === "Wrong password") {
                        setSuccessful(false);
                    } else {
                        logout();
                    }
                } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                    setSuccessful(false);
                } else if (data.status === 200) {
                    setSuccessful(true);
                }
            })
            .catch(() => {
            });
    };

    const Row = ({ games }) => {
        return (
            <tr>
                <td className="master-list-title-td-game-title">{games.title}</td>
                <td className="master-list-title-td">{games.date}</td>
                <td className="master-list-title-td">{games.startTime}</td>
                <td className="master-list-title-td">{games.player1}</td>
                <td className="master-list-title-td">{games.player2}</td>
                <td className="master-list-title-td">
                    <label className="label-status-new">{"upcoming"}</label>
                </td>
                {role === "admin" && <td className="master-list-title-actions-td-small">
                    <div>
                        <ButtonToolbar style={{ padding: 1 }}>
                            <Button color="green" appearance="ghost" size="xs"
                                onClick={(e) => start(e, games)} >
                                Start
                            </Button>
                            <Button color="red" appearance="ghost" size="xs"
                                onClick={(e) => cancel(e, games)} >
                                Cancel
                            </Button>
                            {/* <Button color="cyan" appearance="ghost" size="xs"
                                onClick={(e) => suspend(e, games)} >
                                Suspend
                            </Button> */}
                        </ButtonToolbar>
                    </div>
                </td>}
            </tr>
        )
    };

    return (
        <div>
            <div style={{ background: "white" }}>
                {role && role === "admin" && <div className="add-games-container-upcoming">
                    <div>
                        <button
                            className="board-buttons board-buttons-nav-bar-dark-small-thin"
                            onClick={(e) => openRegisterGames(e)} >Add Games</button>
                    </div>
                </div>}
            </div>
            <div className="table-top-container">
                {(message || successful) && (<div>
                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                        {message ? message : ("Game Status Updated Successfully!")}
                    </div>
                </div>)}
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th-game-title">Title</th>
                                        <th className="master-list-title-th">Date</th>
                                        <th className="master-list-title-th">Time</th>
                                        <th className="master-list-title-th">Player 1</th>
                                        <th className="master-list-title-th">Player 2</th>
                                        <th className="master-list-title-th">Status</th>
                                        {role === "admin" && <th className="master-list-title-actions-th-small">Actions</th>}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tbl-content">
                            <table>
                                <tbody>
                                    {upcomingGames &&
                                        upcomingGames.map((games, index) =>
                                            <Row
                                                games={games}
                                                key={index}
                                                index={index} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
