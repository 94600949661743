import "./style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from 'react-router-dom';
// import data from "./data";
// import ReactPaginate from "react-paginate";
// import { httpHelpers } from "../../services/httpHelpers";
// import { 
//   ZERO,
//   ONE,
//   TWO,
//   THREE,
//   FOUR,
//   FIVE,
//   SIX,
//   SEVEN,
//   EIGHT,
//   NINE,
//   GREEN,
//   PURPLE,
//   RED
// } from '../LuckyNumber/BetCandidatesTypes';

export default function MatchesPastTable({ role, logout }) {
    console.log("show", role);
    const getPastGames = "/alpha/getPastGames";
    const api = httpHelpers();
    const [pastGames, setPastGames] = useState(null);
    let navigate = useNavigate();

    const fetchPastGames = () => {
        api
            .get(`${getPastGames}`, `${role}`)
            .then(res => {
                console.log("past games", res);
                if (res && res.data && res.data.length > 0) {
                    setPastGames(res.data);
                } else {
                    setPastGames(null);
                }
            })

            .catch(err => {
                console.log(err.response.status);
                if (err.response.status === 401) {
                    logout();
                }
            });
    };

    const openRegisterGames = (e) => {
        e.preventDefault();
        navigate('/registergames');
    };

    // const cancel = (e, games) => {
    //     e.preventDefault();
    // };

    // const suspend = (e, games) => {
    //     e.preventDefault();
    // };

    useEffect(() => {
        fetchPastGames();
    }, []);

    const Row = ({ games }) => {
        return (
            <tr>
                <td className="master-list-title-td-game-title">{games.title}</td>
                <td className="master-list-title-td">{games.date}</td>
                <td className="master-list-title-td">{games.startTime}</td>
                <td className="master-list-title-td">{games.player1}</td>
                <td className="master-list-title-td">{games.player2}</td>
                <td className="master-list-title-td">{games.winner}</td>
                <td className="master-list-title-td">
                    <label className="label-status-blocked">{"over"}</label>
                </td>
                {/* <td className="master-list-title-actions-td-small">
                    <div>
                        <ButtonToolbar style={{ padding: 1 }}>
                            <Button color="red" appearance="ghost" size="xs"
                                onClick={(e) => cancel(e, games)} >
                                Cancel
                            </Button>
                            <Button color="cyan" appearance="ghost" size="xs"
                                onClick={(e) => suspend(e, games)} >
                                Suspend
                            </Button>
                        </ButtonToolbar>
                    </div>
                </td> */}
            </tr>
        )
    };

    return (
        <div>
            <div style={{ background: "white" }}>
                {role && role === "admin" && <div className="add-games-container-upcoming">
                    <div>
                        <button
                            className="board-buttons board-buttons-nav-bar-dark-small-thin"
                            onClick={(e) => openRegisterGames(e)} >Add Games</button>
                    </div>
                </div>}
            </div>
            <div className="table-top-container">
                <div className="table-container">
                    <div className="table-content-container">
                        <div className="tbl-header">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="master-list-title-th-game-title">Title</th>
                                        <th className="master-list-title-th">Date</th>
                                        <th className="master-list-title-th">Time</th>
                                        <th className="master-list-title-th">Player 1</th>
                                        <th className="master-list-title-th">Player 2</th>
                                        <th className="master-list-title-th">Winner</th>
                                        <th className="master-list-title-th">Status</th>
                                        {/* <th className="master-list-title-actions-th-small">Actions</th> */}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tbl-content">
                            <table>
                                <tbody>
                                    {pastGames &&
                                        pastGames.map((games, index) =>
                                            <Row
                                                games={games}
                                                key={index}
                                                index={index} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
