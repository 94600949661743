import React, { useCallback, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TokenService from "../services/token-service";
import whatsapp from '../assets/whatsapp_48.png';
import '../App.css';
import { httpHelpers } from "../services/httpHelpers";
import { Container, Card, Typography, CardContent, Button } from "@mui/material";


const Profile = () => {

  const [balance, setBalance] = useState();
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();


  const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const { user: currentUser } = useSelector((state) => state.auth);
  const user = TokenService.getUser(role);
  const uid = user.userName;
  let refer = 'https://api.whatsapp.com/send?text=Join here: https://game.play247.link/register?agentCode=' + user.id;
  if (role === "admin") {
    refer = 'https://api.whatsapp.com/send?text=Join here: https://game.play247.link/registerUser?adminCode=' + user.id;
  }
  // const [width, setWidth] = useState(window.innerWidth);

  const logOut = useCallback(() => {
    // dispatch(logout("", role));
    if (role === "admin") {
      navigate("/logadmin");
    } else if (role === "agent") {
      navigate("/logagent");
    }
  }, [dispatch]);

  useEffect(() => {
    fetchBalance();
  }, []);

  if (!currentUser) {
    if (role === "admin") {
      return <Navigate to="/logadmin" />;
    } else if (role === "agent") {
      return <Navigate to="/logagent" />;
    } else {
      return <Navigate to="/logagent" />;
    }
  }

  let url = "alpha/getBalance";
  if (role === "agent")
    url = "beta/getBalance";
  const api = httpHelpers();

  const updateBalance = (updatedBalance) => {
    setBalance(updatedBalance);
  }

  const fetchBalance = () => {
    setIsLoading(true);
    api
      .get(`${url}`)
      .then(res => {
        console.log("fetch balance res", res.data)
        let role = "agent";
        if (href.includes("admin")) {
          role = "admin";
        }
        console.log("fetch balance res", role)
        let temp = TokenService.getUser(role);
        temp.balance = res.data;
        TokenService.setUser(temp);
        updateBalance(res.data);
        setIsLoading(false); // Set loading state back to false when done fetching
      })
      .catch(err => {
        console.log(err.response.status)
        if (err.response.status === 401) {
          TokenService.removeUser();
          logOut();
          window.location.reload();
          // logOut();
        }
      })
  }

  return (
    <div style={{ color: "black", paddingTop: "90px" }}>
      <Container >
        <header className="jumbotron">
          <h3>
            {/* <strong>{currentUser.user.username}</strong> Profile */}
            <strong>{uid}</strong> Profile
          </h3>
        </header>
        {/* <p>
        <strong>Token:</strong> {currentUser.jwt && currentUser.jwt.substring(0, 20)} ...{" "}
        {currentUser.jwt && currentUser.jwt.substr(currentUser.jwt && currentUser.jwt.length - 20)}
      </p> */}
        {/* <p>
        <strong>Id:</strong> {currentUser.user.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.user.email}
      </p> */}
        {/* <strong>Authorities:</strong> {"currentUser.user.role"} */}
        <strong>Authorities:</strong> {role === "admin" ? "Admin" : "Agent"}
        <br></br>
        <br></br>
        {role === "agent" && (<div>
          <strong>Share Referral Link: </strong>
          <a href={refer}
            data-action="share/whatsapp/share"
            target="_blank">
            <img className='rcorners2' src={whatsapp}></img>
          </a></div>)}

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "30px" }}>
          <Card style={{ width: "350px", padding: "20px" }}>
            <CardContent>
              
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 'bold', color: 'darkred' }}
              >
                Balance: {isLoading ? 'Loading...' : balance ? (balance).toFixed(1) : "0"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchBalance}
                disabled={isLoading}
                style={{marginTop:"10px"}}
              >
                refresh
              </Button>
            </CardContent>
          </Card>
        </div>

      </Container>
    </div>
  );
};

export default Profile;
