import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout, notify } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const Notify = ({ role, logout, game }) => {

    const form = useRef();
    const checkBtn = useRef();

    const gameId = game ? game.id : null;
    const [notiMessage, setNotiMessage] = useState();
    const [successful, setSuccessful] = useState(false);
    const [showError, setShowError] = useState(true);

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeNotiMessage = (e) => {
        const noti = e.target.value;
        setNotiMessage(noti);
        setSuccessful(false);
    };

    const handleNotify = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log("register user", role);
            dispatch(notify(gameId, notiMessage))
                .then((data) => {
                    console.log("noti data: ", data);
                    if (data.status === 401) {
                        if (data.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                        setSuccessful(false);
                    } else if (data.status === 200) {
                        setSuccessful(true);
                    }
                })
                .catch(() => {
                });
        }
    };

    return (
        <div className="noti-top-container">
            <div className="noti-controls-container">
                <Form onSubmit={handleNotify} ref={form}><div>
                    <div className="noti-container">
                        <div className="form-group" style={{ marginTop: "12px" }} >
                            <Input
                                id="reg_notimessage"
                                type="text"
                                className="form-control"
                                name="notimessage"
                                value={notiMessage}
                                onChange={onChangeNotiMessage}
                                validations={!successful && [required]}
                                style={{ width: "260px" }}
                            />
                        </div>
                        <div className="form-group" style={{ marginLeft: "5px", marginTop: "10px" }}>
                            <button className="board-buttons board-buttons-nav-bar-dark-smaller" 
                            style={{width: "100px"}} >
                                Notify
                            </button>
                        </div>
                    </div>
                </div>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
                {successful && <div className="div-label-form">
                    <label>Sent!</label>
                </div>}
            </div>
        </div>
    );
};

export default Notify;