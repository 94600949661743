import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { marketTable } from "./tables/Columns";
import MarketTable from "./tables/MarketTable";
import TableTitle from "./customized/TableTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../actions/message";
import { SET_MESSAGE } from "../../actions/types";
import { MARKET_STATUS_BLOCKED, MARKET_STATUS_UNBLOCKED } from "./common/Constants";
import TokenService from "../../services/token-service";

export default function BlockMarket({ role, logout, child, hideTitle, isSmallScreen }) {

    const user = TokenService.getUser();
    let userId = user.userId;

    if (child)
        userId = child.userId;
    const [successMessage, setSuccessMessage] = useState();
    const [successful, setSuccessful] = useState(false);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let getMarkets = "beta/getSupportedSports?userId=";
    const api = httpHelpers();
    const [markets, setMarkets] = useState([]);

    const fetchMarkets = () => {
        api
            .get(`${getMarkets}` + userId)
            .then(res => {
                console.log("get markets res", res);
                if (res && res.data) {
                    setMarkets(res.data);
                } else {
                    setMarkets([]);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(clearMessage());
        fetchMarkets();
    }, []);

    useEffect(() => {
        fetchMarkets();
    }, [successful]);

    const handleMarket = (sportType, state) => {
        setSuccessful(false);
        dispatch(clearMessage());
        console.log("handle market state", sportType, state);
        let blockUnblock = MARKET_STATUS_BLOCKED;
        let blockUnblockUrl = "beta/blockSport?sportType=";
        if (state) {
            blockUnblock = MARKET_STATUS_UNBLOCKED;
            blockUnblockUrl = "beta/unblockSport?sportType=";
        }
        api
            .get(`${blockUnblockUrl}` + sportType + "&userId=" + userId)
            .then(res => {
                console.log("block markets res", res);
                if (res && res.status === 200) {
                    setSuccessMessage(sportType + " " + blockUnblock + " Successfully!");
                    setSuccessful(true);
                } else {
                    setSuccessful(false);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    return (
        <div>
            <div>
                {/* <TableHeader title={"Market List"} minWidth={"100px"} /> */}
                {(message || successful) && (<div className="form-group" style={{ marginTop: "10px", paddingLeft: "5px", paddingRight: "5px" }}>
                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                        {message ? message : (successMessage)}
                    </div>
                </div>)}
                {!hideTitle && <TableTitle
                    text="Sports List"
                    color="#ffffff"
                    fontSize="24px"
                    textAlign="left"
                    width={isSmallScreen ? "98%" : "99.2%" }
                    height="66px"
                />}
                {/* <TableTitle
                    text="User Statistics"
                    color="#ffffff"
                    fontSize="28px"
                    textAlign="left"
                    width="80%"
                    height="50px"
                />
                <TableTitle
                    text="Sales Report"
                    color="#ffffff"
                    fontSize="24px"
                    textAlign="right"
                    width="75%"
                    height="50px"
                /> */}
                <MarketTable columns={marketTable} data={markets} onCheckBoxClick={handleMarket} hideTitle={hideTitle} userId={userId} />
            </div>
        </div>
    );
};
